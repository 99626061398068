/* eslint-disable no-unused-vars */
import { StringHelper } from '../../common/helpers/string-helper';
import axiosInstance from './axios.interceptors';

export interface IApiService {
	Get<T>(baseUri: string, endpointUri: string): Promise<T>;
	Post<T>(baseUri: string, endpointUri: string, payload: any, responseType: 'json' | 'blob'): Promise<T>;
}

export class ApiService implements IApiService {
	public async Get<T>(baseUri: string, endpointUri: string): Promise<T> {
		const targetUri = `${baseUri}/${endpointUri}`;
		try {
			let result = await axiosInstance.get<T>(`${targetUri}`);
			return result.data;
		} catch (err) {
			throw new Error(`Error calling ${targetUri}`);
		}
	}

	public async Post<T>(
		baseUri: string,
		endpointUri: string,
		payload: any = {},
		responseType: 'json' | 'blob' = 'json'
	): Promise<T> {
		const targetUri = `${baseUri}/${endpointUri}`;

		try {
			let result = await axiosInstance.post<T>(`${targetUri}`, payload, { responseType: responseType });
			return result.data;
		} catch (err) {
			throw new Error(`Error calling ${targetUri}`);
		}
	}
}
