/* eslint-disable no-unused-vars */
import { BaseTableCount } from '../../models/base/base-table-count';
import { Contact } from '../../models/contacts/contact';
import { GetSegmentViewContactsRequest } from '../../models/segment';
import { ApiServiceBase } from './base/api-service-base';

const baseEndpoint = 'views';

export interface IViewsApiService {
	GetSegmentViewContacts(getSegmentViewContactsRequest: GetSegmentViewContactsRequest): Promise<Contact[]>;
}

export class ViewsApiService extends ApiServiceBase implements IViewsApiService {
	public async GetSegmentViewContacts(
		getSegmentViewContactsRequest: GetSegmentViewContactsRequest
	): Promise<Contact[]> {
		return await this.PostToApi<Contact[]>(`${baseEndpoint}/contacts/get`, getSegmentViewContactsRequest);
	}
}
