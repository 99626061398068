import { FieldType } from '../enums/field-type-enums';

export class ObjectHelper {
	public static HasValue(object: any): boolean {
		return object !== null && object !== undefined;
	}

	public static DeepCopy<T>(object: T): T | null {
		return object ? (JSON.parse(JSON.stringify(object)) as T) : null;
	}

	public static ObjectsAreEqual<T>(object1: T, object2: T): boolean {
		if (!object1 || !object2) return false;
		return JSON.stringify(object1) === JSON.stringify(object2);
	}

	public static ExtractObjectKeysByType(object: any, fieldType: FieldType = FieldType.None): string[] {
		const requiredKeys: string[] = [];
		if (!object) return requiredKeys;

		const keys = Array.from(Object.keys(object));
		const values = Array.from(Object.values(object));

		keys.forEach((key, index) => {
			const currentFieldType = typeof values[index];
			if (currentFieldType === fieldType.toLowerCase() || fieldType === FieldType.None) {
				requiredKeys.push(key);
			}
		});

		return requiredKeys;
	}

	public static SearchInDataSource<T>(dataSource: any[], searchTerm: string): T[] {
		const matchingItems = new Set();
		dataSource.forEach((data) => {
			Object.values(data).forEach((dataField) => {
				if (dataField?.toString().toLowerCase().includes(searchTerm)) {
					matchingItems.add(data);
				}
			});
		});
		return Array.from(matchingItems) as T[];
	}
}
