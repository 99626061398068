import { ReferenceValueColumnName } from '../../models/reference-value';
import { FieldType } from '../enums/field-type-enums';
import { StringHelper } from './string-helper';

export class ReferenceValuesHelper {
	public static GetColumnsByType(
		referenceValueColumnNames: ReferenceValueColumnName[],
		fieldType: FieldType,
		end: number = 0,
		friendlyNames: boolean = false
	): string[] {
		const columnNames = referenceValueColumnNames
			.filter((contactColumnName) => StringHelper.Capitalize(contactColumnName.dataType) === fieldType)
			.map((contactColumnName) => {
				return friendlyNames
					? StringHelper.SnakeCaseToFriendlyString(contactColumnName.columnName)
					: contactColumnName.columnName;
			});

		return end > 0 ? columnNames.slice(0, end) : columnNames;
	}
}
