import styles from './Settings.module.scss';
import React from 'react';
import { Container } from '../../atoms/Base/Container/Container';

const Settings = () => {
	return (
		<Container className={styles.settings_container}>
			<h3>Settings</h3>
		</Container>
	);
};

export default Settings;
