import styles from './SegmentFilterRecordType.module.scss';
import React, { FC, useState } from 'react';
import { SegmentRecordType } from '../../../../common/enums/segment-enums';
import { ColumnFilterItem } from '../../../../common/models/column-filter';
import SegmentFilterRecordTypeModal from './SegmentFilterRecordTypeModal';
import Label from '../../../atoms/Base/Label/Label';

interface ISegmentFilterRecordType {
	columnFilterItem: ColumnFilterItem;
	onClickRecordType: (columnFilterItemId: string, segmentRecordType: SegmentRecordType) => void;
}

const SegmentFilterRecordType: FC<ISegmentFilterRecordType> = ({ columnFilterItem, onClickRecordType }) => {
	const [showOptions, setShowOptions] = useState<boolean>(false);

	const onClickRecordTypeHandler = (columnFilterItemId: string, segmentRecordType: SegmentRecordType) => {
		onClickRecordType(columnFilterItemId, segmentRecordType);
		setShowOptions(false);
	};

	return (
		<div className={styles.segment_filter_record_type_container}>
			{showOptions && (
				<SegmentFilterRecordTypeModal
					columnFilterItem={columnFilterItem}
					onCloseClick={() => setShowOptions(false)}
					onClickRecordType={onClickRecordTypeHandler}
				/>
			)}
			{columnFilterItem.recordType === SegmentRecordType.Contact && (
				<Label
					secondary
					selected={columnFilterItem.recordType === SegmentRecordType.Contact}
					onClick={() => setShowOptions(true)}
				>
					{SegmentRecordType.Contact}
				</Label>
			)}
			{columnFilterItem.recordType === SegmentRecordType.Activity && (
				<Label
					secondary
					selected={columnFilterItem.recordType === SegmentRecordType.Activity}
					onClick={() => setShowOptions(true)}
				>
					{SegmentRecordType.Activity}
				</Label>
			)}
		</div>
	);
};

export default SegmentFilterRecordType;
