import { IApiService } from '../../interfaces/api-service';
import { IAppConfigService } from '../../interfaces/app-config-service';

export abstract class ApiServiceBase {
	private apiService: IApiService;
	private appConfigService: IAppConfigService;

	constructor(apiService: IApiService, appConfigService: IAppConfigService) {
		this.apiService = apiService;
		this.appConfigService = appConfigService;
	}

	protected GetFromApi<T>(endpointUri: string): Promise<T> {
		const baseUri =
			process.env.NODE_ENV === 'production'
				? this.appConfigService.cxDataApiUrl
				: this.appConfigService.cxDataLocalApiUrl;

		return this.apiService.Get<T>(baseUri, endpointUri);
	}

	protected PostToApi<T>(endpointUri: string, payload: any = {}, responseType: 'json' | 'blob' = 'json'): Promise<T> {
		const baseUri =
			process.env.NODE_ENV === 'production'
				? this.appConfigService.cxDataApiUrl
				: this.appConfigService.cxDataLocalApiUrl;

		return this.apiService.Post<T>(baseUri, endpointUri, payload, responseType);
	}
}
