import styles from './TextBox.module.scss';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { IErrorProps } from '../../../../common/props/error-props';
import { StringValueCallback } from '../../../../types/Callbacks';
import { Container } from '../Container/Container';
import Label from '../Label/Label';

interface ITextBoxProps extends IErrorProps {
	bold?: boolean;
	className?: string;
	defaultText?: string;
	disabled?: boolean;
	max?: number;
	placeHolder?: string;
	showSearchIcon?: boolean;
	type?: string;
	onBlur?: any;
	onChange?: StringValueCallback;
}

const TextBox: FC<ITextBoxProps> = ({
	bold,
	className,
	defaultText,
	disabled = false,
	max = 100,
	placeHolder = 'Type here',
	showSearchIcon,
	type = 'text',
	onBlur,
	onChange,
	errorMessage,
}) => {
	const [text, setText] = useState<string>(StringHelper.Empty);

	const classNameBuilderFactory = useClassNameBuilderFactory();

	const textBoxClassNames = classNameBuilderFactory
		.Create()
		.AddClass(className)
		.AddClass(styles.text_box)
		.AddConditionalClass(bold, styles.bold)
		.AddConditionalClass(disabled, styles.disabled)
		.AddConditionalClass(showSearchIcon, styles.search)
		.AddConditionalClass(StringHelper.IsNotNullOrEmpty(errorMessage), styles.error)
		.GetClassNames();

	useEffect(() => {
		setText(StringHelper.ValueOrDefault(defaultText));
	}, [defaultText, errorMessage]);

	const onChangeHandler = (args: ChangeEvent<HTMLInputElement>) => {
		const currentValue: string = args.target.value;

		if ((type === 'number' && Number(currentValue) <= max) || type === 'text') {
			setText(currentValue);
		}

		if (onChange) onChange(currentValue);
	};

	return (
		<Container childSpace>
			<Container>
				<input
					disabled={disabled}
					type={type}
					className={textBoxClassNames}
					placeholder={placeHolder}
					max={max}
					onBlur={onBlur}
					onChange={onChangeHandler}
					value={text}
				/>
			</Container>
			{errorMessage && (
				<Container>
					<Label redText>{errorMessage}</Label>
				</Container>
			)}
		</Container>
	);
};

export default TextBox;
