/* eslint-disable no-unused-vars */
import { ReferenceValue, ReferenceValueColumnName } from '../../models/reference-value';
import { ApiServiceBase } from './base/api-service-base';

const baseEndpoint = 'reference-values';

export interface IReferenceValuesApiService {
	GetActivityColumnNames(): Promise<ReferenceValueColumnName[]>;
	GetActivityReferenceValues(columnName: string): Promise<ReferenceValue[]>;
	GetContactColumnNames(): Promise<ReferenceValueColumnName[]>;
	GetContactReferenceValues(columnName: string): Promise<ReferenceValue[]>;
}

export class ReferenceValuesApiService extends ApiServiceBase implements IReferenceValuesApiService {
	public async GetActivityColumnNames(): Promise<ReferenceValueColumnName[]> {
		return await this.GetFromApi<ReferenceValueColumnName[]>(`${baseEndpoint}/activity/column-names`);
	}

	public async GetActivityReferenceValues(columnName: string): Promise<ReferenceValue[]> {
		return await this.GetFromApi<ReferenceValue[]>(`${baseEndpoint}/activity/${columnName}`);
	}

	public async GetContactReferenceValues(columnName: string): Promise<ReferenceValue[]> {
		return await this.GetFromApi<ReferenceValue[]>(`${baseEndpoint}/contact/${columnName}`);
	}

	public async GetContactColumnNames(): Promise<ReferenceValueColumnName[]> {
		return await this.GetFromApi<ReferenceValueColumnName[]>(`${baseEndpoint}/contact/column-names`);
	}
}
