import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NumberValueCallback } from '../../../types/Callbacks';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { Container } from '../../atoms/Base/Container/Container';
import Label from '../../atoms/Base/Label/Label';

interface IPagingTemplateProps {
	children: ReactNode;
	pageSize: number;
	totalSize: number;
	onChangePage?: NumberValueCallback;
}

const PagingTemplate: FC<IPagingTemplateProps> = ({ children, pageSize, totalSize, onChangePage }) => {
	const [pageNo, setPageNo] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);

	const onChangePageHandler = (changeIndex: number) => {
		const newPageNo = pageNo + changeIndex;
		if (newPageNo <= 0 || newPageNo >= totalPages) return;

		setPageNo(newPageNo);
		if (onChangePage) onChangePage(newPageNo);
	};

	useEffect(() => {
		setTotalPages(Math.ceil(totalSize / pageSize));
	}, [pageSize, totalSize]);

	return (
		<Container childSpace>
			{pageSize > 0 && totalSize > 0 && (
				<Container horizontal fullWidth>
					<Container fullWidth horizontal>
						<Container centerFull>
							<Label>
								Showing {(pageNo - 1) * pageSize}-{pageNo * pageSize} of {totalSize}
							</Label>
						</Container>
					</Container>
					<Container fullWidth horizontal right>
						<Container centerFull horizontal childSpace>
							<Label>
								Page {pageNo} of {totalPages}
							</Label>
							<ActionButton secondary onClick={() => onChangePageHandler(-1)}>{`<`}</ActionButton>
							<ActionButton secondary onClick={() => onChangePageHandler(1)}>{`>`}</ActionButton>
						</Container>
					</Container>
				</Container>
			)}
			<Container>{children}</Container>
		</Container>
	);
};

export default PagingTemplate;
