import React, { FC, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../authConfig';
import ActionButton from '../../Base/ActionButton/ActionButton';
import Loader from '../../Base/Loader/Loader';

export interface IAuthenticationProps {
	isLoading: boolean;
}

/**
 * Renders a simple button that loads a popup for signing in.
 */
export const SignInButton: FC<IAuthenticationProps> = ({ isLoading }) => {
	const { instance } = useMsal();

	// eslint-disable-next-line no-unused-vars
	const loginPopup = () => {
		instance
			.loginPopup(loginRequest)
			.then((response) => {})
			.catch((e) => {
				console.log(e);
				// Log the error to an external logging service
			});
	};

	const loginRedirect = () => {
		instance.loginRedirect(loginRequest);
	};

	const handleLogin = () => {
		loginRedirect();
	};

	useEffect(() => {
		instance.handleRedirectPromise().then((response) => {
			if (response !== null) {
				// Successfully logged in - log the event in App Insights
			}
		});
	}, []);

	return (
		<ActionButton secondary onClick={() => handleLogin()}>
			{isLoading && <Loader simple size={'sm'} />}
			{!isLoading && `Sign In`}
		</ActionButton>
	);
};
