import styles from './Separator.module.scss';
import React, { FC } from 'react';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface ISeparatorProps {
	noBottomSpace?: boolean;
	noTopSpace?: boolean;
}

const Separator: FC<ISeparatorProps> = ({ noBottomSpace, noTopSpace }) => {
	const classNames = useClassNameBuilderFactory()
		.Create()
		.AddClass(styles.separator)
		.AddConditionalClass(noBottomSpace, styles.no_bottom_space)
		.AddConditionalClass(noTopSpace, styles.no_top_space)
		.GetClassNames();

	return <div className={classNames}></div>;
};

export default Separator;
