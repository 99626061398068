/* eslint-disable no-unused-vars */
import styles from './AndOr.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { FilterType } from '../../../../common/enums/segment-enums';
import { Container } from '../../Base/Container/Container';
import Label from '../../Base/Label/Label';

interface IAndOrProps {
	filterType: string;
	hideConnectorLines?: boolean;
	itemIndex?: number;
	isFirstItem?: boolean;
	isPlus?: boolean;
	lastOr?: boolean;
	onClick?: any;
}

const AndOr: FC<IAndOrProps> = ({
	filterType,
	hideConnectorLines,
	itemIndex,
	isFirstItem,
	isPlus = false,
	lastOr,
	onClick,
}) => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const connectorClassNames = classNameBuilderFactory
		.Create()
		.AddConditionalClass(!isFirstItem && filterType !== FilterType.Or, styles.connector)
		.AddConditionalClass(isFirstItem && filterType !== FilterType.Or, styles.connector_first)
		.AddConditionalClass(filterType === FilterType.Or, styles.connector_or)
		.GetClassNames();

	const horizontalConnectorClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.connector_line)
		.AddConditionalClass(isFirstItem, styles.connector_line_horizontal_first)
		.AddConditionalClass(filterType === FilterType.And && !isFirstItem, styles.connector_line_horizontal)
		.GetClassNames();

	const [filterItemIndex, setFilterItemIndex] = useState<number>(0);

	useEffect(() => {
		if (!itemIndex) return;

		setFilterItemIndex(itemIndex);
	}, []);

	const onClickHandler = () => {
		if (onClick) onClick(filterItemIndex);
	};

	return (
		<div>
			{!hideConnectorLines && (
				<div className={connectorClassNames}>
					<div className={horizontalConnectorClassNames}></div>
				</div>
			)}
			<Container className={styles.and_or_container} horizontal onClick={onClickHandler}>
				<Container centerFull>
					<Label simple small clickable>
						{filterType}
					</Label>
				</Container>
				<Container centerFull className={styles.plus_or_minus}>
					{!isPlus ? <FaCircleMinus size={25} /> : <FaCirclePlus size={25} />}
				</Container>
			</Container>
			{filterType === FilterType.Or && !lastOr && <div className={connectorClassNames}></div>}
		</div>
	);
};

export default AndOr;
