import styles from './ContactsTableTemplate.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { Contact } from '../../../models/contacts/contact';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { useNavigate } from 'react-router-dom';
import { PrimaryRoutes } from '../../../common/enums/internal-route-enums';
import TableHead from '../../molecules/Base/TableHead/TableHead';
import TableBody from '../../molecules/Base/TableBody/TableBody';
import Table from '../../atoms/Base/Table/Table';
import Label from '../../atoms/Base/Label/Label';
import { StringHelper } from '../../../common/helpers/string-helper';
import { ContactField } from '../../../common/enums/contact-enums';
import { ObjectHelper } from '../../../common/helpers/object-helper';
import { Container } from '../../atoms/Base/Container/Container';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { FieldType } from '../../../common/enums/field-type-enums';
import { useContactsApiService, useReferenceValuesApiService } from '../../../api/providers/AppServiceProvider';
import CXDataLoader from '../../atoms/Custom/CXDataLoader/CXDataLoader';
import { setCachedContactColumnNames } from '../../../features/contacts-slice';
import PagingTemplate from '../PagingTemplate/PagingTemplate';
import { useClassNameBuilderFactory } from '../../../common/providers/ReactKitProvider';
import { ContactSearchTermRequest } from '../../../models/contacts/requests/contact-search-term-request';

interface IContactsTableTemplateProps {
	isDefault?: boolean;
	paging?: boolean;
	selectedColumns?: string[];
	searchTerm?: string;
	where?: string;
}

const ContactsTableTemplate: FC<IContactsTableTemplateProps> = ({
	isDefault,
	paging,
	selectedColumns,
	searchTerm,
	where,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const contactsApiService = useContactsApiService();
	const referenceValuesApiService = useReferenceValuesApiService();

	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);
	const cachedSelectedContactColumnNames: string[] = useAppSelector((state) => state.contacts.selectedColumns);
	const cachedTotalContactCount = useAppSelector((state) => state.contacts.totalCount);

	const containerClassNames = useClassNameBuilderFactory()
		.Create()
		.AddClass(styles.contacts_table_container)
		.AddConditionalClass(paging, styles.paging)
		.GetClassNames();

	const contactTableClassNames = useClassNameBuilderFactory()
		.Create()
		.AddClass(styles.contacts_table)
		.AddConditionalClass(paging, styles.paging)
		.GetClassNames();

	const [contacts, setContacts] = useState<any[]>([]);
	const [sortedContacts, setSortedContacts] = useState<any[]>([]);

	const [contactCount, setContactCount] = useState<number>(0);
	const [currentSearchTerm, setCurrentSearchTerm] = useState<string>(StringHelper.Empty);
	const [sortHeading, setSortHeading] = useState<string>(StringHelper.Empty);
	const [totalContactCount, setTotalContactCount] = useState<number>(cachedTotalContactCount);
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>();
	const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [contactIdToggle, setContactIdToggle] = useState<boolean>(false);
	const [contactNameToggle, setContactNameToggle] = useState<boolean>(false);
	const [emailToggle, setEmailToggle] = useState<boolean>(false);
	const [phoneNumberToggle, setPhoneNumberToggle] = useState<boolean>(false);
	const [mobileNumberToggle, setMobileNumberToggle] = useState<boolean>(false);
	const [locationToggle, setLocationToggle] = useState<boolean>(false);
	const [loyaltyStatusToggle, setLoyaltyStatusToggle] = useState<boolean>(false);
	const [lastActivityToggle, setLastActivityToggle] = useState<boolean>(false);
	const [lastActivityDateToggle, setLastActivityDateToggle] = useState<boolean>(false);

	const batchSize = 100;

	const loadContacts = (pageNo: number = 0) => {
		setTotalContactCount(cachedTotalContactCount);
		const offset = pageNo > 1 ? (pageNo - 1) * batchSize : 0;

		setIsLoading(true);
		contactsApiService.GetContacts({ limit: batchSize, offset: offset, where: where }).then((response) => {
			setContacts(response);
			setContactCount(response.length);
			setSortedContacts(response);
			setIsLoading(false);
		});
	};

	const searchContacts = (searchTerm: string, pageNo: number = 0) => {
		const pageChangeSearch = pageNo === -1;
		const offset = pageChangeSearch ? -1 : (pageNo - 1) * batchSize;

		setIsLoading(true);
		const contactSearchTermRequest: ContactSearchTermRequest = {
			columnNames: cachedContactColumnNames.slice(0, 7).map((contactColumnName) => contactColumnName.columnName),
			columnValue: searchTerm,
			limit: batchSize,
			offset: offset,
		};

		contactsApiService.GetSearchContactsCount(contactSearchTermRequest).then((response) => {
			setTotalContactCount(response.count);
		});
		contactsApiService.SearchContacts(contactSearchTermRequest).then((response) => {
			setIsLoading(false);
			setContactCount(batchSize);
			setSortedContacts(response.slice(0, batchSize));
		});
	};

	const onNavigationHandler = (contact: Contact) => {
		navigate(PrimaryRoutes.ContactDetails.replace(':contactId', contact.uniqueContactKey!), {
			state: { contact: contact },
		});
	};

	const onClickHeadingHandler = (heading: string) => {
		setSortHeading(heading);
		const contactsToSort = ObjectHelper.DeepCopy(contacts);

		switch (heading) {
			case ContactField.UniqueContactKey:
				{
					setContactIdToggle(!contactIdToggle);
					setSortedContacts(
						contactIdToggle
							? contactsToSort!.sort((a, b) => Number(a.uniqueContactKey) - Number(b.uniqueContactKey))
							: contactsToSort!.sort((a, b) => Number(b.uniqueContactKey) - Number(a.uniqueContactKey))
					);
				}
				break;
			case ContactField.FirstName:
				{
					setContactNameToggle(!contactNameToggle);
					setSortedContacts(
						contactNameToggle
							? contactsToSort!.sort((a, b) => a.firstName!.localeCompare(b.firstName!))
							: contactsToSort!.sort((a, b) => b.firstName!.localeCompare(a.firstName!))
					);
				}
				break;
			case ContactField.Email:
				{
					setEmailToggle(!emailToggle);
					setSortedContacts(
						emailToggle
							? contactsToSort!.sort((a, b) => a.email!.localeCompare(b.email!))
							: contactsToSort!.sort((a, b) => b.email!.localeCompare(a.email!))
					);
				}
				break;
			case ContactField.PhoneNumber:
				{
					setPhoneNumberToggle(!phoneNumberToggle);
					setSortedContacts(
						phoneNumberToggle
							? contactsToSort!.sort((a, b) => a.phoneNumber!.localeCompare(b.phoneNumber!))
							: contactsToSort!.sort((a, b) => b.phoneNumber!.localeCompare(a.phoneNumber!))
					);
				}
				break;
			case ContactField.MobileNumber:
				{
					setMobileNumberToggle(!mobileNumberToggle);
					setSortedContacts(
						mobileNumberToggle
							? contactsToSort!.sort((a, b) => a.mobileNumber!.localeCompare(b.mobileNumber!))
							: contactsToSort!.sort((a, b) => b.mobileNumber!.localeCompare(a.mobileNumber!))
					);
				}
				break;
			case ContactField.Country:
				{
					setLocationToggle(!locationToggle);
					setSortedContacts(
						locationToggle
							? contactsToSort!.sort((a, b) => a.country!.localeCompare(b.country!))
							: contactsToSort!.sort((a, b) => b.country!.localeCompare(a.country!))
					);
				}
				break;
			case ContactField.LoyaltyStatus:
				{
					setLoyaltyStatusToggle(!loyaltyStatusToggle);
					setSortedContacts(
						loyaltyStatusToggle
							? contactsToSort!.sort((a, b) => a.loyaltyStatus!.localeCompare(b.loyaltyStatus!))
							: contactsToSort!.sort((a, b) => b.loyaltyStatus!.localeCompare(a.loyaltyStatus!))
					);
				}
				break;
		}
	};

	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId);

		if (ArrayHelper.HasElements(selectedColumns)) {
			setVisibleColumnNames(selectedColumns!);
			return;
		}

		const visibleColumnNames =
			!isDefault && ArrayHelper.HasElements(cachedSelectedContactColumnNames)
				? cachedSelectedContactColumnNames.map((contactColumnName) =>
						StringHelper.SnakeCaseToFriendlyString(contactColumnName)
				  )
				: cachedContactColumnNames
						.slice(0, 7)
						.map((contactColumnName) =>
							StringHelper.SnakeCaseToFriendlyString(contactColumnName.columnName)
						);

		if (StringHelper.IsNullOrEmpty(searchTerm)) {
			setTotalContactCount(cachedTotalContactCount);
			setVisibleColumnNames(visibleColumnNames);
		}

		if (searchTerm && searchTerm.length > 0) {
			setCurrentSearchTerm(searchTerm);

			if (isLoading) return;

			// Set a new timeout
			const id = setTimeout(() => {
				// Execute the search once typing has stopped
				setIsLoading(true);
				searchContacts(searchTerm, -1);
			}, 1000);

			setTimeoutId(id);
		} else {
			if (!ArrayHelper.HasElements(cachedContactColumnNames)) {
				referenceValuesApiService.GetContactColumnNames().then((response) => {
					dispatch(setCachedContactColumnNames(response));
					setVisibleColumnNames(
						response.slice(0, 7).map((column) => StringHelper.SnakeCaseToFriendlyString(column.columnName))
					);

					loadContacts();
				});
			} else {
				setVisibleColumnNames(
					ArrayHelper.HasElements(selectedColumns)
						? selectedColumns!
						: visibleColumnNames.map((columnName) => StringHelper.SnakeCaseToFriendlyString(columnName))
				);
				loadContacts();
			}
		}
	}, [searchTerm, selectedColumns]);

	const onChangePageHandler = (newPageNo: number) => {
		if (StringHelper.IsNotNullOrEmpty(currentSearchTerm)) {
			searchContacts(currentSearchTerm, newPageNo);
		} else {
			loadContacts(newPageNo);
		}
	};

	const getContent = () => {
		const mainContent = (
			<Container>
				{isLoading ? (
					<CXDataLoader />
				) : (
					<Container className={containerClassNames}>
						{sortedContacts.length === 0 && (
							<Container center fullWidth>
								<Label>No contacts found</Label>
							</Container>
						)}
						{sortedContacts.length > 0 && (
							<Container childSpace>
								{!paging && (
									<Container fullWidth center>
										<Label>
											Showing first 100 rows, please <strong>Export</strong> to see more
											information
										</Label>
									</Container>
								)}
								<Container className={contactTableClassNames}>
									<Table>
										<TableHead
											headings={[...visibleColumnNames, 'Action']}
											sortHeading={sortHeading}
											onClickHeading={onClickHeadingHandler}
										/>
										<TableBody rowCount={contactCount}>
											{sortedContacts.map((contact, index) => {
												return (
													<tr key={`row-${index}`}>
														{visibleColumnNames.map((column, index) => {
															var currentContactColumnNameRef =
																cachedContactColumnNames.find(
																	(contactColumnName) =>
																		column ===
																		StringHelper.SnakeCaseToFriendlyString(
																			contactColumnName.columnName
																		)
																);
															let columnValue =
																contact[currentContactColumnNameRef!.columnName];
															const dataType = StringHelper.Capitalize(
																currentContactColumnNameRef!.dataType
															);
															columnValue =
																dataType === FieldType.Number
																	? Number(columnValue).toFixed(2)
																	: columnValue;
															return <td key={index}>{columnValue}</td>;
														})}
														<td>
															<ActionButton
																secondary
																onClick={() => onNavigationHandler(contact)}
															>
																View
															</ActionButton>
														</td>
													</tr>
												);
											})}
										</TableBody>
									</Table>
								</Container>
							</Container>
						)}
					</Container>
				)}
			</Container>
		);

		if (!paging) return mainContent;

		return (
			<PagingTemplate
				pageSize={contactCount}
				totalSize={totalContactCount > 0 ? totalContactCount : cachedTotalContactCount}
				onChangePage={onChangePageHandler}
			>
				{mainContent}
			</PagingTemplate>
		);
	};

	return <>{getContent()}</>;
};

export default ContactsTableTemplate;
