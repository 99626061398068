import { IServiceFactory } from '../../../common/types/service-factory';
import { IApiService } from '../../interfaces/api-service';
import { IAppConfigService } from '../../interfaces/app-config-service';

export class ApiServiceFactoryBase {
	protected apiServiceFactory: IServiceFactory<IApiService>;
	protected appConfigService: IAppConfigService;

	constructor(apiServiceFactory: IServiceFactory<IApiService>, appConfigService: IAppConfigService) {
		this.apiServiceFactory = apiServiceFactory;
		this.appConfigService = appConfigService;
	}
}
