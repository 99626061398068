import styles from './SelectMedianValueColumnsModal.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { StringHelper } from '../../../common/helpers/string-helper';
import { setCachedSelectedMedianColumns } from '../../../features/median-values-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Callback } from '../../../types/Callbacks';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import CheckboxLabel from '../../atoms/Base/CheckboxLabel/CheckboxLabel';
import { Container } from '../../atoms/Base/Container/Container';
import { Modal } from '../../molecules/Base/Modal/Modal';

interface IMedianValuesModalProps {
	medianColumns: string[];
	selectedMedianColumn: string;
	selectedMedianColumnIndex: number;
	onCloseClick?: Callback;
}

const MedianValuesModal: FC<IMedianValuesModalProps> = ({
	medianColumns,
	selectedMedianColumn,
	selectedMedianColumnIndex,
	onCloseClick,
}) => {
	const dispatch = useAppDispatch();

	const cachedSelectedMedianColumns = useAppSelector((state) => state.medianValues.selectedMedianColumns);
	const [medianColumn, setMedianColumn] = useState<string>(StringHelper.Empty);
	const [selectedMedianColumns, setSelectedMedianColumns] = useState<string[]>([]);

	useEffect(() => {
		setMedianColumn(selectedMedianColumn);
	}, [selectedMedianColumn]);

	const onCheckBoxCheckedHandler = (medianColumn: string) => {
		const updatedSelectedMedianColumns = cachedSelectedMedianColumns.slice();

		if (
			!ArrayHelper.HasElements(updatedSelectedMedianColumns) ||
			(ArrayHelper.HasElements(updatedSelectedMedianColumns) &&
				selectedMedianColumnIndex > updatedSelectedMedianColumns.length - 1)
		) {
			updatedSelectedMedianColumns.push(medianColumn);
		} else {
			updatedSelectedMedianColumns[selectedMedianColumnIndex] = medianColumn;
		}

		setMedianColumn(StringHelper.SnakeCaseToFriendlyString(medianColumn));
		setSelectedMedianColumns(updatedSelectedMedianColumns);
	};

	const onConfirmSelectedMedianColumns = () => {
		dispatch(setCachedSelectedMedianColumns(selectedMedianColumns));
		if (onCloseClick) onCloseClick();
	};

	return (
		<Modal visible small onClose={onCloseClick}>
			<Container left topSpace bottomSpace className={styles.median_values_modal_container}>
				{medianColumns.map((columnName, index) => {
					const readableColumnName = StringHelper.SnakeCaseToFriendlyString(columnName);
					return (
						<CheckboxLabel
							key={index}
							label={readableColumnName}
							isChecked={readableColumnName === (medianColumn || selectedMedianColumn)}
							onCheckBoxChecked={() => onCheckBoxCheckedHandler(columnName)}
						/>
					);
				})}
			</Container>
			<Container right topSpace>
				<ActionButton secondary onClick={onConfirmSelectedMedianColumns}>
					Confirm
				</ActionButton>
			</Container>
		</Modal>
	);
};

export default MedianValuesModal;
