import styles from './SegmentName.module.scss';
import React, { FC } from 'react';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { StringValueCallback } from '../../../../types/Callbacks';
import { Container } from '../../../atoms/Base/Container/Container';
import Label from '../../../atoms/Base/Label/Label';
import TextBox from '../../../atoms/Base/TextBox/TextBox';

interface ISegmentNameProps {
	segmentName: string;
	segmentNameError: string;
	onChangeSegmentName: StringValueCallback;
}

const SegmentName: FC<ISegmentNameProps> = ({ segmentName, segmentNameError, onChangeSegmentName }) => {
	return (
		<Container horizontal centerFull childSpace>
			<Label className={styles.segment_name_label}>Segment Name:</Label>
			<TextBox
				bold
				defaultText={StringHelper.ValueOrEmpty(segmentName)}
				placeHolder={'Enter Segment Name'}
				errorMessage={segmentNameError}
				onChange={onChangeSegmentName}
			/>
		</Container>
	);
};

export default SegmentName;
