import styles from './SelectColumnsModal.module.scss';
import React, { FC, useEffect, useState } from 'react';
import CheckboxLabel from '../../atoms/Base/CheckboxLabel/CheckboxLabel';
import { Modal } from '../../molecules/Base/Modal/Modal';
import { Container } from '../../atoms/Base/Container/Container';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { ColumnVisibility } from '../../../common/models/column-visibility';
import { EventArgsCallback } from '../../../types/Callbacks';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { StringHelper } from '../../../common/helpers/string-helper';
import { setCachedSelectedContactColumnNames } from '../../../features/contacts-slice';
import { ArrayHelper } from '../../../common/helpers/array-helper';

interface ISelectColumnsModalProps {
	onCloseClick?: any;
	onConfirmSelectedColumnNames?: EventArgsCallback<string[]>;
}

const SelectColumnsModal: FC<ISelectColumnsModalProps> = ({ onCloseClick, onConfirmSelectedColumnNames }) => {
	const dispatch = useAppDispatch();

	const cachedSelectedContactColumnNames: string[] = useAppSelector((state) => state.contacts.selectedColumns);
	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);

	const [contactColumnNames] = useState<string[]>(
		cachedContactColumnNames.map((contactColumnName) =>
			StringHelper.SnakeCaseToFriendlyString(contactColumnName.columnName)
		)
	);
	const [selectedColumnNames, setSelectedColumnNames] = useState<string[]>([]);

	const onCheckBoxCheckedHandler = (columnVisibility: ColumnVisibility) => {
		const filteredSelections = selectedColumnNames.filter(
			(columnName) =>
				columnName !== columnVisibility.columnName ||
				(columnName === columnVisibility.columnName && columnVisibility.isVisible)
		);
		if (columnVisibility.isVisible) filteredSelections.push(columnVisibility.columnName);
		setSelectedColumnNames(filteredSelections);
		dispatch(setCachedSelectedContactColumnNames(filteredSelections));
	};

	const onConfirmSelectedColumnsHandler = () => {
		if (onConfirmSelectedColumnNames) onConfirmSelectedColumnNames(selectedColumnNames);
		onCloseClick();
	};

	useEffect(() => {
		if (ArrayHelper.HasElements(cachedSelectedContactColumnNames)) {
			setSelectedColumnNames(cachedSelectedContactColumnNames);
		} else {
			setSelectedColumnNames(
				cachedContactColumnNames
					.slice(0, 7)
					.map((contactColumnName) => StringHelper.SnakeCaseToFriendlyString(contactColumnName.columnName))
			);
		}
	}, []);

	return (
		<Modal title={'SELECT COLUMNS'} visible small onClose={onCloseClick}>
			<Container left topSpace bottomSpace className={styles.columns_container}>
				{contactColumnNames
					.filter((columnName) => columnName !== 'error')
					.map((columnName, index) => {
						const readableColumnName = StringHelper.CamelCaseToFriendlyString(columnName);
						return (
							<CheckboxLabel
								key={index}
								label={readableColumnName}
								isChecked={selectedColumnNames.includes(readableColumnName)}
								onCheckBoxChecked={(columnVisibility) => onCheckBoxCheckedHandler(columnVisibility)}
							/>
						);
					})}
			</Container>
			<Container topSpace right>
				<ActionButton secondary onClick={onConfirmSelectedColumnsHandler}>
					Confirm
				</ActionButton>
			</Container>
		</Modal>
	);
};

export default SelectColumnsModal;
