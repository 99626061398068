import styles from './DropDown.module.scss';
import React, { FC, ReactElement, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { StringValueCallback } from '../../../../types/Callbacks';

interface IDropDownProps {
	id: string;
	dataCy?: string;
	inErrorState?: boolean;
	header?: string;
	includeDefaultItem?: boolean;
	dataSource: string[];
	title?: string;
	onSelect?: StringValueCallback;
}

const DropDown: FC<IDropDownProps> = ({
	id,
	dataCy,
	inErrorState = false,
	header,
	includeDefaultItem = false,
	dataSource,
	title = 'Select',
	onSelect,
}) => {
	const [selectedTitle, setSelectedTitle] = useState<string>(StringHelper.Empty);

	return (
		<DropdownButton
			data-cy={dataCy}
			aria-required={true}
			id={id}
			variant={'secondary'}
			className={`${styles.drop_down}${inErrorState ? ` ${styles.drop_down__error}` : StringHelper.Empty}`}
			title={StringHelper.SnakeCaseToFriendlyString(title || selectedTitle)}
			onSelect={(_, event: any) => {
				if (onSelect) onSelect(event.target.id);
				else setSelectedTitle(event.target.innerHTML);
			}}
		>
			{header && <Dropdown.Header>{title}</Dropdown.Header>}
			{header && <Dropdown.Divider />}
			{includeDefaultItem && <Dropdown.Item>Clear</Dropdown.Item>}
			{dataSource.map((value, index) => {
				return (
					<Dropdown.Item id={value} key={index} as={'button'}>
						{StringHelper.SnakeCaseToFriendlyString(value)}
					</Dropdown.Item>
				);
			})}
		</DropdownButton>
	);
};

export default DropDown;
