import { FieldType } from '../enums/field-type-enums';
import {
	GenericFilterOption,
	SegmentDateFilterOption,
	SegmentNumberFilterOption,
	SegmentStringFilterOption,
} from '../enums/segment-enums';
import { ColumnFilterOptions } from '../models/column-filter';

const SEGMENT_DATE_FILTER_OPTIONS: string[] = [
	SegmentDateFilterOption.EqualTo,
	SegmentDateFilterOption.AfterOrOn,
	SegmentDateFilterOption.After,
	SegmentDateFilterOption.Before,
	SegmentDateFilterOption.BeforeOrOn,
	SegmentDateFilterOption.Between,
	GenericFilterOption.Known,
	GenericFilterOption.Unknown,
];

const SEGMENT_NUMBER_FILTER_OPTIONS: string[] = [
	SegmentNumberFilterOption.GreaterThan,
	SegmentNumberFilterOption.GreaterThanOrEqualTo,
	SegmentNumberFilterOption.LessThan,
	SegmentNumberFilterOption.LessThanOrEqualTo,
	SegmentNumberFilterOption.Between,
	SegmentNumberFilterOption.NotEqualTo,
	GenericFilterOption.Known,
	GenericFilterOption.Unknown,
];

const SEGMENT_STRING_FILTER_OPTIONS: string[] = [
	SegmentStringFilterOption.AnyOf,
	SegmentStringFilterOption.NoneOf,
	SegmentStringFilterOption.ContainsExactly,
	SegmentStringFilterOption.DoesNotContainExactly,
	SegmentStringFilterOption.StartsWith,
	SegmentStringFilterOption.EndsWith,
	GenericFilterOption.Known,
	GenericFilterOption.Unknown,
];

export const COLUMN_FILTER_OPTIONS_MAP = new Map<string, ColumnFilterOptions>([
	[
		FieldType.String,
		{
			filterOptions: SEGMENT_STRING_FILTER_OPTIONS,
			filterColumnType: FieldType.String,
		},
	],
	[
		FieldType.Number,
		{
			filterOptions: SEGMENT_NUMBER_FILTER_OPTIONS,
			filterColumnType: FieldType.Number,
		},
	],
	[
		FieldType.Date,
		{
			filterOptions: SEGMENT_DATE_FILTER_OPTIONS,
			filterColumnType: FieldType.Date,
		},
	],
]);
