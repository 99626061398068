import { configureStore } from '@reduxjs/toolkit';
import activityReducer from '../features/activity-slice';
import contactsReducer from '../features/contacts-slice';
import medianValuesReducer from '../features/median-values-slice';
import segmentsReducer from '../features/segments-slice';

const store = configureStore({
	reducer: {
		activity: activityReducer,
		contacts: contactsReducer,
		medianValues: medianValuesReducer,
		segments: segmentsReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
