import styles from './Contacts.module.scss';
import React, { useEffect, useState } from 'react';
import TextBox from '../../atoms/Base/TextBox/TextBox';
import ContactsTableTemplate from '../../templates/ContactsTableTemplate/ContactsTableTemplate';
import Label from '../../atoms/Base/Label/Label';
import { StringHelper } from '../../../common/helpers/string-helper';
import { useContactsApiService } from '../../../api/providers/AppServiceProvider';
import { useAppDispatch } from '../../../redux/hooks';
import { setCachedTotalContactCount } from '../../../features/contacts-slice';
import { StringValueCallback } from '../../../types/Callbacks';
import { Container } from '../../atoms/Base/Container/Container';

const Contacts = () => {
	const dispatch = useAppDispatch();
	const contactsApiService = useContactsApiService();

	const [totalContactCount, setTotalContactCount] = useState<number>(0);
	const [searchTerm, setSearchTerm] = useState<string>(StringHelper.Empty);

	const onChangeHandler: StringValueCallback = (newValue: string) => {
		setSearchTerm(newValue.toLowerCase());
	};

	useEffect(() => {
		contactsApiService.GetTotalCount().then((response) => {
			dispatch(setCachedTotalContactCount(response.count));
			setTotalContactCount(response.count);
		});
	}, []);

	return (
		<div>
			<Container>
				<div className={styles.contacts_list_container}>
					<div className={styles.title}>
						<h3>Contacts List</h3>
					</div>
					<div className={styles.search}>
						<TextBox placeHolder={'Search'} showSearchIcon={true} onChange={onChangeHandler} />
					</div>
					{totalContactCount > 0 && (
						<div className={styles.record_number}>
							<Label>Total number of contacts ({totalContactCount})</Label>
						</div>
					)}
				</div>
				<Container topSpaceLarge>
					<ContactsTableTemplate searchTerm={searchTerm} isDefault paging />
				</Container>
			</Container>
		</div>
	);
};

export default Contacts;
