/* eslint-disable no-unused-vars */
export const enum Page {
	Home = 'Home',
	Contacts = 'Contacts',
	Segments = 'Segments',
	Campaigns = 'Campaigns',
	DataInsights = 'DataInsights',
	Help = 'Help',
	Settings = 'Settings',
}
