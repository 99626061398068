/* eslint-disable no-unused-vars */
import styles from './SegmentFilterOptionsModal.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
	SegmentDateFilterOption,
	SegmentNumberFilterOption,
	SegmentRecordType,
	SegmentStringFilterOption,
} from '../../../common/enums/segment-enums';
import DateInput from '../../atoms/Base/DateInput/DateInput';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { SegmentHelper } from '../../../common/helpers/segment-helper';
import { StringHelper } from '../../../common/helpers/string-helper';
import { ModifiedFilter, SelectedFilter } from '../../../common/models/filter';
import DropDown from '../../atoms/Base/DropDown/DropDown';
import SegmentFilterSelections from '../../molecules/Custom/SegmentFilterSelections/SegmentFilterSelections';
import TextBox from '../../atoms/Base/TextBox/TextBox';
import { ColumnFilterOptions } from '../../../common/models/column-filter';
import { COLUMN_FILTER_OPTIONS_MAP } from '../../../common/constants/column-filter-enums';
import { useReferenceValuesApiService } from '../../../api/providers/AppServiceProvider';
import { DateHelper } from '../../../common/helpers/date-helper';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { Modal } from '../../molecules/Base/Modal/Modal';
import { Container } from '../../atoms/Base/Container/Container';
import { Callback, StringValueCallback } from '../../../types/Callbacks';
import Label from '../../atoms/Base/Label/Label';
import DualButton from '../../molecules/Custom/DualButton/DualButton';
import RadioButton from '../../atoms/Base/RadioButton/RadioButton';
import { useAppSelector } from '../../../redux/hooks';
import { FieldType } from '../../../common/enums/field-type-enums';

interface ISegmentFilterOptionsModalProps {
	selectedFilter: SelectedFilter;
	currentSegmentName?: string;
	onCloseClick?: Callback;
	onColumnSelected?: (columnName: string) => void;
	onConfirmFilter?: (modifiedFilter: ModifiedFilter, columnName?: string) => void;
}

const SegmentFilterOptionsModal: FC<ISegmentFilterOptionsModalProps> = ({
	selectedFilter: { filterIndex, orGroupIndex, columnFilterItem, columnFilterOptions, isNewFilter },
	currentSegmentName,
	onCloseClick,
	onConfirmFilter,
	onColumnSelected,
}) => {
	const referenceValuesApiService = useReferenceValuesApiService();

	const cachedActivityColumnNames = useAppSelector((state) => state.activity.columnNames);
	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);

	const [activityTableColumnNames] = useState<string[]>(
		cachedActivityColumnNames.map((activityColumnName) => activityColumnName.columnName)
	);
	const [contactTableColumnNames] = useState<string[]>(
		cachedContactColumnNames.map((contactColumnName) => contactColumnName.columnName)
	);

	const [anyOfDropDownDataSource, setAnyOfDropDownDataSource] = useState<string[]>([]);
	const [selectedColumn, setSelectedColumn] = useState<string>(columnFilterItem.columnName!);
	const [selectedColumnFilterOptions, setSelectedColumnFilterOptions] =
		useState<ColumnFilterOptions>(columnFilterOptions);

	const [selectedFilter, setSelectedFilter] = useState<string>(StringHelper.Empty);
	const [selectedValueOption, setSelectedValueOption] = useState<string>('Select');
	const [showPrimaryDateInput, setShowPrimaryDateInput] = useState<boolean>(false);
	const [showSecondaryDateInput, setShowSecondaryDateInput] = useState<boolean>(false);
	const [showAnyOfDropdown, setShowAnyOfDropdown] = useState<boolean>(false);
	const [showPrimaryTextBox, setShowPrimaryTextBox] = useState<boolean>(false);
	const [showSecondaryTextBox, setShowSecondaryTextBox] = useState<boolean>(false);
	const [showSelections, setShowSelections] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<string>(StringHelper.Empty);
	const [endDate, setEndDate] = useState<string>(StringHelper.Empty);
	const [selections, setSelections] = useState<string[]>(
		columnFilterItem.columnType === FieldType.String ? columnFilterItem.columnValues! : []
	);
	const [tableColumns, setTableColumns] = useState<string[]>([]);
	const [primaryText, setPrimaryText] = useState<string>(StringHelper.Empty);
	const [secondaryText, setSecondaryText] = useState<string>(StringHelper.Empty);
	const [recordType, setRecordType] = useState<string>(columnFilterItem?.recordType);
	const [tablePrefix, setTablePrefix] = useState<string>(columnFilterItem?.tablePrefix);
	const [segmentName, setSegmentName] = useState<string>(StringHelper.ValueOrEmpty(currentSegmentName));
	const [segmentNameError, setSegmentNameError] = useState<string>(StringHelper.Empty);
	const [showFilterOptions, setShowFilterOptions] = useState<boolean>(
		StringHelper.IsNotNullOrEmpty(currentSegmentName)
	);

	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>();

	useEffect(() => {
		if (!isNewFilter) selectColumn(columnFilterItem.columnName!);

		setTableColumns(
			columnFilterItem?.recordType === SegmentRecordType.Contact
				? contactTableColumnNames
				: activityTableColumnNames
		);
		if (columnFilterOptions) {
			setInputVisibility(columnFilterItem.columnOperator || columnFilterOptions.filterOptions[0]);
		}

		if (columnFilterItem.columnType === FieldType.Date && columnFilterItem.columnValues) {
			if (columnFilterItem.columnValues?.length >= 1) setStartDate(columnFilterItem.columnValues[0]);
			if (columnFilterItem.columnValues?.length >= 2) setEndDate(columnFilterItem.columnValues[1]);
		}

		if (columnFilterItem.columnValues && ArrayHelper.HasElements(columnFilterItem.columnValues)) {
			setPrimaryText(columnFilterItem.columnValues[0]);
			if (columnFilterItem.columnValues.length === 2) setSecondaryText(columnFilterItem.columnValues[1]);
		}
	}, []);

	const setInputVisibility = (selectedOption: string) => {
		if (SegmentHelper.ShouldClearSelection(selectedFilter, selectedOption)) setSelections([]);

		setSelectedFilter(selectedOption);
		setShowSecondaryDateInput(selectedOption === SegmentDateFilterOption.Between);
		setShowSecondaryTextBox(selectedOption === SegmentNumberFilterOption.Between);

		setShowAnyOfDropdown(SegmentHelper.ShouldDisplayAnyOfDropdown(selectedOption));
		setShowPrimaryDateInput(SegmentHelper.ShouldDisplayPrimaryDateInput(selectedOption));
		setShowPrimaryTextBox(SegmentHelper.ShouldDisplayTextBox(selectedOption));
		setShowSelections(SegmentHelper.ShouldDisplaySelections(selectedOption));
	};

	const onConfirmFilterHandler = () => {
		if (
			StringHelper.IsNullOrEmpty(selectedColumn) ||
			!SegmentHelper.FilterOptionIsValid(
				selectedColumnFilterOptions.filterColumnType,
				selectedFilter,
				selections,
				[primaryText || startDate, secondaryText || endDate]
			)
		)
			return;

		const columnName = !selectedColumn.startsWith(tablePrefix)
			? `${tablePrefix}.${selectedColumn}`
			: selectedColumn;

		let filterValue = StringHelper.Empty;
		switch (selectedColumnFilterOptions?.filterColumnType) {
			case FieldType.Date:
				filterValue = startDate;
				if (endDate && showSecondaryDateInput) filterValue = `${filterValue} AND ${endDate}`;
				break;
			case FieldType.Number:
				filterValue = primaryText;
				if (secondaryText && showSecondaryTextBox) filterValue = `${filterValue} AND ${secondaryText}`;
				break;
			case FieldType.String:
				filterValue = SegmentHelper.ConstructFilterValue(columnName, primaryText, selectedFilter, selections);
				break;
		}

		const modifiedFilter: ModifiedFilter = {
			segmentName: segmentName,
			filterIndex: filterIndex,
			orGroupIndex: orGroupIndex,
			filterOption: selectedFilter,
			filterColumn: columnName,
			filterColumnValue: `${filterValue}`,
			filterString:
				selectedFilter === SegmentStringFilterOption.StartsWith ||
				selectedFilter === SegmentStringFilterOption.EndsWith
					? filterValue
					: `${columnName} ${selectedFilter} ${filterValue}`.trim(),
			isNewFilter: isNewFilter,
		};

		if (onConfirmFilter) {
			onConfirmFilter(modifiedFilter, columnFilterItem.columnName);
		}
	};

	const selectColumn = (columnName: string) => {
		const fullColumnName = `${tablePrefix}.${columnName}`;

		let columnDataType: string | undefined = StringHelper.Empty;
		if (recordType === SegmentRecordType.Contact) {
			columnDataType = StringHelper.Capitalize(
				cachedContactColumnNames.find((contactColumnName) => contactColumnName.columnName === columnName)
					?.dataType
			);
		} else {
			columnDataType = StringHelper.Capitalize(
				cachedActivityColumnNames.find((activityColumnName) => activityColumnName.columnName === columnName)
					?.dataType
			);
		}
		const columnFilterOptions = COLUMN_FILTER_OPTIONS_MAP.get(columnDataType);
		const currentSelectedFilter = columnFilterItem.columnOperator;

		if (columnDataType === FieldType.Date) {
			setInputVisibility(SegmentDateFilterOption.EqualTo);
			setSelectedFilter(StringHelper.ValueOrDefault(currentSelectedFilter, SegmentDateFilterOption.EqualTo));
		}

		if (columnDataType === FieldType.Number) {
			setInputVisibility(SegmentNumberFilterOption.GreaterThan);
			setSelectedFilter(
				StringHelper.ValueOrDefault(currentSelectedFilter, SegmentNumberFilterOption.GreaterThan)
			);
		}

		if (columnDataType === FieldType.String) {
			setInputVisibility(SegmentStringFilterOption.AnyOf);
			setSelectedFilter(StringHelper.ValueOrDefault(currentSelectedFilter, SegmentStringFilterOption.AnyOf));
			if (tablePrefix === SegmentHelper.ActivityTablePrefix) {
				referenceValuesApiService.GetActivityReferenceValues(columnName).then((response) => {
					setAnyOfDropDownDataSource(response.map((referenceValue) => referenceValue.value));
				});
			} else {
				referenceValuesApiService.GetContactReferenceValues(columnName).then((response) => {
					setAnyOfDropDownDataSource(response.map((referenceValue) => referenceValue.value));
				});
			}
		}

		setSelectedColumnFilterOptions(columnFilterOptions!);
		setSelectedColumn(columnName);
		if (onColumnSelected) onColumnSelected(fullColumnName);
	};

	const onSelectColumnHandler: StringValueCallback = (newValue: string) => {
		selectColumn(newValue);
		setSelections([]);
	};

	const onSelectRadioButtonHandler = (event: any) => {
		const selectedOption = event.target.id;

		setInputVisibility(selectedOption);
	};

	const onSelectFilterOptionValueHandler: StringValueCallback = (newValue: string) => {
		setSelectedValueOption(newValue);
		if (!selections.includes(newValue)) {
			setSelections([...selections, newValue]);
		}
	};

	const onChangeColumnHandler: StringValueCallback = (newValue: string) => {
		if (timeoutId) clearTimeout(timeoutId);

		const id = setTimeout(() => {
			const matchingColumn = tableColumns.find((column) =>
				StringHelper.SnakeCaseToFriendlyString(column).toLowerCase().includes(newValue.toLowerCase())
			);
			if (StringHelper.IsNotNullOrEmpty(matchingColumn)) selectColumn(matchingColumn!);
		}, 1000);

		setTimeoutId(id);
	};

	const onChangePrimaryTextHandler: StringValueCallback = (newValue: string) => {
		setPrimaryText(newValue);
	};

	const onChangeSecondaryTextHandler: StringValueCallback = (newValue: string) => {
		setSecondaryText(newValue);
	};

	const onAddTextHandler = () => {
		if (!selections.includes(primaryText) && primaryText !== StringHelper.Empty) {
			setSelections([...selections, primaryText]);
			setPrimaryText(StringHelper.Empty);
		}
	};

	const onSelectRecordTypeHandler = (recordType: string) => {
		const selectedColumns =
			recordType === SegmentRecordType.Contact ? contactTableColumnNames : activityTableColumnNames;
		const tablePrefix =
			recordType === SegmentRecordType.Contact
				? SegmentHelper.ContactTablePrefix
				: SegmentHelper.ActivityTablePrefix;
		const fullColumnName = `${tablePrefix}.${selectedColumns[0]}`;

		setRecordType(recordType);
		setTablePrefix(tablePrefix);
		setTableColumns(selectedColumns);
		setSelections([]);
		setSelectedColumn(selectedColumns[0]);
		setSelectedColumnFilterOptions(COLUMN_FILTER_OPTIONS_MAP.get(fullColumnName)!);

		setInputVisibility(StringHelper.Empty);
	};

	const onChangeSegmentNameHandler: StringValueCallback = (newValue: string) => {
		setSegmentNameError(StringHelper.Empty);
		setSegmentName(newValue);
	};

	const onContinueToFilterOptionsHandler = () => {
		if (StringHelper.IsNullOrEmpty(segmentName)) setSegmentNameError('Please enter a Segment Name');

		setShowFilterOptions(StringHelper.IsNotNullOrEmpty(segmentName));
	};

	const generateRadioButtonOptions = (startIndex: number, endIndex: number) => {
		return selectedColumnFilterOptions?.filterOptions?.slice(startIndex, endIndex).map((filter, index) => {
			return (
				<RadioButton
					key={index}
					checked={filter === selectedFilter}
					id={`${filter}`}
					label={SegmentHelper.MapOptionToDescription(selectedColumnFilterOptions.filterColumnType, filter)}
					onChange={onSelectRadioButtonHandler}
				/>
			);
		});
	};

	const isDateOption = selectedColumnFilterOptions && selectedColumnFilterOptions.filterColumnType === FieldType.Date;

	const isStandardStringNumberOption =
		selectedColumnFilterOptions &&
		(selectedColumnFilterOptions.filterColumnType === FieldType.String ||
			selectedColumnFilterOptions.filterColumnType === FieldType.Number);

	var halfLength = Math.ceil(selectedColumnFilterOptions?.filterOptions?.length / 2);
	const endIndex = selectedColumnFilterOptions?.filterOptions?.length;

	return (
		<Modal title={!showFilterOptions ? 'SEGMENT NAME' : 'FILTER PANEL'} visible onClose={onCloseClick}>
			{!showFilterOptions && (
				<Container fullWidth horizontal childSpaceLarge>
					<Container fullWidth>
						<TextBox
							defaultText={StringHelper.ValueOrEmpty(segmentName)}
							placeHolder={'Enter Segment Name'}
							errorMessage={segmentNameError}
							onChange={onChangeSegmentNameHandler}
						/>
					</Container>
					<Container>
						<ActionButton secondary onClick={onContinueToFilterOptionsHandler}>
							Save
						</ActionButton>
					</Container>
				</Container>
			)}
			{StringHelper.IsNotNullOrEmpty(segmentName) && showFilterOptions && (
				<Container className={styles.filter_options_modal_container} childSpace>
					<Container fullWidth horizontal centerFull childSpace>
						<Container center>
							<DualButton
								optionOne={SegmentRecordType.Contact}
								optionTwo={SegmentRecordType.Activity}
								selectedOption={columnFilterItem.recordType || SegmentRecordType.Contact}
								onOptionSelected={onSelectRecordTypeHandler}
							/>
						</Container>
					</Container>
					<Container className={styles.column_selection_container} horizontal fullWidth childSpace>
						<Container fullWidth>
							<DropDown
								id={'column-options-dropdown'}
								dataSource={tableColumns}
								title={StringHelper.ValueOrDefault(selectedColumn, 'Select Column')}
								onSelect={onSelectColumnHandler}
							/>
						</Container>
						<Container className={styles.text_box_container} fullWidth>
							<TextBox onChange={onChangeColumnHandler} />
						</Container>
					</Container>
					{selections.length > 0 && showSelections && (
						<SegmentFilterSelections
							scrollable
							selections={selections}
							onSelectionRemoved={(selectionIndex: number) =>
								setSelections(selections.filter((_, index) => index !== selectionIndex))
							}
						/>
					)}
					<Container horizontal childSpaceLarge fullWidth>
						<Container left fullWidth>
							<Form.Group>
								{selectedColumnFilterOptions && generateRadioButtonOptions(0, halfLength)}
							</Form.Group>
						</Container>
						<Container left fullWidth>
							<Form.Group>
								{selectedColumnFilterOptions && generateRadioButtonOptions(halfLength, endIndex)}
							</Form.Group>
						</Container>
					</Container>

					{isDateOption && showPrimaryDateInput && (
						<DateInput
							className={styles.date_input}
							value={startDate}
							onChange={(event: any) => {
								setStartDate(DateHelper.ConvertToValidDateInputDate(event.target.value));
							}}
						/>
					)}
					{isDateOption && showSecondaryDateInput && (
						<DateInput
							className={styles.date_input}
							value={endDate}
							onChange={(event: any) => {
								setEndDate(DateHelper.ConvertToValidDateInputDate(event.target.value));
							}}
						/>
					)}
					{showAnyOfDropdown && (
						<DropDown
							id={'any-of-dropdown'}
							dataSource={anyOfDropDownDataSource}
							title={selectedValueOption}
							onSelect={onSelectFilterOptionValueHandler}
						/>
					)}
					{isStandardStringNumberOption && showPrimaryTextBox && (
						<div
							className={
								SegmentHelper.ShouldDisplayAddButton(selectedFilter)
									? styles.add_text_container_two_col
									: styles.add_text_container
							}
						>
							<TextBox
								onChange={onChangePrimaryTextHandler}
								defaultText={primaryText}
								type={selectedColumnFilterOptions.filterColumnType.toLowerCase()}
							/>
							{SegmentHelper.ShouldDisplayAddButton(selectedFilter) && (
								<ActionButton onClick={onAddTextHandler}>Add</ActionButton>
							)}
						</div>
					)}
					{isStandardStringNumberOption && showSecondaryTextBox && (
						<TextBox
							onChange={onChangeSecondaryTextHandler}
							defaultText={secondaryText}
							type={selectedColumnFilterOptions.filterColumnType.toLowerCase()}
						/>
					)}

					<Container centerFull horizontal childSpace topSpace>
						<ActionButton cancel onClick={onCloseClick}>
							Cancel
						</ActionButton>
						<ActionButton secondary onClick={onConfirmFilterHandler}>
							Confirm
						</ActionButton>
					</Container>
				</Container>
			)}
		</Modal>
	);
};

export default SegmentFilterOptionsModal;
