import MsalDataApiService from 'api/services/msal-api-service';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
	async (config) => {
		const token = await MsalDataApiService.GetAccessToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		const navigate = useNavigate();
		if (error.response.status === 401) {
			navigate('/login');
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
