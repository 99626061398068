import styles from './MedianValueChartsModal.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { Callback } from '../../../types/Callbacks';
import { Container } from '../../atoms/Base/Container/Container';
import { Modal } from '../../molecules/Base/Modal/Modal';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';
import { useSegmentsApiService } from 'api/providers/AppServiceProvider';
import { setCachedMedianValueCharts } from 'features/median-values-slice';
import { ArrayHelper } from 'common/helpers/array-helper';
import Label from 'components/atoms/Base/Label/Label';
import CXDataLoader from 'components/atoms/Custom/CXDataLoader/CXDataLoader';
import { GetMedianValueChartsRequest } from '../../../models/median-values/requests/get-median-value-charts-request';
import { StringHelper } from '../../../common/helpers/string-helper';
import { MedianValueChartList } from '../../../models/median-values/median-value-chart-list';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

interface IMedianValuesInsightModalProps {
	medianColumns: string[];
	medianValues: number[];
	whereClause: string;
	onCloseClick?: Callback;
}

const MedianValuesInsightModal: FC<IMedianValuesInsightModalProps> = ({
	medianColumns,
	medianValues,
	whereClause,
	onCloseClick,
}) => {
	const dispatch = useAppDispatch();

	const batchSize = 4;

	const segmentsApiService = useSegmentsApiService();
	const [medianValueCharts, setMedianValueCharts] = useState<MedianValueChartList[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLazyLoading, setIsLazyLoading] = useState<boolean>(true);
	const [startIndex, setStartIndex] = useState<number>(0);
	const [endIndex, setEndIndex] = useState<number>(batchSize);

	const optionsdata = {
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false,
				},
				ticks: {
					font: {
						size: 9,
					},
				},
			},
			x: {
				beginAtZero: true,
				grid: {
					display: false,
				},
				ticks: {
					font: {
						size: 9,
					},
				},
			},
		},
		plugins: {
			tooltip: {
				displayColors: false,
			},
		},
	};

	useEffect(() => {
		if (startIndex === medianColumns.length) {
			setIsLazyLoading(false);
			return;
		}

		const updatedEndIndex = endIndex > medianColumns.length ? medianColumns.length : endIndex;
		loadChartData(startIndex, updatedEndIndex);
	}, [endIndex]);

	const loadChartData = async (startIndex: number = 0, endIndex: number = batchSize) => {
		if (startIndex === 0) setIsLoading(true);
		const getMedianValueChartsRequest: GetMedianValueChartsRequest = {
			menuNames: medianColumns.slice(startIndex, endIndex),
			where: whereClause,
		};
		segmentsApiService.GetMedianValueCharts(getMedianValueChartsRequest).then(
			(response) => {
				if (response != null) {
					dispatch(setCachedMedianValueCharts([...medianValueCharts, ...response]));
					setMedianValueCharts([...medianValueCharts, ...response]);

					setStartIndex(startIndex + response.length);
					setEndIndex(endIndex + batchSize);
					setIsLoading(false);
				}
			},
			(error) => {
				setIsLoading(false);
				console.log(error);
			}
		);
	};

	return (
		<Modal title={'AUDIENCE INSIGHTS'} visible scroll={true} onClose={onCloseClick}>
			<Container className={styles.chart_modal_container}>
				<Container>
					<div className="row">
						{isLoading && <CXDataLoader simple />}
						{ArrayHelper.HasElements(medianValueCharts) &&
							medianValueCharts &&
							medianValueCharts.map((item, index) => {
								let dataset = {
									labels: item.medianValueChartListItems.map((x: any) => {
										return x.bucketRange;
									}),
									datasets: [
										{
											label: '',
											data: item.medianValueChartListItems.map((x: any) => {
												return x.count;
											}),
											backgroundColor: '#0052cd',
										},
									],
								};
								return (
									<div key={index} className="col-md-6 mb-4">
										<div className={`card h-100 w-100 ${styles.card_container}`}>
											<div className="card-body rounded">
												<div className={styles.median_header_value}>
													<Label className="text-uppercase mb-4 w-75">
														{StringHelper.SnakeCaseToFriendlyString(medianColumns[index])}
													</Label>
													<Label className="text-uppercase mb-4">
														{medianValues.length > index ? medianValues[index] : ''}
													</Label>
												</div>
												<div className={styles.chart_container}>
													<Bar data={dataset} options={optionsdata} />
												</div>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</Container>
				<Container>{isLazyLoading && !isLoading && <CXDataLoader simple />}</Container>
			</Container>
		</Modal>
	);
};

export default MedianValuesInsightModal;
