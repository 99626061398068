/* eslint-disable no-unused-vars */
import styles from './SegmentFilterSelections.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { SegmentHelper } from '../../../../common/helpers/segment-helper';
import { StringHelper } from '../../../../common/helpers/string-helper';

interface ISegmentFilterSelectionsProps {
	canRemove?: boolean;
	scrollable?: boolean;
	displayFirstItemOnly?: boolean;
	selections: string[];
	onClick?: any;
	onSelectionRemoved?: (index: number) => void;
}

const SegmentFilterSelections: FC<ISegmentFilterSelectionsProps> = ({
	canRemove = true,
	scrollable,
	displayFirstItemOnly = false,
	selections,
	onClick,
	onSelectionRemoved,
}) => {
	const [currentSelections, setCurrentSelections] = useState<string[]>([]);
	const [selectionCount, setSelectionCount] = useState<number>(0);
	const maxCharactersToDisplay = 13;

	const classNameBuilderFactory = useClassNameBuilderFactory();

	const classNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.segment_filter_selections)
		.AddConditionalClass(displayFirstItemOnly, styles.display_first_item_only)
		.AddConditionalClass(scrollable, styles.scrollable)
		.AddConditionalClass(onClick, styles.clickable)
		.GetClassNames();

	useEffect(() => {
		const remappedSelections: string[] = [];
		selections.forEach((selection) => {
			remappedSelections.push(...selection.split('&&').map((selection) => selection.trim()));
		});
		setSelectionCount(remappedSelections.length);
		setCurrentSelections(displayFirstItemOnly ? [...remappedSelections.slice(0, 1)] : remappedSelections);
	}, [selections]);

	const onRemoveSelectionHandler = (selectionIndex: number) => {
		setCurrentSelections(currentSelections.filter((_, index) => index !== selectionIndex));
		if (onSelectionRemoved) onSelectionRemoved(selectionIndex);
	};

	const sanitiseSelection = (selection: string) => {
		const regEx = /^(?:[\s'%\(\)]*)([a-zA-Z\d\s\-]*)/;
		const match = regEx.exec(selection)?.map((match) => match.trim()) || [];
		const matchedItem = match[1];
		return (displayFirstItemOnly && matchedItem.length > maxCharactersToDisplay && selectionCount > 1) ||
			matchedItem.length > maxCharactersToDisplay
			? `${matchedItem.substring(0, maxCharactersToDisplay)}...`
			: matchedItem;
	};

	const formatSelection = (selection: string) => {
		// The second part of the ternary is for instances where values contain a single quote
		// in addition to the surrounding single quotes e.g. Lao People's Democratic Republic
		const formattedSelection = displayFirstItemOnly
			? sanitiseSelection(selection)
			: SegmentHelper.AddApostrophe(
					selection
						.replace(StringHelper.DoubleSingleQuote, StringHelper.SingleQuote)
						.replace('|', StringHelper.SingleQuote),
					true
			  );
		return StringHelper.SnakeCaseToFriendlyString(formattedSelection);
	};

	return (
		<ul className={classNames} onClick={onClick}>
			{currentSelections.map((selection, index) => {
				return (
					<li key={`list-item-${index}`}>
						{formatSelection(selection)}
						{canRemove && (
							<span className={styles.remove_selection} onClick={() => onRemoveSelectionHandler(index)}>
								<VscChromeClose size={15} />
							</span>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default SegmentFilterSelections;
