export interface IAppConfigService {
	get version(): string;
	get cxDataLocalApiUrl(): string;
	get cxDataApiUrl(): string;
}

export class AppConfigService implements IAppConfigService {
	public get version(): string {
		return this.getConfigurationSetting(process.env.REACT_APP_VERSION);
	}

	public get cxDataLocalApiUrl(): string {
		return this.getConfigurationSetting(process.env.REACT_APP_CX_DATA_LOCAL_API_URL);
	}

	public get cxDataApiUrl(): string {
		return this.getConfigurationSetting(process.env.REACT_APP_API_URL);
	}

	private getConfigurationSetting(configValue: string | undefined): string {
		if (!configValue) throw new Error('Unable to access requested config value');

		return configValue;
	}
}
