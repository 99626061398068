import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { PrimaryRoutes } from '../../../common/enums/internal-route-enums';
import { loginRequest } from '../../../authConfig';
import Home from '../Home/Home';
import Contacts from '../Contacts/Contacts';
import ContactDetails from '../ContactDetails/ContactDetails';
import Segments from '../Segments/Segments';
import SegmentBuilder from '../SegmentBuilder/SegmentBuilder';
import { Provider } from 'react-redux';
import store from '../../../redux/store';
import { ReactKit } from '../../../common/providers/ReactKitProvider';
import { AppServiceProvider } from '../../../api/providers/AppServiceProvider';
import PageLayout from '../../../framework/PageLayout';
import DataInsights from '../DataInsights/DataInsights';
import Campaigns from '../Campaigns/Campaigns';
import Settings from '../Settings/Settings';
import { clarity } from 'react-microsoft-clarity';
import { StringHelper } from '../../../common/helpers/string-helper';

const App: FC = () => {
	const { accounts, instance } = useMsal();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (process.env.NODE_ENV == 'production' && StringHelper.IsNotNullOrEmpty(process.env.REACT_APP_CLARITY_ID)) {
			clarity.init(process.env.REACT_APP_CLARITY_ID!);
		}

		if (accounts.length === 0) {
			instance
				.ssoSilent(loginRequest)
				.then((response) => {
					// Do nothing for now as msal handles the page refresh.
					setIsLoading(false);
				})
				.catch(() => {
					setIsLoading(false);
					// Don't do anything here and allow manual login.
					// Log the error to an external logging service.
				});
		} else {
			setIsLoading(false);
		}
	}, []);

	return (
		<Provider store={store}>
			<Router>
				<ReactKit>
					<AppServiceProvider>
						<PageLayout isLoading={isLoading}>
							<Routes>
								<Route path={PrimaryRoutes.Home} element={<Home isLoading={isLoading} />} />
								<Route path={PrimaryRoutes.Contacts} element={<Contacts />} />
								<Route path={PrimaryRoutes.ContactDetails} element={<ContactDetails />} />
								<Route path={PrimaryRoutes.SegmentBuilder} element={<SegmentBuilder />} />
								<Route path={PrimaryRoutes.SegmentBuilderDetails} element={<SegmentBuilder />} />
								<Route path={PrimaryRoutes.Segments} element={<Segments />} />
								<Route path={PrimaryRoutes.Campaigns} element={<Campaigns />} />
								<Route path={PrimaryRoutes.DataInsights} element={<DataInsights />} />
								<Route path={PrimaryRoutes.Settings} element={<Settings />} />
							</Routes>
						</PageLayout>
					</AppServiceProvider>
				</ReactKit>
			</Router>
		</Provider>
	);
};

export default App;
