/* eslint-disable no-unused-vars */
import { PowerBIResponse } from 'models/powerbi';
import { ApiServiceBase } from './base/api-service-base';

const baseEndpoint = 'embedded';

export interface IPowerBIApiService {
	getPowerBIDataInsightsService(): Promise<PowerBIResponse>;
	getPowerBICampaignsService(): Promise<PowerBIResponse>;
	getPowerBIDashboardService(): Promise<PowerBIResponse>;
	getEmbeddedToken(pageName: string): Promise<PowerBIResponse>;
}

export class PowerBIApiService extends ApiServiceBase implements IPowerBIApiService {
	public async getPowerBIDataInsightsService(): Promise<PowerBIResponse> {
		return await this.GetFromApi<any>(`${baseEndpoint}/data-insights`);
	}

	public async getPowerBIDashboardService(): Promise<PowerBIResponse> {
		return await this.GetFromApi<any>(`${baseEndpoint}/dashboard`);
	}

	public async getPowerBICampaignsService(): Promise<PowerBIResponse> {
		return await this.GetFromApi<any>(`${baseEndpoint}/campaigns`);
	}

	public async getEmbeddedToken(pageName: string): Promise<PowerBIResponse> {
		return await this.GetFromApi<any>(`${baseEndpoint}/refresh-token/${pageName}`);
	}
}
