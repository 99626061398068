import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SegmentView } from '../models/segments/segment-view';

// Define a type for the slice state
interface SegmentsState {
	contactCount: number;
	orGroups: string[];
	refresh: boolean;
	views: SegmentView[];
}

// Define the initial state using that type
const initialState: SegmentsState = {
	contactCount: 0,
	orGroups: [],
	refresh: false,
	views: [],
};

export const segmentsSlice = createSlice({
	name: 'segments',
	// "segments" will infer the state type from the `initialState` argument.
	initialState,
	reducers: {
		setCachedSegmentViewContactCount: (state, action: PayloadAction<number>) => {
			state.contactCount = action.payload;
		},
		setCachedOrGroups: (state, action: PayloadAction<string[]>) => {
			state.orGroups = action.payload;
		},
		setCachedRefreshState: (state, action: PayloadAction<boolean>) => {
			state.refresh = action.payload;
		},
		setCachedSegmentViews: (state, action: PayloadAction<SegmentView[]>) => {
			state.views = action.payload;
		},
	},
});

export const { setCachedSegmentViewContactCount, setCachedOrGroups, setCachedRefreshState, setCachedSegmentViews } =
	segmentsSlice.actions;

export default segmentsSlice.reducer;
