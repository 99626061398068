import styles from './TableBody.module.scss';
import React, { FC, ReactNode } from 'react';
import { StringHelper } from '../../../../common/helpers/string-helper';

interface ITableBodyProps {
	bodyOnly?: boolean;
	children: ReactNode;
	rowCount: number;
}

const TableBody: FC<ITableBodyProps> = ({ bodyOnly = false, children, rowCount }) => {
	return (
		<tbody
			className={`${bodyOnly ? styles.body_only : styles.default_body} ${
				rowCount === 1 ? styles.single_row : StringHelper.Empty
			}`}
		>
			{children}
		</tbody>
	);
};

export default TableBody;
