/* eslint-disable no-unused-vars */
export const enum PrimaryRoutes {
	Home = '/',
	Contacts = '/contacts',
	ContactDetails = '/contacts/:contactId',
	Campaigns = '/campaigns',
	DataInsights = '/data-insights',
	Help = 'help',
	Settings = '/settings',
	SegmentBuilder = '/segments/builder',
	SegmentBuilderDetails = '/segments/builder/:segmentId',
	Segments = '/segments',
}
