import React, { FC, useEffect, useState } from 'react';
import styles from './PowerBIEmbedded.module.scss';
import { Embed, models, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import Spinner from 'components/atoms/Custom/Spinner/Spinner';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { BooleanCallback } from '../../../../types/Callbacks';

interface IPowerBIEmbeddedProps {
	reportClass?: string;
	embedUrl: string;
	accessToken: string;
	loading: boolean;
	onRenderedPowerBI: BooleanCallback;
}

const PowerBIEmbedded: FC<IPowerBIEmbeddedProps> = ({
	reportClass,
	embedUrl,
	accessToken,
	loading,
	onRenderedPowerBI,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [embeddedReportConfig, setEmbeddedReportConfig] = useState<models.IReportEmbedConfiguration>({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: {
			navContentPaneEnabled: false,
		},
	});

	const [eventHandlersMap] = useState<
		Map<string, (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null>
	>(
		new Map([
			[
				'loaded',
				() => {
					console.log('Report has loaded');
					onRenderedPowerBI(false);
					setIsLoading(false);
				},
			],
			[
				'rendered',
				() => {
					console.log('Report has rendered');
					setIsLoading(false);
					onRenderedPowerBI(false);
				},
			],
			[
				'error',
				(event?: service.ICustomEvent<any>) => {
					if (event) {
						console.error(event.detail);
					}
				},
			],
			['visualClicked', () => console.log('visual clicked')],
			['pageChanged', (event) => console.log(event)],
		])
	);

	const classNames = useClassNameBuilderFactory()
		.Create()
		.AddConditionalClass(isLoading, styles.embed_container_hide)
		.AddConditionalClass(!isLoading, styles.embed_container_show)
		.GetClassNames();

	useEffect(() => {
		if (embedUrl != null && accessToken != null) {
			setEmbeddedReportConfig({
				...embeddedReportConfig,
				embedUrl: embedUrl,
				accessToken: accessToken,
			});
		}
	}, [embedUrl, accessToken, loading]);

	useEffect(() => {
		if (loading) {
			setIsLoading(loading);
		}
	}, [loading]);

	return (
		<div className={styles.overlay_container}>
			{isLoading && (
				<div className={styles.overlay}>
					<Spinner />
				</div>
			)}

			<div className={classNames}>
				<PowerBIEmbed
					embedConfig={embeddedReportConfig}
					eventHandlers={eventHandlersMap}
					cssClassName={reportClass}
					getEmbeddedComponent={(embedObject: Embed) => {
						console.log(`Embedded object of type "${embedObject.embedtype}" received`);
					}}
				/>
			</div>
		</div>
	);
};

export default PowerBIEmbedded;
