import styles from './MedianValueResults.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { useContactsApiService, useSegmentsApiService } from '../../../../api/providers/AppServiceProvider';
import { ArrayHelper } from '../../../../common/helpers/array-helper';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { useAppSelector } from '../../../../redux/hooks';
import { Container } from '../../../atoms/Base/Container/Container';
import Label from '../../../atoms/Base/Label/Label';
import CXDataLoader from '../../../atoms/Custom/CXDataLoader/CXDataLoader';
import { FaChartSimple } from 'react-icons/fa6';
import { Callback } from 'types/Callbacks';
import MedianValueChartsModal from '../../../organisms/MedianValueChartsModal/MedianValueChartsModal';
import { ReferenceValuesHelper } from '../../../../common/helpers/reference-values-helper';
import { GetMedianValueResult } from '../../../../models/median-values/get-median-value-result';
import { FieldType } from '../../../../common/enums/field-type-enums';

interface IMedianValueResultsProps {
	isNewSegment?: boolean;
	where: string;
}

const MedianValueResults: FC<IMedianValueResultsProps> = ({ isNewSegment, where }) => {
	const contactsApiService = useContactsApiService();
	const segmentsApiService = useSegmentsApiService();

	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);
	const cachedSegmentViewContactCount = useAppSelector((state) => state.segments.contactCount);
	const cachedSelectedMedianColumns = useAppSelector((state) => state.medianValues.selectedMedianColumns);

	const [filteredMedianValues, setFilteredMedianValues] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showMedianValueChartsModal, setShowMedianValueChartsModal] = useState<boolean>(false);
	const [currentMedianColumns, setCurrentMedianColumns] = useState<string[]>([]);
	const [currentWhere, setCurrentWhere] = useState<string>(StringHelper.ValueOrEmpty(where));

	const filterAndSetMedianValues = (medianValueResults: GetMedianValueResult[]) => {
		const filteredMedianValues: number[] = [];
		cachedSelectedMedianColumns.forEach((medianColumn) => {
			filteredMedianValues.push(
				medianValueResults.find((getMedianValueResult) => getMedianValueResult.columnName === medianColumn)
					?.medianValue!
			);
		});
		setFilteredMedianValues(filteredMedianValues);
		setIsLoading(false);
	};

	const loadMedianValues = (contactColumnNames: string[]) => {
		setCurrentWhere(StringHelper.ValueOrDefault(where, currentWhere));

		if (StringHelper.IsNullOrEmpty(where) || !ArrayHelper.HasElements(cachedSelectedMedianColumns)) {
			setFilteredMedianValues([0, 0, 0]);
			return;
		}

		if (
			where === currentWhere &&
			ArrayHelper.HasElements(filteredMedianValues) &&
			ArrayHelper.Equal(cachedSelectedMedianColumns, currentMedianColumns)
		) {
			setIsLoading(false);
			return;
		}

		setIsLoading(true);
		contactsApiService.GetMedianValues({ columnNames: contactColumnNames, where: where }).then((response) => {
			filterAndSetMedianValues(response);
		});
	};

	useEffect(() => {
		const allNumberContactColumnNames = ReferenceValuesHelper.GetColumnsByType(
			cachedContactColumnNames,
			FieldType.Number
		);
		const currentMedianColumns = ArrayHelper.HasElements(cachedSelectedMedianColumns)
			? cachedSelectedMedianColumns
			: allNumberContactColumnNames.slice(0, 3);
		setCurrentMedianColumns(currentMedianColumns);

		loadMedianValues(currentMedianColumns);
	}, [where, cachedSegmentViewContactCount, cachedSelectedMedianColumns]);

	const onCloseClickHandler: Callback = () => {
		setShowMedianValueChartsModal(false);
	};

	return (
		<Container fullWidth center>
			{showMedianValueChartsModal && (
				<MedianValueChartsModal
					medianColumns={ReferenceValuesHelper.GetColumnsByType(cachedContactColumnNames, FieldType.Number)}
					medianValues={filteredMedianValues}
					onCloseClick={onCloseClickHandler}
					whereClause={StringHelper.ValueOrEmpty(where)}
				/>
			)}
			<Container horizontal childSpaceLarge centerFull>
				{(!ArrayHelper.HasElements(filteredMedianValues) || isLoading) && <CXDataLoader simple />}
				{ArrayHelper.HasElements(filteredMedianValues) &&
					!isLoading &&
					filteredMedianValues.map((medianValue, index) => {
						return (
							<Label bold smallFixedWidth key={index}>
								{medianValue === 0 ? '-' : Math.round(medianValue)}
							</Label>
						);
					})}

				{ArrayHelper.HasElements(filteredMedianValues) && !isLoading && (
					<Label bold smallFixedWidth key={'median-value-chart'}>
						<Container center className={styles.median_value_charts_container}>
							<FaChartSimple
								key={'data-insights'}
								size={20}
								onClick={() => setShowMedianValueChartsModal(true)}
							/>
						</Container>
					</Label>
				)}
			</Container>
		</Container>
	);
};

export default MedianValueResults;
