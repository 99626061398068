import React, { FC, useEffect, useState } from 'react';
import { useSegmentsApiService } from '../../../../api/providers/AppServiceProvider';
import { SegmentHelper } from '../../../../common/helpers/segment-helper';
import { useAppSelector } from '../../../../redux/hooks';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { MathHelper } from '../../../../common/helpers/math-helper';
import { RowCount } from '../../../../models/row-count';
import PercentageBar from './PercentageBar/PercentageBar';

interface ISegmentFilterPercentageBarProps {
	where?: string;
	isNewSegment?: boolean;
	isPrimary?: boolean;
	numerator?: number;
	denominator?: number;
}

const SegmentFilterPercentageBar: FC<ISegmentFilterPercentageBarProps> = ({
	where,
	isNewSegment,
	isPrimary,
	numerator = 0,
	denominator = 0,
}) => {
	const cachedTotalContactCount = useAppSelector((state) => state.contacts.totalCount);
	const cachedSegmentViewContactCount = useAppSelector((state) => state.segments.contactCount);

	const segmentsApiService = useSegmentsApiService();

	const [displayEmptyCount, setDisplayEmptyCount] = useState<boolean>(false);
	const [rowCount, setRowCount] = useState<number>(numerator);
	const [totalCount, setTotalCount] = useState<number>(denominator > 0 ? denominator : cachedSegmentViewContactCount);
	const [percentage, setPercentage] = useState<number>(Number(Math.round((rowCount / totalCount) * 100).toFixed(0)));

	const setPercentageBarValues = (rowCountResponse: RowCount) => {
		const rowCount = rowCountResponse.count;
		const totalCount = isPrimary ? cachedTotalContactCount : cachedSegmentViewContactCount;
		if (rowCount === 0) setDisplayEmptyCount(true);

		setRowCount(rowCount);
		setTotalCount(totalCount);

		const percentage = Number(Math.round((rowCount / totalCount) * 100).toFixed(0));
		// Conditional ensures progress bar never exceeds 100% as it causes the bar to overflow in the UI
		if (MathHelper.IsValidNumber(percentage)) setPercentage(percentage <= 100 ? percentage : 0);
		else setPercentage(0);
	};

	const getRowCount = () => {
		if (where?.includes(`${SegmentHelper.ActivityTablePrefix}.`)) {
			segmentsApiService.GetJoinedRowCount({ where: StringHelper.ValueOrEmpty(where) }).then((response) => {
				setPercentageBarValues(response);
			});
		} else if (where?.includes(`${SegmentHelper.ContactTablePrefix}.`)) {
			segmentsApiService.GetContactRowCount({ where: StringHelper.ValueOrEmpty(where) }).then((response) => {
				setPercentageBarValues(response);
			});
		}
	};

	useEffect(() => {
		if (!isNewSegment) {
			getRowCount();
			return;
		}

		setRowCount(cachedSegmentViewContactCount);
		setTotalCount(cachedTotalContactCount);

		if (cachedSegmentViewContactCount === 0) setDisplayEmptyCount(true);

		const percentage = Number(
			Math.round((cachedSegmentViewContactCount / cachedTotalContactCount) * 100).toFixed(0)
		);
		setPercentage(MathHelper.IsValidNumber(percentage) ? percentage : 0);
		return;
	}, [where, cachedSegmentViewContactCount]);

	return (
		<div>
			<PercentageBar
				displayEmptyCount={isNewSegment || displayEmptyCount}
				primary={totalCount === cachedTotalContactCount}
				rowCount={rowCount}
				totalCount={totalCount || cachedSegmentViewContactCount}
				percentage={percentage}
			/>
		</div>
	);
};

export default SegmentFilterPercentageBar;
