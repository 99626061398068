import './RadioButton.scss';
import React, { FC } from 'react';
import { Form } from 'react-bootstrap';

interface IRadioButtonProps {
	checked?: boolean;
	id?: string;
	label?: string;
	onChange?: React.ChangeEventHandler;
}

const RadioButton: FC<IRadioButtonProps> = ({ checked, id, label, onChange }) => {
	return (
		<Form.Check
			required
			type={'radio'}
			id={`${id}`}
			label={label}
			checked={checked}
			onChange={onChange}
			value={'Blue'}
		/>
	);
};

export default RadioButton;
