import styles from './Spinner.module.scss';
import React from 'react';

const Spinner = () => {
	return (
		<div className={styles.logo_size}>
			<img src="/animatedlogo.gif" alt="loading..." />
		</div>
	);
};

export default Spinner;
