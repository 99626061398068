import styles from './DataInsights.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { usePowerBIApiService } from 'api/providers/AppServiceProvider';
import { setPowerBIResponse } from 'features/powerbi-slice';
import PowerBIEmbedded from 'components/molecules/Custom/PowerBI/PowerBIEmbedded';
import { StringHelper } from '../../../common/helpers/string-helper';
import CXDataLoader from '../../atoms/Custom/CXDataLoader/CXDataLoader';
import { Container } from '../../atoms/Base/Container/Container';
import { PowerBIResponse } from '../../../models/powerbi';

interface IDataInsightsProps {
	isLoading?: boolean;
}

const DataInsights: FC<IDataInsightsProps> = () => {
	const dispatch = useAppDispatch();
	const contactsApiService = usePowerBIApiService();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPowerBILoading, setIsPowerBILoading] = useState<boolean>(false);
	const [isRefreshTokenLoading, setIsRefreshTokenLoading] = useState<boolean>(false);
	const [powerBIEmbedded, setPowerBiEmbedded] = useState<any>();
	const [accessToken, setAccessToken] = useState<string>();
	const [embedTokenExpiration, setEmbedTokenExpiration] = useState<string>();

	useEffect(() => {
		contactsApiService.getPowerBIDataInsightsService().then(
			(response: PowerBIResponse) => {
				if (response != null) {
					dispatch(setPowerBIResponse(response));
					setPowerBiEmbedded(response);
					setAccessToken(response.embedToken);
					setEmbedTokenExpiration(response.embedTokenExpiration);
					setIsLoading(false);
					setIsPowerBILoading(true);
					monitorTokenExpiration(response.embedTokenExpiration);
				}
			},
			(error) => {
				console.log(error);
				setIsLoading(false);
			}
		);
	}, []);

	useEffect(() => {
		const intervaldata = setInterval(() => {
			if (embedTokenExpiration != null && embedTokenExpiration != '')
				monitorTokenExpiration(embedTokenExpiration);
		}, 60000);
		return () => clearInterval(intervaldata);
	}, [accessToken, embedTokenExpiration]);

	const onHandleRenderedPowerBI = (isclose: boolean) => {
		setIsPowerBILoading(isclose);
	};

	const monitorTokenExpiration = (tokenExpiration: string): void => {
		var secondsToExpire = Math.floor((new Date(tokenExpiration).getTime() - new Date().getTime()) / 1000);
		var secondsBeforeExpirationForAutoRefresh = 6 * 60;
		if (secondsToExpire < secondsBeforeExpirationForAutoRefresh) {
			setEmbedTokenExpiration(StringHelper.Empty);
			if (!isRefreshTokenLoading) {
				refreshEmbedToken();
			}
		}
	};

	const refreshEmbedToken = async () => {
		setIsRefreshTokenLoading(true);
		contactsApiService.getEmbeddedToken('DataInsights').then(
			(response: PowerBIResponse) => {
				if (response != null) {
					setAccessToken(response.embedToken);
					setEmbedTokenExpiration(response.embedTokenExpiration);
					monitorTokenExpiration(response.embedTokenExpiration);
					setIsRefreshTokenLoading(false);
				}
			},
			(error) => {
				setIsRefreshTokenLoading(false);
				console.log(error);
			}
		);
	};

	return (
		<Container>
			{isLoading && <CXDataLoader />}
			{!isLoading && (
				<div>
					{powerBIEmbedded != null && accessToken != null ? (
						<PowerBIEmbedded
							reportClass={styles.report_class}
							embedUrl={powerBIEmbedded.embeddedUrl}
							accessToken={accessToken}
							loading={isPowerBILoading}
							onRenderedPowerBI={onHandleRenderedPowerBI}
						></PowerBIEmbedded>
					) : (
						<div></div>
					)}
				</div>
			)}
		</Container>
	);
};

export default DataInsights;
