import styles from './CheckboxLabel.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { EventArgsCallback } from '../../../../types/Callbacks';
import { ColumnVisibility } from '../../../../common/models/column-visibility';

interface ICheckboxLabelProps {
	label: string;
	isChecked: boolean;
	onCheckBoxChecked: EventArgsCallback<ColumnVisibility>;
}

const CheckboxLabel: FC<ICheckboxLabelProps> = ({ label, isChecked, onCheckBoxChecked }) => {
	const [checked, setChecked] = useState<boolean>(isChecked);

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	const onCheckBoxClickedHandler = () => {
		const isVisible = !checked;
		onCheckBoxChecked({ columnName: label, isVisible: isVisible });
	};

	return (
		<div className={styles.checkbox_label}>
			<input type="checkbox" checked={checked} onChange={() => {}} />
			<label onClick={onCheckBoxClickedHandler}>{label}</label>
		</div>
	);
};

export default CheckboxLabel;
