import styles from './DateInput.module.scss';
import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface IDateInputProps {
	className?: string;
	dataCy?: string;
	inErrorState?: boolean;
	value?: string;
	onChange: any;
}

const DateInput: FC<IDateInputProps> = ({ className, dataCy, inErrorState, value, onChange }) => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const dateInputClassNames = classNameBuilderFactory
		.Create()
		.AddClass(className)
		.AddClass(styles.date_input)
		.AddConditionalClass(inErrorState, styles.date_input__error)
		.GetClassNames();

	return (
		<Form.Control
			data-cy={dataCy}
			type={'date'}
			className={dateInputClassNames}
			// min={TODAY_DATE}
			placeholder={'Date'}
			aria-label={'Date'}
			onChange={onChange}
			value={value}
		/>
	);
};

export default DateInput;
