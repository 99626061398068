/* eslint-disable no-unused-vars */
import { MedianValueChartList } from '../../models/median-values/median-value-chart-list';
import { GetMedianValueChartsRequest } from '../../models/median-values/requests/get-median-value-charts-request';
import { RowCount } from '../../models/row-count';
import { CreateSegmentResult, UpdateSegmentCommentResponse } from '../../models/segment';
import { CreateSegmentRequest } from '../../models/segments/requests/create-segment-request';
import { DeleteSegmentRequest } from '../../models/segments/requests/delete-segment-request';
import { GetSegmentQueryRowCountRequest } from '../../models/segments/requests/get-segment-query-row-count-request';
import { UpdateSegmentCommentRequest } from '../../models/segments/requests/update-segment-comment-request';
import { SegmentView } from '../../models/segments/segment-view';
import { ApiServiceBase } from './base/api-service-base';

const baseEndpoint = 'segments';

export interface ISegmentsApiService {
	CreateReplaceContactSegmentView(createSegmentRequest: CreateSegmentRequest): Promise<CreateSegmentResult>;
	DeleteSegmentView(deleteSegmentRequest: DeleteSegmentRequest): Promise<void>;
	GetContactRowCount(getRowCountRequest: GetSegmentQueryRowCountRequest): Promise<RowCount>;
	GetJoinedRowCount(getRowCountRequest: GetSegmentQueryRowCountRequest): Promise<RowCount>;
	GetSegmentViews(): Promise<SegmentView[]>;
	GetSegmentViewById(segmentId: string): Promise<SegmentView>;
	GetMedianValueCharts(getMedianValueChartsRequest: GetMedianValueChartsRequest): Promise<MedianValueChartList[]>;
	UpdateSegmentComment(
		updateSegmentCommentRequest: UpdateSegmentCommentRequest
	): Promise<UpdateSegmentCommentResponse>;
}

export class SegmentsApiService extends ApiServiceBase implements ISegmentsApiService {
	public async CreateReplaceContactSegmentView(
		createSegmentRequest: CreateSegmentRequest
	): Promise<CreateSegmentResult> {
		return await this.PostToApi<CreateSegmentResult>(`${baseEndpoint}/contact/create`, createSegmentRequest);
	}

	public async DeleteSegmentView(deleteSegmentRequest: DeleteSegmentRequest): Promise<void> {
		return await this.PostToApi(`${baseEndpoint}/delete`, deleteSegmentRequest);
	}

	public async GetContactRowCount(getSegmentQueryRowCountRequest: GetSegmentQueryRowCountRequest): Promise<RowCount> {
		return await this.PostToApi<RowCount>(`${baseEndpoint}/contacts/row-count`, getSegmentQueryRowCountRequest);
	}

	public async GetJoinedRowCount(getSegmentQueryRowCountRequest: GetSegmentQueryRowCountRequest): Promise<RowCount> {
		return await this.PostToApi<RowCount>(`${baseEndpoint}/joined/row-count`, getSegmentQueryRowCountRequest);
	}

	public async GetSegmentViews(): Promise<SegmentView[]> {
		return await this.GetFromApi<SegmentView[]>(`${baseEndpoint}/views`);
	}

	public async GetSegmentViewById(segmentId: string): Promise<SegmentView> {
		return await this.GetFromApi<SegmentView>(`${baseEndpoint}/views/${segmentId}`);
	}

	public async GetMedianValueCharts(
		getMedianValueChartsRequest: GetMedianValueChartsRequest
	): Promise<MedianValueChartList[]> {
		return await this.PostToApi<MedianValueChartList[]>(
			`${baseEndpoint}/median-value-charts`,
			getMedianValueChartsRequest
		);
	}

	public async UpdateSegmentComment(
		updateSegmentCommentRequest: UpdateSegmentCommentRequest
	): Promise<UpdateSegmentCommentResponse> {
		return await this.PostToApi<UpdateSegmentCommentResponse>(
			`${baseEndpoint}/comment/update`,
			updateSegmentCommentRequest
		);
	}
}
