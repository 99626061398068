import styles from './Label.module.scss';
import React, { FC, ReactNode } from 'react';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { StringHelper } from '../../../../common/helpers/string-helper';

export interface ILabelProps {
	children: ReactNode;
	className?: string;
	bold?: boolean;
	clickable?: boolean;
	disabled?: boolean;
	whiteText?: boolean;
	redText?: boolean;
	secondary?: boolean;
	selected?: boolean;
	simple?: boolean;
	small?: boolean;
	smallFixedWidth?: boolean;
	tooltip?: string;
	underline?: boolean;
	onClick?: any;
}

const Label: FC<ILabelProps> = ({
	children,
	className,
	bold,
	clickable,
	disabled,
	whiteText,
	redText,
	secondary,
	selected,
	simple,
	small,
	smallFixedWidth,
	tooltip,
	underline,
	onClick,
}) => {
	const classNames = useClassNameBuilderFactory()
		.Create()
		.AddClass(className)
		.AddClass(styles.label)
		.AddConditionalClass(bold, styles.bold)
		.AddConditionalClass(clickable, styles.clickable)
		.AddConditionalClass(disabled, styles.disabled)
		.AddConditionalClass(secondary, styles.secondary)
		.AddConditionalClass(redText, styles.red_text)
		.AddConditionalClass(whiteText, styles.white_text)
		.AddConditionalClass(simple, styles.simple)
		.AddConditionalClass(selected, styles.selected)
		.AddConditionalClass(small, styles.small)
		.AddConditionalClass(smallFixedWidth, styles.small_fixed_width)
		.AddConditionalClass(underline, styles.underline)
		.GetClassNames();

	const defaultLabel = () => {
		const defaultLabel = (
			<label className={classNames} onClick={onClick}>
				{children}
			</label>
		);

		return StringHelper.IsNotNullOrEmpty(tooltip) ? (
			<Tooltip
				TransitionComponent={Zoom}
				title={tooltip || StringHelper.Empty}
				placement={'top'}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: '#999999',
						},
					},
				}}
			>
				{defaultLabel}
			</Tooltip>
		) : (
			defaultLabel
		);
	};

	return <>{defaultLabel()}</>;
};

export default Label;
