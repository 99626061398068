import { StringHelper } from './string-helper';

export class MathHelper {
	public static Median(numArr: number[]) {
		const mid = Math.floor(numArr.length / 2);
		const sortedArr = numArr.sort((a, b) => a - b);

		if (numArr.length % 2 === 0) {
			return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
		} else {
			return sortedArr[mid];
		}
	}

	public static IsValidNumber(num: any) {
		if (StringHelper.IsNullOrEmpty(num)) return false;

		const convertedValue = Number(num);

		return !isNaN(convertedValue);
	}
}
