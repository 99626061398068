import React, { FC } from 'react';
import { Modal } from '../../molecules/Base/Modal/Modal';
import { Container } from '../../atoms/Base/Container/Container';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { Callback } from '../../../types/Callbacks';
import Label from '../../atoms/Base/Label/Label';

interface IHelpModalProps {
	onCloseClick?: Callback;
}

const HelpModal: FC<IHelpModalProps> = ({ onCloseClick }) => {
	return (
		<Modal visible small onClose={onCloseClick}>
			<Container left topSpace bottomSpace>
				<Label>
					Please email <strong>aj@cxdata.ai</strong> with any queries
				</Label>
			</Container>
			<Container topSpace right>
				<ActionButton secondary onClick={onCloseClick}>
					Close
				</ActionButton>
			</Container>
		</Modal>
	);
};

export default HelpModal;
