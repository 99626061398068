import React, { FC, ReactNode, useContext } from 'react';
import { ApiServiceFactory } from '../../api/factories/api-service-factory';
import { IApiService } from '../../api/interfaces/api-service';
import { IClassNameBuilder } from '../builders/class-name-builder';
import { IQueryBuilder } from '../builders/query-builder';
import { ClassNameBuilderFactory } from '../factories/class-name-builder-factory';
import { QueryBuilderFactory } from '../factories/query-builder-factory';
import { IServiceFactory } from '../types/service-factory';

interface ReactKitServiceContainer {
	getClassNameBuilderFactory(): IServiceFactory<IClassNameBuilder>;
	getQueryBuilderFactory(): IServiceFactory<IQueryBuilder>;
}

class ReactKitServiceContainer implements ReactKitServiceContainer {
	private apiServiceFactory: IServiceFactory<IApiService>;
	private classNameBuilderFactory: IServiceFactory<IClassNameBuilder>;
	private queryBuilderFactory: IServiceFactory<IQueryBuilder>;

	constructor() {
		this.apiServiceFactory = new ApiServiceFactory();
		this.classNameBuilderFactory = new ClassNameBuilderFactory();
		this.queryBuilderFactory = new QueryBuilderFactory();
	}

	public getApiServiceFactory(): IServiceFactory<IApiService> {
		return this.apiServiceFactory;
	}

	public getClassNameBuilderFactory(): IServiceFactory<IClassNameBuilder> {
		return this.classNameBuilderFactory;
	}

	public getQueryBuilderFactory(): IServiceFactory<IQueryBuilder> {
		return this.queryBuilderFactory;
	}
}

const ReactKitContext: React.Context<ReactKitServiceContainer> = React.createContext<ReactKitServiceContainer>(new ReactKitServiceContainer());

interface IReactKitProviderProps {
	children?: ReactNode;
}

export const ReactKit: FC<IReactKitProviderProps> = ({ children }) => {
	return <ReactKitContext.Provider value={useContext(ReactKitContext)}>{children}</ReactKitContext.Provider>;
};

export const useApiServiceFactory = (): IServiceFactory<IApiService> => {
	return useContext(ReactKitContext).getApiServiceFactory();
};

export const useClassNameBuilderFactory = (): IServiceFactory<IClassNameBuilder> => {
	return useContext(ReactKitContext).getClassNameBuilderFactory();
};

export const useQueryBuilderFactory = (): IServiceFactory<IQueryBuilder> => {
	return useContext(ReactKitContext).getQueryBuilderFactory();
};
