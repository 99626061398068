import React, { FC } from 'react';
import { Callback } from '../../../types/Callbacks';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { Container } from '../../atoms/Base/Container/Container';
import Label from '../../atoms/Base/Label/Label';
import { Modal } from '../../molecules/Base/Modal/Modal';

interface IConfirmDeleteModalProps {
	isLoading?: boolean;
	onConfirmDeleteClick?: Callback;
	onCloseClick?: Callback;
}

const ConfirmDeleteModal: FC<IConfirmDeleteModalProps> = ({ isLoading, onConfirmDeleteClick, onCloseClick }) => {
	return (
		<Modal small visible onClose={onCloseClick}>
			<Container>
				<Label>Are you sure you want to delete?</Label>
			</Container>
			<Container right>
				<ActionButton isLoading={isLoading} secondary onClick={onConfirmDeleteClick}>
					Confirm
				</ActionButton>
			</Container>
		</Modal>
	);
};

export default ConfirmDeleteModal;
