import React, { FC } from 'react';
import Loader from '../../Base/Loader/Loader';

interface CXDataLoaderProps {
	message?: string;
	simple?: boolean;
}

const CXDataLoader: FC<CXDataLoaderProps> = ({ message, simple = false }) => {
	return <Loader animation={'grow'} count={3} childSpace size={'sm'} simple={simple} message={message} />;
};

export default CXDataLoader;
