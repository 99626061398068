import styles from './TableHead.module.scss';
import React, { FC } from 'react';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { StringValueCallback } from '../../../../types/Callbacks';

interface ITableHeadProps {
	headings: string[];
	headingOnly?: boolean;
	sortHeading?: string;
	onClickHeading?: StringValueCallback;
}

const TableHead: FC<ITableHeadProps> = ({ headings, headingOnly = false, sortHeading, onClickHeading }) => {
	const onClickHeadingHandler = (heading: string) => {
		if (onClickHeading) onClickHeading(heading);
	};

	return (
		<thead className={styles.table_head}>
			<tr className={headingOnly ? styles.heading_only : styles.default_heading}>
				{headings.map((heading) => {
					return (
						<th
							className={`${headingOnly ? styles.heading_only : styles.default_heading}${
								sortHeading === heading ? ` ${styles.default_heading__sort}` : StringHelper.Empty
							}`}
							key={heading.replace(StringHelper.WhiteSpace, '-')}
							onClick={() => onClickHeadingHandler(heading)}
						>
							{heading}
						</th>
					);
				})}
			</tr>
		</thead>
	);
};

export default TableHead;
