import styles from './ContactDetails.module.scss';
import React from 'react';
import { ImArrowLeft } from 'react-icons/im';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryRoutes } from '../../../common/enums/internal-route-enums';
import Separator from '../../atoms/Base/Separator/Seperator';
import Label from '../../atoms/Base/Label/Label';
import { ContactField } from '../../../common/enums/contact-enums';

const ContactDetails = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { contact } = state;

	return (
		<div className={styles.contact_details}>
			<div className={styles.contact_details_header_container} onClick={() => navigate(PrimaryRoutes.Contacts)}>
				<ImArrowLeft />
				<Label>Back to Contacts</Label>
			</div>
			<div className={styles.contact_details_container}>
				<div className={styles.details_container_one}>
					<h3>{contact.contactName}</h3>
					<Label>{contact.location}</Label>
					<Separator />
					<ul>
						<li>
							<span>{ContactField.UniqueContactKey}</span>
							<span>{contact.contactId}</span>
						</li>
						<li>
							<span>Title</span>
							<span>{contact.title}</span>
						</li>
						<li>
							<span>{ContactField.FirstName}</span>
							<span>{contact.firstName}</span>
						</li>
						<li>
							<span>Last Name</span>
							<span>{contact.lastName}</span>
						</li>
						<li>
							<span>{ContactField.Email}</span>
							<span>{contact.email}</span>
						</li>
						<li>
							<span>{ContactField.PhoneNumber}</span>
							<span>{contact.phoneNumber}</span>
						</li>
						<li>
							<span>{ContactField.MobileNumber}</span>
							<span>{contact.mobileNumber}</span>
						</li>
					</ul>
					<Separator />
				</div>
				<div className={styles.details_container_two}></div>
				<div className={styles.details_container_three}></div>
			</div>
		</div>
	);
};

export default ContactDetails;
