import { StringHelper } from './string-helper';

export class ArrayHelper {
	public static Equal(arrayOne: any[], arrayTwo: any[]): boolean {
		if (!this.HasElements(arrayOne) || !this.HasElements(arrayTwo)) return false;

		if ([...arrayOne].sort().join(',') === [...arrayTwo].sort().join(',')) {
			return true;
		}
		return false;
	}

	public static HasElements(dataSource?: any[]): boolean {
		if (!dataSource) return false;
		return dataSource && dataSource.length > 0;
	}

	public static EmptyArray(size: number): string[] {
		const arr = [];
		for (let i = 0; i < size; i++) {
			arr.push(StringHelper.Empty);
		}
		return arr;
	}

	public static Single(dataSource?: any[]): boolean {
		if (!dataSource) return false;
		return dataSource && dataSource.length === 1;
	}

	public static SingleWithValue(dataSource?: any[], value?: any): boolean {
		if (!dataSource) return false;
		return dataSource && dataSource.length === 1 && dataSource[0] === value;
	}

	public static ValueOrDefault<T>(dataSource: T[] | null | undefined, defaultValue: T[] = []): T[] {
		if (dataSource != null && dataSource !== undefined && this.HasElements(dataSource)) return dataSource;

		return defaultValue;
	}

	public static ValueOrEmpty<T>(dataSource?: T[]): T[] {
		if (!dataSource) return [];
		else return dataSource;
	}
}
