import styles from './SegmentsTableTemplate.module.scss';
import React, { FC, useEffect, useState } from 'react';
import Table from '../../atoms/Base/Table/Table';
import TableHead from '../../molecules/Base/TableHead/TableHead';
import TableBody from '../../molecules/Base/TableBody/TableBody';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { PrimaryRoutes } from '../../../common/enums/internal-route-enums';
import { useNavigate } from 'react-router-dom';
import { SegmentView } from '../../../models/segments/segment-view';
import { Container } from '../../atoms/Base/Container/Container';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { ObjectHelper } from '../../../common/helpers/object-helper';
import { StringHelper } from '../../../common/helpers/string-helper';
import { SegmentType, SegmentViewField } from '../../../common/enums/segment-enums';
import { FieldType } from '../../../common/enums/field-type-enums';
import Label from '../../atoms/Base/Label/Label';
import { useSegmentsApiService } from '../../../api/providers/AppServiceProvider';
import ConfirmDeleteModal from '../../organisms/ConfirmDeleteModal/ConfirmDeleteModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setCachedRefreshState } from '../../../features/segments-slice';

interface ISegmentsTableTemplateProps {
	segmentViews: SegmentView[];
	searchTerm: string;
}

const SegmentsTableTemplate: FC<ISegmentsTableTemplateProps> = ({ segmentViews, searchTerm }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const segmentsApiService = useSegmentsApiService();
	const cachedRefreshState = useAppSelector((state) => state.segments.refresh);

	const [allSegmentViews, setAllSegmentViews] = useState<SegmentView[]>(segmentViews);
	const [sortedSegmentViews, setSortedSegmentViews] = useState<SegmentView[]>(segmentViews);
	const [contactCountToggle, setContactCountToggle] = useState<boolean>(false);
	const [createdDateToggle, setCreatedDateToggle] = useState<boolean>(false);
	const [segmentIdToggle, setSegmentIdToggle] = useState<boolean>(false);
	const [segmentNameToggle, setSegmentNameToggle] = useState<boolean>(false);
	const [segmentTypeToggle, setSegmentTypeToggle] = useState<boolean>(false);
	const [sortHeading, setSortHeading] = useState<string>(StringHelper.Empty);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
	const [segmentIdToDelete, setSegmentIdToDelete] = useState<string>(StringHelper.Empty);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	const getFormattedPercentage = (label: string, percentage: string) => {
		if (StringHelper.IsNotNullOrEmpty(percentage) && Number(percentage) > 0) {
			return `${label}: ${percentage}%, `;
		}
		return StringHelper.Empty;
	};

	const formatTestVariantPercentages = (segmentView: SegmentView) => {
		let formattedTestVariantString = StringHelper.Empty;
		if (segmentView.testVariantPercentages) {
			const percentages = segmentView.testVariantPercentages;
			formattedTestVariantString = `${getFormattedPercentage('A', percentages[0])}${getFormattedPercentage(
				'B',
				percentages[1]
			)}${getFormattedPercentage('C', percentages[2])}${getFormattedPercentage('D', percentages[3])}`.trim();
			formattedTestVariantString = formattedTestVariantString.substring(0, formattedTestVariantString.length - 1);
		}
		return formattedTestVariantString;
	};

	const applySort = (toggleState: boolean, heading: string, columnType: FieldType = FieldType.String) => {
		const segmentViewsToSort = ObjectHelper.DeepCopy(allSegmentViews);
		const fieldName = StringHelper.ToCamelCase(heading);

		switch (columnType) {
			case FieldType.Number:
				{
					setSortedSegmentViews(
						toggleState
							? segmentViewsToSort!.sort((a, b) => Number(a[fieldName]) - Number(b[fieldName]))
							: segmentViewsToSort!.sort((a, b) => Number(b[fieldName]) - Number(a[fieldName]))
					);
				}
				break;
			case FieldType.String:
				{
					setSortedSegmentViews(
						toggleState
							? segmentViewsToSort!.sort((a, b) => a[fieldName].localeCompare(b[fieldName]))
							: segmentViewsToSort!.sort((a, b) => b[fieldName].localeCompare(a[fieldName]))
					);
				}
				break;
		}
	};

	const onClickHeadingHandler = (heading: string) => {
		setSortHeading(heading);
		switch (heading) {
			case SegmentViewField.Contacts:
				{
					setContactCountToggle(!contactCountToggle);
					applySort(contactCountToggle, 'contactCount', FieldType.Number);
				}
				break;
			case SegmentViewField.CreatedDate:
				{
					setCreatedDateToggle(!createdDateToggle);
					applySort(createdDateToggle, 'createdOn', FieldType.Number);
				}
				break;
			case SegmentViewField.SegmentId:
				{
					setSegmentIdToggle(!segmentIdToggle);
					applySort(segmentIdToggle, heading);
				}
				break;
			case SegmentViewField.SegmentName:
				{
					setSegmentNameToggle(!segmentNameToggle);
					applySort(segmentNameToggle, heading);
				}
				break;
			case SegmentViewField.SegmentType:
				{
					setSegmentTypeToggle(!segmentTypeToggle);
					applySort(segmentTypeToggle, heading);
				}
				break;
		}
	};

	const onShowDeleteConfirmationHandler = (segmentId: string) => {
		setShowDeleteConfirmation(true);
		setSegmentIdToDelete(segmentId);
	};

	const onDeleteSegmentViewHandler = () => {
		setIsDeleting(true);
		segmentsApiService.DeleteSegmentView({ segmentId: segmentIdToDelete }).then(() => {
			dispatch(setCachedRefreshState(!cachedRefreshState));
			setShowDeleteConfirmation(false);
			setIsDeleting(false);
		});
	};

	useEffect(() => {
		if (searchTerm && searchTerm.length > 0 && ArrayHelper.HasElements(segmentViews)) {
			setSortedSegmentViews(ObjectHelper.SearchInDataSource(segmentViews, searchTerm));
		} else {
			setAllSegmentViews(segmentViews);
			setSortedSegmentViews(
				segmentViews.slice().sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
			);
		}
	}, [segmentViews, searchTerm]);

	return (
		<Container className={styles.segments_table_container}>
			{showDeleteConfirmation && (
				<ConfirmDeleteModal
					isLoading={isDeleting}
					onCloseClick={() => setShowDeleteConfirmation(false)}
					onConfirmDeleteClick={onDeleteSegmentViewHandler}
				/>
			)}
			{!ArrayHelper.HasElements(segmentViews) && <Label bold>Please start by creating a new Segment</Label>}
			{ArrayHelper.HasElements(segmentViews) && (
				<Table>
					<TableHead
						headings={[
							'Segment ID',
							'Segment Name',
							'Segment Type',
							'AB Test',
							'Created Date',
							'Contacts',
							'Action',
						]}
						sortHeading={sortHeading}
						onClickHeading={onClickHeadingHandler}
					/>
					<TableBody bodyOnly={true} rowCount={sortedSegmentViews.length}>
						{sortedSegmentViews.map((segmentView) => {
							return (
								<tr key={segmentView.segmentId}>
									<td>{segmentView.segmentId}</td>
									<td>{segmentView.segmentName}</td>
									<td>{StringHelper.ValueOrDefault(segmentView.segmentType, SegmentType.Dynamic)}</td>
									<td>{formatTestVariantPercentages(segmentView)}</td>
									<td>{`${new Date(segmentView.createdOn).toLocaleDateString('en-UK')} ${new Date(
										segmentView.createdOn
									).toLocaleTimeString('en-UK')}`}</td>
									<td>{segmentView.contactCount}</td>
									<td>
										<Container horizontal fullWidth childSpace>
											<ActionButton
												onClick={() =>
													navigate(
														PrimaryRoutes.SegmentBuilderDetails.replace(
															':segmentId',
															segmentView.segmentId
														),
														{
															state: segmentView,
														}
													)
												}
												secondary
											>
												View
											</ActionButton>
											<ActionButton
												isDelete
												onClick={() => onShowDeleteConfirmationHandler(segmentView.segmentId)}
											>
												Delete
											</ActionButton>
										</Container>
									</td>
								</tr>
							);
						})}
					</TableBody>
				</Table>
			)}
		</Container>
	);
};

export default SegmentsTableTemplate;
