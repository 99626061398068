import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReferenceValueColumnName } from '../models/reference-value';

// Define a type for the slice state
interface ActivityState {
	columnNames: ReferenceValueColumnName[];
}

// Define the initial state using that type
const initialState: ActivityState = {
	columnNames: [],
};

export const activitySlice = createSlice({
	name: 'activity',
	initialState,
	reducers: {
		setCachedActivityColumnNames: (state, action: PayloadAction<ReferenceValueColumnName[]>) => {
			state.columnNames = action.payload;
		},
	},
});

export const { setCachedActivityColumnNames } = activitySlice.actions;

export default activitySlice.reducer;
