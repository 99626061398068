import styles from './PageLayout.module.scss';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Container } from '../components/atoms/Base/Container/Container';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Logo from '../components/atoms/Base/Logo/Logo';
import Home from '../components/pages/Home/Home';
import { SignInButton } from '../components/atoms/Custom/SignInButton/SignInButton';
import NavigationMenu from '../components/atoms/Base/NavigationMenu/NavigationMenu';
import AuthenticatedContainer from '../components/molecules/AuthenticatedContainer/AuthenticatedContainer';
import { StringHelper } from '../common/helpers/string-helper';
import CollapseButton from '../components/atoms/Custom/CollapseButton/CollapseButton';
import { useClassNameBuilderFactory } from '../common/providers/ReactKitProvider';

interface IPageLayoutProps {
	children?: ReactNode;
	isLoading: boolean;
}

const PageLayout: FC<IPageLayoutProps> = ({ children, isLoading }) => {
	const { accounts } = useMsal();

	const classNamesForLogoContainer = useClassNameBuilderFactory()
		.Create()
		.AddClass(styles.logo_container)
		.AddConditionalClass(accounts.length === 0, styles.not_signed_in)
		.GetClassNames();

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [leftContainerClassNames, setLeftContainerClassNames] = useState<string>(StringHelper.Empty);

	const onToggleMenuHandler = () => {
		setIsCollapsed(!isCollapsed);
	};

	useEffect(() => {
		if (StringHelper.IsNullOrEmpty(leftContainerClassNames)) {
			setLeftContainerClassNames(styles.left_container);
			return;
		}

		setLeftContainerClassNames(
			isCollapsed
				? `${leftContainerClassNames} ${styles.collapsed}`
				: leftContainerClassNames.split(StringHelper.WhiteSpace)[0]
		);
	}, [isCollapsed]);

	return (
		<Container fullWidth horizontal className={styles.page_layout}>
			<UnauthenticatedTemplate>
				<Container className={leftContainerClassNames}>
					<Container className={classNamesForLogoContainer} horizontal centerFull bottomSpace>
						<Logo />
					</Container>
					<Container className={styles.authentication} centerFull>
						<SignInButton isLoading={isLoading} />
					</Container>
				</Container>
				<Container className={styles.right_container}>
					<Home isLoading={isLoading} />
				</Container>
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<Container className={leftContainerClassNames}>
					<Container>
						<Container className={classNamesForLogoContainer} horizontal centerFull bottomSpace>
							<Container>
								<Logo />
							</Container>
							<Container>
								<CollapseButton isCollapsed={isCollapsed} onToggle={onToggleMenuHandler} />
							</Container>
						</Container>
						<Container className={styles.navigation}>
							<NavigationMenu />
						</Container>
						<Container className={styles.authentication} centerFull>
							<AuthenticatedContainer isLoading={isLoading} />
						</Container>
					</Container>
				</Container>
				<Container className={styles.right_container} padding fullWidth>
					{children}
				</Container>
			</AuthenticatedTemplate>
		</Container>
	);
};

export default PageLayout;
