import React, { FC } from 'react';
import { SegmentRecordType } from '../../../../common/enums/segment-enums';
import { ColumnFilterItem } from '../../../../common/models/column-filter';
import { Modal } from '../../Base/Modal/Modal';
import { Container } from '../../../atoms/Base/Container/Container';
import Label from '../../../atoms/Base/Label/Label';

interface ISegmentFilterRecordTypeModalProps {
	columnFilterItem: ColumnFilterItem;
	onCloseClick?: any;
	onClickRecordType: (filterItemId: string, recordType: SegmentRecordType) => void;
}

const SegmentFilterRecordTypeModal: FC<ISegmentFilterRecordTypeModalProps> = ({
	columnFilterItem,
	onCloseClick,
	onClickRecordType,
}) => {
	return (
		<Modal visible small onClose={onCloseClick}>
			<Container childSpace>
				<Label
					secondary
					selected={columnFilterItem.recordType === SegmentRecordType.Contact}
					onClick={() => onClickRecordType(columnFilterItem.id, SegmentRecordType.Contact)}
				>
					{SegmentRecordType.Contact}
				</Label>
				<Label
					secondary
					selected={columnFilterItem.recordType === SegmentRecordType.Activity}
					onClick={() => onClickRecordType(columnFilterItem.id, SegmentRecordType.Activity)}
				>
					{SegmentRecordType.Activity}
				</Label>
			</Container>
		</Modal>
	);
};

export default SegmentFilterRecordTypeModal;
