import styles from './CollapseButton.module.scss';
import React, { FC } from 'react';
import ActionButton from '../../Base/ActionButton/ActionButton';
import { Container } from '../../Base/Container/Container';
import { LuArrowLeftFromLine, LuArrowRightFromLine, LuArrowDownFromLine } from 'react-icons/lu';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface ICollapseButtonProps {
	isCollapsed: boolean;
	vertical?: boolean;
	onToggle: any;
}

const CollapseButton: FC<ICollapseButtonProps> = ({ isCollapsed, vertical, onToggle }) => {
	const classNameBuilderFactory = useClassNameBuilderFactory();
	const buttonClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.collapse_button)
		.AddConditionalClass(isCollapsed, styles.collapsed)
		.GetClassNames();

	const classNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.collapse_button_container)
		.AddConditionalClass(vertical, styles.vertical)
		.GetClassNames();

	return (
		<Container className={classNames}>
			<ActionButton className={buttonClassNames} onClick={onToggle}>
				{!vertical && (
					<Container>
						{!isCollapsed ? <LuArrowLeftFromLine size={12} /> : <LuArrowRightFromLine size={12} />}
					</Container>
				)}
				{vertical && <Container>{!isCollapsed ? <LuArrowDownFromLine size={12} /> : <></>}</Container>}
			</ActionButton>
		</Container>
	);
};

export default CollapseButton;
