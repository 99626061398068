/* eslint-disable no-unused-vars */
import { StringHelper } from '../helpers/string-helper';

export interface IClassNameBuilder {
	AddClass(className?: string): IClassNameBuilder;
	AddConditionalClass(condition: any, className: string | undefined): IClassNameBuilder;
	GetClassNames(): string;
}

export class ClassNameBuilder implements IClassNameBuilder {
	private classNames: string[] = [];

	public AddClass(className?: string): IClassNameBuilder {
		if (className) this.classNames.push(className);

		return this;
	}

	public AddConditionalClass(condition: any, className: string | undefined): IClassNameBuilder {
		if (condition && className) this.AddClass(className);

		return this;
	}

	public GetClassNames(): string {
		return this.classNames.join(StringHelper.WhiteSpace);
	}
}
