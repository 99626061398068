import { ColumnFilterItem, ColumnFilterOptions } from 'common/models/column-filter';
import {
	SegmentDateFilterOption,
	SegmentStringFilterOption,
	SegmentNumberFilterOption,
	SegmentRecordType,
	GenericFilterOption,
	FilterType,
	SegmentType,
} from '../enums/segment-enums';
import { ArrayHelper } from './array-helper';
import { StringHelper } from './string-helper';
import { IServiceFactory } from '../types/service-factory';
import { IQueryBuilder } from '../builders/query-builder';
import { ModifiedFilter } from '../models/filter';
import { MathHelper } from './math-helper';
import { FieldType } from '../enums/field-type-enums';
import { SegmentView } from '../../models/segments/segment-view';

export class SegmentHelper {
	public static get ActivityTablePrefix(): string {
		return 'act';
	}

	public static get ContactTablePrefix(): string {
		return 'con';
	}

	public static get DateSuffix(): string {
		return '::DATE';
	}

	public static get EmptyOrGroup(): string {
		return '(con.)';
	}

	public static ConstructFilterValue(
		columnName: string,
		currentFilterValue: string,
		selectedFilterOption: string,
		selections: string[]
	) {
		let filterValue = currentFilterValue;
		switch (selectedFilterOption) {
			case SegmentStringFilterOption.StartsWith:
				return `${SegmentStringFilterOption.StartsWith}(${columnName}, '${filterValue}')`;
			case SegmentStringFilterOption.EndsWith:
				return `${SegmentStringFilterOption.EndsWith}(${columnName}, '${filterValue}')`;
		}
		return selections.length > 0
			? `(${selections
					.map((selection) => {
						const apostropheIndex = selection.indexOf(StringHelper.SingleQuote);
						const sanitisedSelection =
							apostropheIndex !== -1
								? `${selection.replaceAll(
										StringHelper.SingleQuote,
										StringHelper.Empty
								  )}[${apostropheIndex}]`
								: selection;
						return selectedFilterOption === SegmentStringFilterOption.ContainsExactly ||
							selectedFilterOption === SegmentStringFilterOption.DoesNotContainExactly
							? `'%${sanitisedSelection}%'`
							: `'${sanitisedSelection}'`;
					})
					.join(', ')})`
			: StringHelper.Empty;
	}

	public static ConstructQueryFromFilterItems(
		columnFilterItems: ColumnFilterItem[],
		queryBuilderFactory: IServiceFactory<IQueryBuilder>
	): string {
		const queryBuilderFactoryItem = queryBuilderFactory.Create();

		columnFilterItems.forEach((filterItem, index) => {
			if (
				filterItem.columnOperator === SegmentStringFilterOption.StartsWith ||
				filterItem.columnOperator === SegmentStringFilterOption.EndsWith
			) {
				queryBuilderFactoryItem.addQueryPart(
					`${filterItem.columnOperator}(${filterItem.tablePrefix}.${filterItem.columnName}, '${filterItem.columnValueString}')`
				);
			} else if (filterItem.columnOperator === SegmentStringFilterOption.DoesNotContainExactly) {
				queryBuilderFactoryItem
					.addNot()
					.addQueryPart(`${filterItem.tablePrefix}.${filterItem.columnName}`)
					.addQueryPart(SegmentStringFilterOption.ContainsExactly)
					.addQueryPart(filterItem.columnValueString);
			} else if (
				filterItem.columnOperator === SegmentDateFilterOption.Between ||
				filterItem.columnOperator === SegmentNumberFilterOption.Between
			) {
				queryBuilderFactoryItem
					.addQueryPart(`${filterItem.tablePrefix}.${filterItem.columnName}`)
					.addQueryPart(SegmentDateFilterOption.Between)
					.addQueryPart(filterItem.columnValues![0], filterItem.columnType);

				if (filterItem.columnValues?.length === 2) {
					queryBuilderFactoryItem.addAnd().addQueryPart(filterItem.columnValues[1], filterItem.columnType);
				}
			} else {
				queryBuilderFactoryItem
					.addQueryPart(`${filterItem.tablePrefix}.${filterItem.columnName}`)
					.addQueryPart(filterItem.columnOperator);
				if (
					filterItem.columnOperator === SegmentStringFilterOption.AnyOf ||
					filterItem.columnOperator === SegmentStringFilterOption.NoneOf
				) {
					queryBuilderFactoryItem.addQueryPart(
						'(' +
							filterItem.columnValues
								?.map((columnValue) => `'${this.AddApostrophe(columnValue)}'`)
								.join(', ') +
							')'
					);
				} else if (SegmentHelper.IsNotKnownOrUnknownOperator(filterItem.columnOperator!)) {
					queryBuilderFactoryItem.addQueryPart(filterItem.columnValueString, filterItem.columnType);
				}
			}

			if (index < columnFilterItems.length - 1) {
				queryBuilderFactoryItem.addAnd();
			}
		});

		return queryBuilderFactoryItem.getQuery();
	}

	public static AddApostrophe(source: string, singleQuoteOnly: boolean = false) {
		const startIndex = source.indexOf('[');
		const endIndex = source.indexOf(']');
		const singleQuoteString = singleQuoteOnly ? StringHelper.SingleQuote : "''";
		if (startIndex > 0 && endIndex > 0) {
			const indexOfSingleQuote = Number(source.substring(startIndex + 1, endIndex));
			var sanitisedSource = source.substring(0, source.indexOf('['));
			return `${sanitisedSource.slice(0, indexOfSingleQuote)}${singleQuoteString}${sanitisedSource.slice(
				indexOfSingleQuote,
				sanitisedSource.length
			)}`;
		}
		return source;
	}

	public static ConstructSegmentName = (
		currentSegmentView?: SegmentView,
		modifiedFilter?: ModifiedFilter
	): string => {
		if (!currentSegmentView) return StringHelper.Empty;

		let constructedSegmentName = `${StringHelper.ValueOrDefault(
			modifiedFilter?.segmentName,
			currentSegmentView.segmentName
		)}${
			ArrayHelper.HasElements(currentSegmentView.testVariantPercentages)
				? `|${currentSegmentView.testVariantPercentages!.join('|')}`
				: `|0|0|0|0`
		}`;
		constructedSegmentName = StringHelper.IsNotNullOrEmpty(currentSegmentView.segmentType)
			? `${constructedSegmentName}|${currentSegmentView.segmentType}`
			: `${constructedSegmentName}|${SegmentType.Dynamic}`;
		return `${constructedSegmentName}|`;
	};

	public static ConvertToFilterItem(filter: string, index: number): ColumnFilterItem {
		const knownUnknownRegEx = /^(.*)(act|con).([a-zA-Z_]*)\s*(IS NOT NULL|IS NULL)/;
		const operatorFirstRegEx = /(STARTSWITH|ENDSWITH).*\((act|con).(.*),.*'(.*)'/;
		const operatorAfterRegEx =
			/^(.*)(act|con).([a-zA-Z_]*)\s*([<=>A-Z!=\bILIKE ANY\b\bNOT ILIKE ANY\b]*)[\s*](['%,A-Za-z_=\s\d\&\-\(\)]*)(.*)/;

		if (
			filter.startsWith(SegmentStringFilterOption.StartsWith) ||
			filter.startsWith(SegmentStringFilterOption.EndsWith)
		) {
			let match = operatorFirstRegEx.exec(filter.trim())?.map((match) => match.trim()) || [];
			return {
				...this.GetDefaultColumnFilterItem(match, index),
				columnOperator: match[1],
				columnValueString: match[4],
				columnValues: [match[4]],
				columnType: SegmentHelper.DetermineColumnType([match[4]], match[1]),
				recordType:
					match[2] === SegmentHelper.ContactTablePrefix
						? SegmentRecordType.Contact
						: SegmentRecordType.Activity,
			};
		}

		let match = knownUnknownRegEx.exec(filter.trim())?.map((match) => match.trim()) || [];
		if (
			ArrayHelper.HasElements(match) &&
			(match[4].startsWith(GenericFilterOption.Known) || match[4].startsWith(GenericFilterOption.Unknown))
		) {
			return {
				...this.GetDefaultColumnFilterItem(match, index),
				columnOperator: match[4],
				columnValues: [],
				recordType:
					match[2] === SegmentHelper.ContactTablePrefix
						? SegmentRecordType.Contact
						: SegmentRecordType.Activity,
			};
		}

		match = operatorAfterRegEx.exec(filter.trim())?.map((match) => match.trim()) || [];
		const columnOperator = `${match[1] === 'NOT' ? `${match[1]} ` : StringHelper.Empty}${match[4]}`;
		return {
			...this.GetDefaultColumnFilterItem(match, index),
			columnOperator: columnOperator,
			columnValueString: match[5],
			columnValues:
				SegmentHelper.ExtractColumnValues(
					`${match[5]}${StringHelper.IsNotNullOrEmpty(match[6]) ? match[6] : StringHelper.Empty}`
				) || [],
			columnType: SegmentHelper.DetermineColumnType([match[5], match[6]], columnOperator),
			recordType:
				match[2] === SegmentHelper.ContactTablePrefix ? SegmentRecordType.Contact : SegmentRecordType.Activity,
		};
	}

	public static ConvertToFilterItems(orGroup: string): ColumnFilterItem[] {
		if (orGroup === SegmentHelper.EmptyOrGroup) {
			return [
				{
					id: '0',
					tablePrefix: SegmentHelper.ContactTablePrefix,
					recordType: SegmentRecordType.Contact,
				},
			];
		}

		const orGroupParts = orGroup.split(` ${FilterType.And} `).map((orGroupPart) => orGroupPart.trim());

		const sanitisedFilters = [];
		for (let i = 0; i < orGroupParts.length; i++) {
			if (orGroupParts[i].includes('BETWEEN') && !orGroupParts[i].includes(FilterType.And)) {
				sanitisedFilters.push(`${orGroupParts[i]} ${FilterType.And} ${orGroupParts[i + 1]}`);
				i++;
			} else {
				sanitisedFilters.push(orGroupParts[i]);
			}
		}

		const columnFilterItems = sanitisedFilters.map((filter, index) => {
			let sanitisedFilter = filter;
			if (
				filter.includes(SegmentStringFilterOption.StartsWith) ||
				filter.includes(SegmentStringFilterOption.EndsWith)
			) {
				sanitisedFilter = filter.trim().substring(1, filter.length - 1);
			} else {
				sanitisedFilter = filter.trim().replaceAll('(', StringHelper.Empty).replaceAll(')', StringHelper.Empty);
			}
			return SegmentHelper.ConvertToFilterItem(sanitisedFilter, index);
		});

		return columnFilterItems;
	}

	public static ConvertFiltersToFilterItems(filters: string[]): ColumnFilterItem[] {
		const columnFilterItems: ColumnFilterItem[] = [];
		if (ArrayHelper.SingleWithValue(filters, SegmentHelper.EmptyOrGroup)) {
			return [
				{
					id: '0',
					tablePrefix: SegmentHelper.ContactTablePrefix,
					recordType: SegmentRecordType.Contact,
				},
			];
		}

		filters.forEach((filter, index) => {
			const sanitisedFilter = filter.substring(1, filter.length - 1);
			columnFilterItems.push(SegmentHelper.ConvertToFilterItem(sanitisedFilter, index));
		});

		return columnFilterItems;
	}

	public static DetermineColumnType(matches: string[], columnOperator?: string): FieldType {
		const match = matches[0];
		if (!match || match === SegmentHelper.EmptyOrGroup || this.IsStringOption(columnOperator))
			return FieldType.String;

		const betweenNumbers = matches[0].split(FilterType.And);
		const columnValues = SegmentHelper.ExtractColumnValues(matches[0]);
		if (
			MathHelper.IsValidNumber(+match) ||
			(betweenNumbers.length === 2 && MathHelper.IsValidNumber(+betweenNumbers[0]))
		) {
			return FieldType.Number;
		}

		if (new Date(match).toString() !== 'Invalid Date' || new Date(columnValues[0]).toString() !== 'Invalid Date') {
			return FieldType.Date;
		}

		return FieldType.String;
	}

	public static ShouldClearSelection(previousSelection: string, currentSelection: string): boolean {
		return (
			((previousSelection === SegmentStringFilterOption.AnyOf ||
				previousSelection === SegmentStringFilterOption.NoneOf ||
				previousSelection === SegmentStringFilterOption.EndsWith ||
				previousSelection === SegmentStringFilterOption.StartsWith) &&
				(currentSelection === SegmentStringFilterOption.ContainsExactly ||
					currentSelection === SegmentStringFilterOption.DoesNotContainExactly)) ||
			((previousSelection === SegmentStringFilterOption.ContainsExactly ||
				previousSelection === SegmentStringFilterOption.DoesNotContainExactly ||
				previousSelection === SegmentStringFilterOption.EndsWith ||
				previousSelection === SegmentStringFilterOption.StartsWith) &&
				(currentSelection === SegmentStringFilterOption.AnyOf ||
					currentSelection === SegmentStringFilterOption.NoneOf)) ||
			((previousSelection === SegmentStringFilterOption.AnyOf ||
				previousSelection === SegmentStringFilterOption.NoneOf ||
				previousSelection === SegmentStringFilterOption.ContainsExactly ||
				previousSelection === SegmentStringFilterOption.DoesNotContainExactly) &&
				(currentSelection === SegmentStringFilterOption.EndsWith ||
					currentSelection === SegmentStringFilterOption.StartsWith))
		);
	}

	public static ShouldDisplayAnyOfDropdown(selectedOption: string): boolean {
		return (
			selectedOption === SegmentStringFilterOption.AnyOf || selectedOption === SegmentStringFilterOption.NoneOf
		);
	}

	public static ShouldDisplayTextBox(selectedOption: string): boolean {
		return (
			selectedOption === SegmentNumberFilterOption.GreaterThan ||
			selectedOption === SegmentNumberFilterOption.GreaterThanOrEqualTo ||
			selectedOption === SegmentNumberFilterOption.LessThan ||
			selectedOption === SegmentNumberFilterOption.LessThanOrEqualTo ||
			selectedOption === SegmentNumberFilterOption.Between ||
			selectedOption === SegmentNumberFilterOption.NotEqualTo ||
			selectedOption === SegmentStringFilterOption.StartsWith ||
			selectedOption === SegmentStringFilterOption.EndsWith ||
			selectedOption === SegmentStringFilterOption.ContainsExactly ||
			selectedOption === SegmentStringFilterOption.DoesNotContainExactly
		);
	}

	public static ShouldDisplayPrimaryDateInput(selectedOption: string): boolean {
		return selectedOption !== GenericFilterOption.Known && selectedOption !== GenericFilterOption.Unknown;
	}

	public static ShouldDisplayAddButton(selectedOption: string): boolean {
		return (
			selectedOption === SegmentStringFilterOption.ContainsExactly ||
			selectedOption === SegmentStringFilterOption.DoesNotContainExactly
		);
	}

	public static ShouldDisplaySelections(selectedOption?: string): boolean {
		return selectedOption !== GenericFilterOption.Known && selectedOption !== GenericFilterOption.Unknown;
	}

	public static IsNotKnownOrUnknownOperator(columnOperator: string): boolean {
		return (
			StringHelper.IsNotNullOrEmpty(columnOperator) &&
			!columnOperator.startsWith(GenericFilterOption.Known) &&
			!columnOperator.startsWith(GenericFilterOption.Unknown)
		);
	}

	public static IsValueInOperator(selectedOption: string): boolean {
		return (
			selectedOption === SegmentStringFilterOption.StartsWith ||
			selectedOption === SegmentStringFilterOption.EndsWith
		);
	}

	public static MapOptionToDescription(filterColumnType: FieldType, option: string): string {
		const sanitisedOption = option.trim();
		switch (filterColumnType) {
			case FieldType.Date:
				return this.MapDateOptionToDescription(sanitisedOption);
			case FieldType.Number:
				return this.MapNumberOptionToDescription(sanitisedOption);
			case FieldType.String:
				return this.MapStringOptionToDescription(sanitisedOption);
			default:
				return SegmentHelper.MapGenericOptionToDescription(option);
		}
	}

	public static FilterOptionIsValid(
		segmentFilterColumnType: FieldType,
		selectedFilter: string,
		selections: string[] = [],
		inputValues: string[] = []
	): boolean {
		let isValid = false;
		const hasSingleValue = StringHelper.IsNotNullOrEmpty(inputValues[0]);
		const hasMultipleValues =
			inputValues.filter(
				(inputValue) =>
					segmentFilterColumnType === FieldType.Number ||
					(StringHelper.IsNotNullOrEmpty(inputValue) && new Date(inputValue).toString() !== 'Invalid Date')
			).length === 2;

		const isKnownOrUnknown =
			selectedFilter === GenericFilterOption.Known || selectedFilter === GenericFilterOption.Unknown;

		switch (segmentFilterColumnType) {
			case FieldType.String:
				{
					isValid =
						((selectedFilter === SegmentStringFilterOption.AnyOf ||
							selectedFilter === SegmentStringFilterOption.NoneOf ||
							selectedFilter === SegmentStringFilterOption.ContainsExactly ||
							selectedFilter === SegmentStringFilterOption.DoesNotContainExactly) &&
							selections.length > 0) ||
						(selectedFilter === SegmentStringFilterOption.StartsWith && hasSingleValue) ||
						(selectedFilter === SegmentStringFilterOption.EndsWith && hasSingleValue) ||
						isKnownOrUnknown;
				}
				break;
			case FieldType.Date:
				{
					isValid =
						((selectedFilter === SegmentDateFilterOption.EqualTo ||
							selectedFilter === SegmentDateFilterOption.After ||
							selectedFilter === SegmentDateFilterOption.AfterOrOn ||
							selectedFilter === SegmentDateFilterOption.Before ||
							selectedFilter === SegmentDateFilterOption.BeforeOrOn) &&
							hasSingleValue) ||
						(selectedFilter === SegmentDateFilterOption.Between && hasMultipleValues) ||
						isKnownOrUnknown;
				}
				break;
			case FieldType.Number:
				{
					isValid =
						((selectedFilter === SegmentNumberFilterOption.GreaterThan ||
							selectedFilter === SegmentNumberFilterOption.GreaterThanOrEqualTo ||
							selectedFilter === SegmentNumberFilterOption.LessThan ||
							selectedFilter === SegmentNumberFilterOption.LessThanOrEqualTo ||
							selectedFilter === SegmentNumberFilterOption.NotEqualTo) &&
							hasSingleValue) ||
						(selectedFilter === SegmentNumberFilterOption.Between && hasMultipleValues) ||
						isKnownOrUnknown;
				}
				break;
			default:
				isValid = isKnownOrUnknown;
				break;
		}
		return isValid;
	}

	private static GetDefaultColumnFilterItem(match: string[], index: number): ColumnFilterItem {
		return {
			id: `${index}`,
			tablePrefix: match[2],
			columnName: match[3],
			recordType: SegmentRecordType.None,
		};
	}

	private static ExtractColumnValues(input: string): string[] {
		const sanitisedInput = StringHelper.SanitiseString(input, ['(', ')'], StringHelper.Empty);
		if (MathHelper.IsValidNumber(Number(sanitisedInput))) return [sanitisedInput];

		const values = sanitisedInput.split(` ${FilterType.And} `).map((value) => {
			return value.replaceAll(StringHelper.SingleQuote, StringHelper.Empty).trim();
		});
		if (values.length === 2) return values;

		const regEx = /^(?:[\(|'])(.*)(?:[\)|'])/g;
		const match = regEx.exec(input);
		if (!match) return [sanitisedInput.replaceAll(StringHelper.SingleQuote, StringHelper.Empty)];

		return sanitisedInput.split(',').map((item) => {
			let extractedItem = item.trim();
			extractedItem = extractedItem.startsWith(StringHelper.SingleQuote)
				? extractedItem.substring(1, extractedItem.length - 1)
				: extractedItem;
			extractedItem = extractedItem.endsWith(StringHelper.SingleQuote)
				? extractedItem.substring(0, extractedItem.length - 1)
				: extractedItem;

			return extractedItem;
		});
	}

	private static IsStringOption(option?: string) {
		if (!option) return false;
		return (
			option === SegmentStringFilterOption.AnyOf ||
			option === SegmentStringFilterOption.NoneOf ||
			option === SegmentStringFilterOption.StartsWith ||
			option === SegmentStringFilterOption.EndsWith ||
			option === SegmentStringFilterOption.ContainsExactly ||
			option === SegmentStringFilterOption.DoesNotContainExactly
		);
	}

	private static MapDateOptionToDescription(option: string): string {
		switch (option) {
			case SegmentDateFilterOption.EqualTo:
				return 'Equal to';
			case SegmentDateFilterOption.After:
				return 'After';
			case SegmentDateFilterOption.AfterOrOn:
				return 'After or on';
			case SegmentDateFilterOption.Before:
				return 'Before';
			case SegmentDateFilterOption.BeforeOrOn:
				return 'Before or on';
			case SegmentDateFilterOption.Between:
				return StringHelper.Capitalize(SegmentDateFilterOption.Between);
			default:
				return this.MapGenericOptionToDescription(option);
		}
	}

	private static MapNumberOptionToDescription(option: string): string {
		switch (option) {
			case SegmentNumberFilterOption.EqualTo:
				return 'Equal to';
			case SegmentNumberFilterOption.NotEqualTo:
				return 'Not equal to';
			case SegmentNumberFilterOption.GreaterThan:
				return 'Greater than';
			case SegmentNumberFilterOption.GreaterThanOrEqualTo:
				return 'Greater than or equal to';
			case SegmentNumberFilterOption.LessThan:
				return 'Less than';
			case SegmentNumberFilterOption.LessThanOrEqualTo:
				return 'Less than or equal to';
			case SegmentNumberFilterOption.Between:
				return 'Between';
			default:
				return this.MapGenericOptionToDescription(option);
		}
	}

	private static MapStringOptionToDescription(option: string): string {
		switch (option) {
			case SegmentStringFilterOption.EqualTo:
				return 'Equal to';
			case SegmentStringFilterOption.ContainsExactly:
				return 'Contains exactly';
			case SegmentStringFilterOption.DoesNotContainExactly:
				return 'Does not contain exactly';
			case SegmentStringFilterOption.AnyOf:
				return 'Any of';
			case SegmentStringFilterOption.NoneOf:
				return 'None of';
			case SegmentStringFilterOption.StartsWith:
				return 'Starts with';
			case SegmentStringFilterOption.EndsWith:
				return 'Ends with';
			default:
				return this.MapGenericOptionToDescription(option);
		}
	}

	private static MapGenericOptionToDescription(option: string): string {
		switch (option) {
			case GenericFilterOption.Known:
				return 'Known';
			case GenericFilterOption.Unknown:
				return 'Unknown';
		}
		return option;
	}
}
