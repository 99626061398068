import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface PowerBIState {
	value: any;
}

// Define the initial state using that type
const initialState: PowerBIState = {
	value: null,
};

export const powerBISlice = createSlice({
	name: 'powerbi',
	initialState,
	reducers: {
		setPowerBIResponse: (state, action: PayloadAction<any>) => {
			state.value = action.payload;
		},
	},
});

export const { setPowerBIResponse } = powerBISlice.actions;

export default powerBISlice.reducer;
