import styles from './Segments.module.scss';
import React, { useEffect, useState } from 'react';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { useNavigate } from 'react-router-dom';
import { PrimaryRoutes } from '../../../common/enums/internal-route-enums';
import { SegmentView } from '../../../models/segments/segment-view';
import {
	useContactsApiService,
	useReferenceValuesApiService,
	useSegmentsApiService,
} from '../../../api/providers/AppServiceProvider';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setCachedSegmentViewContactCount, setCachedSegmentViews } from '../../../features/segments-slice';
import { StringHelper } from '../../../common/helpers/string-helper';
import { Container } from '../../atoms/Base/Container/Container';
import Label from '../../atoms/Base/Label/Label';
import Separator from '../../atoms/Base/Separator/Seperator';
import CXDataLoader from '../../atoms/Custom/CXDataLoader/CXDataLoader';
import SegmentsTableTemplate from '../../templates/SegmentsTableTemplate/SegmentsTableTemplate';
import TextBox from '../../atoms/Base/TextBox/TextBox';
import { StringValueCallback } from '../../../types/Callbacks';
import { setCachedContactColumnNames, setCachedTotalContactCount } from '../../../features/contacts-slice';
import { ReferenceValuesHelper } from '../../../common/helpers/reference-values-helper';
import { setCachedSelectedMedianColumns } from '../../../features/median-values-slice';
import { FieldType } from '../../../common/enums/field-type-enums';

const Segments = () => {
	const dispatch = useAppDispatch();
	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);
	const cachedRefreshState = useAppSelector((state) => state.segments.refresh);
	const contactsApiService = useContactsApiService();
	const referenceValuesApiService = useReferenceValuesApiService();
	const segmentsApiService = useSegmentsApiService();

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [segmentViews, setSegmentViews] = useState<SegmentView[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>(StringHelper.Empty);

	useEffect(() => {
		if (!ArrayHelper.HasElements(cachedContactColumnNames)) {
			referenceValuesApiService.GetContactColumnNames().then((response) => {
				dispatch(setCachedContactColumnNames(response));
				dispatch(
					setCachedSelectedMedianColumns(
						ReferenceValuesHelper.GetColumnsByType(response, FieldType.Number, 3)
					)
				);
			});
		} else {
			dispatch(
				setCachedSelectedMedianColumns(
					ReferenceValuesHelper.GetColumnsByType(cachedContactColumnNames, FieldType.Number, 3)
				)
			);
		}

		contactsApiService.GetTotalCount().then((response) => {
			dispatch(setCachedTotalContactCount(response.count));
		});

		dispatch(setCachedSegmentViewContactCount(0));
		setIsLoading(true);
		segmentsApiService.GetSegmentViews().then((response) => {
			const segmentViews = response.map((segmentView: SegmentView) => {
				const segmentNameParts = segmentView.segmentName.split('|');
				const testVariantPercentages = segmentNameParts.slice(1, 5);
				return {
					...segmentView,
					contactCount: segmentNameParts.length === 7 ? Number(segmentNameParts[6]) : 0,
					segmentName: segmentNameParts[0],
					segmentType:
						segmentNameParts.length === 7
							? segmentNameParts[5]
							: segmentNameParts.length === 2
							? segmentNameParts[1]
							: StringHelper.Empty,
					testVariantPercentages:
						ArrayHelper.HasElements(testVariantPercentages) && testVariantPercentages.length === 4
							? testVariantPercentages
							: undefined,
				};
			});
			dispatch(setCachedSegmentViews(segmentViews));
			setSegmentViews(segmentViews);
			setIsLoading(false);
		});
	}, [cachedRefreshState]);

	const onChangeHandler: StringValueCallback = (newValue: string) => {
		setSearchTerm(newValue.toLowerCase());
	};

	return (
		<Container padding>
			<Container childSpace bottomSpace left>
				<h3>Create a New Segment</h3>
				<Label>Unlock the full potential of campaigns by creating AI-powered segments.</Label>
			</Container>
			<Container topSpaceLarge left>
				<ActionButton tall onClick={() => navigate(PrimaryRoutes.SegmentBuilder)}>
					Create Segment
				</ActionButton>
			</Container>
			<Separator />
			<Container bottomSpace>
				<Label>SEGMENT DETAILS</Label>
			</Container>
			<Container>
				{isLoading && <CXDataLoader />}
				{!isLoading && segmentViews && (
					<Container className={styles.segments_container} childSpace>
						<Container left>
							<TextBox
								showSearchIcon
								placeHolder={'Search Segments by ID or Name'}
								onChange={onChangeHandler}
							/>
						</Container>
						<Container>
							<SegmentsTableTemplate segmentViews={segmentViews} searchTerm={searchTerm} />
						</Container>
					</Container>
				)}
			</Container>
		</Container>
	);
};

export default Segments;
