import styles from './Table.module.scss';
import React, { FC, ReactNode } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import { StringHelper } from '../../../../common/helpers/string-helper';

interface ITableProps {
	children: ReactNode;
	className?: string;
}

const Table: FC<ITableProps> = ({ children, className }) => {
	return (
		<BootstrapTable className={`${styles.table}${className ? ` ${className}` : StringHelper.Empty}`}>
			{children}
		</BootstrapTable>
	);
};

export default Table;
