import styles from './DualButton.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { Container } from '../../../atoms/Base/Container/Container';
import Label from '../../../atoms/Base/Label/Label';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { StringValueCallback } from '../../../../types/Callbacks';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface IDualButtonProps {
	optionOne: string;
	optionTwo: string;
	selectedOption?: string;
	onOptionSelected?: StringValueCallback;
}

const DualButton: FC<IDualButtonProps> = ({ optionOne, optionTwo, selectedOption, onOptionSelected }) => {
	const [activeOption, setActiveOption] = useState<string>(StringHelper.Empty);

	useEffect(() => {
		if (!selectedOption) return;
		setActiveOption(StringHelper.ValueOrEmpty(selectedOption));
	}, [selectedOption]);

	const onOptionSelectedHandler = (option: string) => {
		setActiveOption(option);
		if (onOptionSelected) onOptionSelected(option);
	};

	return (
		<Container fullWidth horizontal>
			<Container horizontal>
				<Label
					className={styles.label_left}
					clickable
					disabled={activeOption === optionTwo}
					whiteText={activeOption === optionOne}
					onClick={() => onOptionSelectedHandler(optionOne)}
				>
					{optionOne}
				</Label>
			</Container>
			<Container horizontal>
				<Label
					className={styles.label_right}
					clickable
					disabled={activeOption === optionOne}
					whiteText={activeOption === optionTwo}
					onClick={() => onOptionSelectedHandler(optionTwo)}
				>
					{optionTwo}
				</Label>
			</Container>
		</Container>
	);
};

export default DualButton;
