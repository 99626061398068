import styles from './Container.module.scss';

import React, { FC, ReactElement, ReactNode } from 'react';
import { IBaseComponentProps } from '../../../../types/IBaseComponentProps';
import { Callback } from '../../../../types/Callbacks';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface IContainerProps extends IBaseComponentProps {
	title?: string;
	horizontal?: boolean;
	small?: boolean;
	medium?: boolean;
	large?: boolean;
	xLarge?: boolean;
	fill?: boolean;
	border?: boolean;
	divider?: boolean;
	shadow?: boolean;
	padding?: boolean;
	paddingLarge?: boolean;
	paddingXLarge?: boolean;
	bottomLeft?: boolean;
	center?: boolean;
	centerFull?: boolean;
	centerLeft?: boolean;
	right?: boolean;
	left?: boolean;
	topSpace?: boolean;
	topSpaceSmall?: boolean;
	topSpaceLarge?: boolean;
	topSpaceXLarge?: boolean;
	leftSpace?: boolean;
	leftSpaceLarge?: boolean;
	rightSpace?: boolean;
	rightSpaceLarge?: boolean;
	bottomSpace?: boolean;
	bottomSpaceLarge?: boolean;
	bottomPaddingLarge?: boolean;
	childSpace?: boolean;
	childSpaceLarge?: boolean;
	fullWidth?: boolean;
	pageWidth?: boolean;
	autoWidth?: boolean;
	stretchHeight?: boolean;
	prominentFill?: boolean;
	prominentPadding?: boolean;
	wrap?: boolean;
	sticky?: boolean;
	toolTip?: string;
	className?: string;
	visible?: boolean;
	disabled?: boolean;
	onClick?: Callback;
	children?: ReactNode;
}

export const Container: FC<IContainerProps> = ({
	title,
	horizontal,
	small,
	medium,
	large,
	xLarge,
	fill,
	border,
	divider,
	shadow,
	padding,
	paddingLarge,
	paddingXLarge,
	bottomLeft,
	center,
	centerFull,
	centerLeft,
	right,
	left,
	topSpace,
	topSpaceSmall,
	topSpaceLarge,
	topSpaceXLarge,
	leftSpace,
	leftSpaceLarge,
	rightSpace,
	rightSpaceLarge,
	bottomSpace,
	bottomSpaceLarge,
	bottomPaddingLarge,
	childSpace,
	childSpaceLarge,
	fullWidth,
	pageWidth,
	autoWidth,
	stretchHeight,
	prominentFill,
	prominentPadding,
	wrap,
	sticky,
	toolTip,
	className,
	visible = true,
	disabled,
	onClick,
	children,
}): ReactElement | null => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const containerClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.container)
		.AddConditionalClass(small, styles.small)
		.AddConditionalClass(medium, styles.medium)
		.AddConditionalClass(large, styles.large)
		.AddConditionalClass(xLarge, styles.xlarge)
		.AddConditionalClass(fill, styles.fill)
		.AddConditionalClass(border, styles.border)
		.AddConditionalClass(divider, styles.divider)
		.AddConditionalClass(shadow, styles.shadow)
		.AddConditionalClass(topSpace, styles.top_space)
		.AddConditionalClass(topSpaceSmall, styles.top_space_small)
		.AddConditionalClass(topSpaceLarge, styles.top_space_large)
		.AddConditionalClass(topSpaceXLarge, styles.top_space_xlarge)
		.AddConditionalClass(leftSpace, styles.left_space)
		.AddConditionalClass(leftSpaceLarge, styles.left_space_large)
		.AddConditionalClass(rightSpace, styles.right_space)
		.AddConditionalClass(rightSpaceLarge, styles.right_space_large)
		.AddConditionalClass(bottomSpace, styles.bottom_space)
		.AddConditionalClass(bottomSpaceLarge, styles.bottom_space_large)
		.AddConditionalClass(title && topSpace, styles.adjuster_title_with_top_space)
		.AddConditionalClass(title && !topSpace, styles.adjuster_title_no_top_space)
		.AddConditionalClass(fullWidth, styles.full_width)
		.AddConditionalClass(pageWidth, styles.page_width)
		.AddConditionalClass(autoWidth, styles.auto_width)
		.AddConditionalClass(stretchHeight, styles.stretch_height)
		.AddConditionalClass(prominentFill, styles.prominent_fill)
		.AddConditionalClass(sticky, styles.sticky)
		.AddConditionalClass(disabled !== undefined, styles.adjuster_relative)
		.AddConditionalClass(className, className)
		.GetClassNames();

	const clientClassNames = classNameBuilderFactory
		.Create()
		.AddClass('container_client')
		.AddClass(styles.container_client)
		.AddConditionalClass(!centerFull && title, styles.adjuster_title)
		.AddConditionalClass(horizontal, styles.horizontal)
		.AddConditionalClass(padding, styles.padding)
		.AddConditionalClass(paddingLarge, styles.padding_large)
		.AddConditionalClass(paddingXLarge, styles.padding_xlarge)
		.AddConditionalClass(center, styles.center)
		.AddConditionalClass(bottomLeft, styles.bottom_left)
		.AddConditionalClass(centerFull, styles.center_full)
		.AddConditionalClass(centerLeft, styles.center_left)
		.AddConditionalClass(right, styles.right)
		.AddConditionalClass(left, styles.left)
		.AddConditionalClass(childSpace && horizontal, styles.adjuster_child_space_horizonal)
		.AddConditionalClass(childSpace && !horizontal, styles.adjuster_child_space_vertical)
		.AddConditionalClass(childSpaceLarge && horizontal, styles.adjuster_child_space_horizonal_large)
		.AddConditionalClass(childSpaceLarge && !horizontal, styles.adjuster_child_space_vertical_large)
		.AddConditionalClass(prominentPadding, styles.prominent_padding)
		.AddConditionalClass(bottomPaddingLarge, styles.bottom_padding_large)
		.AddConditionalClass(wrap, styles.wrap)
		.GetClassNames();

	const overlayClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.container_disabled_overlay)
		.AddConditionalClass(title, styles.adjuster_overlay_title)
		.GetClassNames();

	const renderContainer = (hasOverlay?: boolean): ReactElement => {
		return (
			<div className={containerClassNames} title={toolTip} onClick={onClick}>
				{title && <div className={styles.container_title}>{title}</div>}
				<div className={clientClassNames}>{children}</div>
				{hasOverlay && <div className={overlayClassNames} />}
			</div>
		);
	};

	if (visible) return renderContainer(disabled);

	return null;
};
