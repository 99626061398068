import styles from './PercentageBar.module.scss';
import React, { FC, useEffect } from 'react';
import { Container } from '../../../../atoms/Base/Container/Container';
import { StringHelper } from '../../../../../common/helpers/string-helper';
import { MathHelper } from '../../../../../common/helpers/math-helper';

interface IPercentageBarProps {
	displayEmptyCount?: boolean;
	primary: boolean;
	rowCount: number;
	totalCount: number;
	percentage: number;
}

const PercentageBar: FC<IPercentageBarProps> = ({
	displayEmptyCount,
	primary,
	rowCount,
	totalCount,
	percentage = 0,
}) => {
	useEffect(() => {}, [percentage]);

	return (
		<div className={`${styles.progress} ${styles.progress_moved}`}>
			<div
				className={styles.progress_bar}
				style={{ width: `${percentage > 0 && MathHelper.IsValidNumber(percentage) ? percentage : 0}%` }}
			>
				{percentage > 0 || (rowCount > 0 && totalCount > 0) || displayEmptyCount ? (
					<Container className={styles.progress_bar_text}>
						<span>
							{rowCount}/{totalCount} ({percentage}%{StringHelper.WhiteSpace}
							{primary ? 'of contacts' : 'of segment'})
						</span>
					</Container>
				) : (
					<Container className={styles.progress_bar_text}>
						<span className={styles.re_calculate}>Calculating</span>
					</Container>
				)}
			</div>
		</div>
	);
};

export default PercentageBar;
