import React, { FC } from 'react';
import { Container } from '../../atoms/Base/Container/Container';
import Label from '../../atoms/Base/Label/Label';
import { SignOutButton } from '../../atoms/Custom/SignOutButton/SignOutButton';

interface IAuthenticatedContainerProps {
	isLoading: boolean;
}
const AuthenticatedContainer: FC<IAuthenticatedContainerProps> = ({ isLoading }) => {
	const { version: appVersion } = require('../../../../package.json');

	return (
		<Container childSpace center>
			<Label>App Version {appVersion}</Label>
			<SignOutButton isLoading={isLoading} />
		</Container>
	);
};

export default AuthenticatedContainer;
