/* eslint-disable no-unused-vars */
import { BaseTableCount } from '../../models/base/base-table-count';
import { ContactSearchTermRequest } from '../../models/contacts/requests/contact-search-term-request';
import { GetContactsRequest } from '../../models/contacts/requests/get-contacts-request';
import { GetMedianValueResult } from '../../models/median-values/get-median-value-result';
import { GetMedianValueRequest } from '../../models/median-values/requests/get-median-value-request';
import { ApiServiceBase } from './base/api-service-base';

const baseEndpoint = 'contacts';

export interface IContactsApiService {
	GetContacts(getContactsRequest: GetContactsRequest): Promise<any>;
	ExportContacts(getContactsRequest: GetContactsRequest): Promise<any>;
	GetMedianValues(getMedianValueRequest: GetMedianValueRequest): Promise<GetMedianValueResult[]>;
	GetTotalCount(): Promise<BaseTableCount>;
	GetSearchContactsCount(contactSearchTermRequest: ContactSearchTermRequest): Promise<BaseTableCount>;
	SearchContacts(contactSearchTermRequest: ContactSearchTermRequest): Promise<any>;
}

export class ContactsApiService extends ApiServiceBase implements IContactsApiService {
	public async GetContacts(getContactsRequest: GetContactsRequest): Promise<any> {
		return await this.PostToApi<any>(`${baseEndpoint}`, getContactsRequest);
	}

	public async ExportContacts(getContactsRequest: GetContactsRequest): Promise<any> {
		return await this.PostToApi<any>(`${baseEndpoint}/export`, getContactsRequest, 'blob');
	}

	public async GetMedianValues(getMedianValueRequest: GetMedianValueRequest): Promise<GetMedianValueResult[]> {
		return await this.PostToApi<GetMedianValueResult[]>(`${baseEndpoint}/median-values`, getMedianValueRequest);
	}

	public async GetTotalCount(): Promise<BaseTableCount> {
		return await this.PostToApi<BaseTableCount>(`${baseEndpoint}/total-count`);
	}

	public async GetSearchContactsCount(contactSearchTermRequest: ContactSearchTermRequest): Promise<BaseTableCount> {
		return await this.PostToApi<BaseTableCount>(`${baseEndpoint}/search/count`, contactSearchTermRequest);
	}

	public async SearchContacts(contactSearchTermRequest: ContactSearchTermRequest): Promise<any> {
		return await this.PostToApi<any>(`${baseEndpoint}/search`, contactSearchTermRequest);
	}
}
