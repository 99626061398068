/* eslint-disable no-unused-vars */
export const enum SegmentType {
	Dynamic = 'Dynamic',
	Static = 'Static',
}

export const enum SegmentRecordType {
	None = 'None',
	Activity = 'Activity',
	Contact = 'Contact',
}

export const enum SegmentDateFilterOption {
	EqualTo = '=',
	After = '>',
	AfterOrOn = '>=',
	Before = '<',
	BeforeOrOn = '<=',
	Between = 'BETWEEN',
}

export const enum SegmentNumberFilterOption {
	EqualTo = '=',
	NotEqualTo = '!=',
	GreaterThan = '>',
	GreaterThanOrEqualTo = '>=',
	LessThan = '<',
	LessThanOrEqualTo = '<=',
	Between = 'BETWEEN',
}

export enum SegmentStringFilterOption {
	EqualTo = '=',
	AnyOf = 'IN',
	NoneOf = 'NOT IN',
	ContainsExactly = 'ILIKE ANY',
	DoesNotContainExactly = 'NOT ILIKE ANY',
	StartsWith = 'STARTSWITH',
	EndsWith = 'ENDSWITH',
}

export enum GenericFilterOption {
	Known = 'IS NOT NULL',
	Unknown = 'IS NULL',
}

export enum FilterType {
	Default = 'ADD FILTER',
	And = 'AND',
	Or = 'OR',
}

export enum SegmentViewField {
	ABTest = 'AB Test',
	Contacts = 'Contacts',
	CreatedDate = 'Created Date',
	SegmentId = 'Segment ID',
	SegmentName = 'Segment Name',
	SegmentType = 'Segment Type',
}
