import React, { FC, ReactNode, createContext, useContext, useState } from 'react';
import { ISegmentsApiService } from '../services/segments-api-service';
import { AppConfigServiceFactory } from '../factories/app-config-service-factory';
import { IServiceFactory } from '../../common/types/service-factory';
import { SegmentsApiServiceFactory } from '../factories/segments-api-service-factory';
import { IAppConfigService } from '../interfaces/app-config-service';
import { IApiService } from '../interfaces/api-service';
import { useApiServiceFactory } from '../../common/providers/ReactKitProvider';
import { IContactsApiService } from '../services/contacts-api-service';
import { ContactsApiServiceFactory } from '../factories/contacts-api-service-factory';
import { IViewsApiService } from '../services/view-api-service';
import { ViewsApiServiceFactory } from '../factories/views-api-service-factory';
import { IReferenceValuesApiService } from '../services/reference-values-api-service';
import { ReferenceValuesApiServiceFactory } from '../factories/reference-values-api-service-factory';
import { IPowerBIApiService } from 'api/services/powerbi-api-service';
import { PowerBiApiServiceFactory } from 'api/factories/powerbi-api-service-factory';

interface IAppServiceContainer {
	createAppConfigService(): IAppConfigService;
	createContactsApiService(): IContactsApiService;
	createReferenceValuesApiService(): IReferenceValuesApiService;
	createSegmentsApiService(): ISegmentsApiService;
	createViewsApiService(): IViewsApiService;
	createPowerBIApiService(): IPowerBIApiService;
}

class AppServiceContainer implements IAppServiceContainer {
	private appConfigServiceFactory: IServiceFactory<IAppConfigService>;
	private contactsApiServiceFactory: IServiceFactory<IContactsApiService>;
	private referenceValuesApiServiceFactory: IServiceFactory<IReferenceValuesApiService>;
	private segmentsApiServiceFactory: IServiceFactory<ISegmentsApiService>;
	private viewsApiServiceFactory: IServiceFactory<IViewsApiService>;
	private powerBiApiServiceFactory: IServiceFactory<IPowerBIApiService>;

	constructor(apiServiceFactory: IServiceFactory<IApiService>) {
		this.appConfigServiceFactory = new AppConfigServiceFactory();
		const appConfig = this.appConfigServiceFactory.Create();

		this.contactsApiServiceFactory = new ContactsApiServiceFactory(apiServiceFactory, appConfig);
		this.referenceValuesApiServiceFactory = new ReferenceValuesApiServiceFactory(apiServiceFactory, appConfig);
		this.segmentsApiServiceFactory = new SegmentsApiServiceFactory(apiServiceFactory, appConfig);
		this.viewsApiServiceFactory = new ViewsApiServiceFactory(apiServiceFactory, appConfig);
		this.powerBiApiServiceFactory = new PowerBiApiServiceFactory(apiServiceFactory, appConfig);
	}

	public createAppConfigService(): IAppConfigService {
		return this.appConfigServiceFactory.Create();
	}

	public createContactsApiService(): IContactsApiService {
		return this.contactsApiServiceFactory.Create();
	}

	public createReferenceValuesApiService(): IReferenceValuesApiService {
		return this.referenceValuesApiServiceFactory.Create();
	}

	public createSegmentsApiService(): ISegmentsApiService {
		return this.segmentsApiServiceFactory.Create();
	}

	public createViewsApiService(): IViewsApiService {
		return this.viewsApiServiceFactory.Create();
	}

	public createPowerBIApiService(): IPowerBIApiService {
		return this.powerBiApiServiceFactory.Create();
	}
}

const AppServiceContext: React.Context<IAppServiceContainer> = createContext(null as unknown as IAppServiceContainer);

interface IAppServiceProviderProps {
	children?: ReactNode;
}

export const AppServiceProvider: FC<IAppServiceProviderProps> = ({ children }) => {
	const [appServiceContainer] = useState(new AppServiceContainer(useApiServiceFactory()));
	return <AppServiceContext.Provider value={appServiceContainer}>{children}</AppServiceContext.Provider>;
};

export const useConfiguration = (): IAppConfigService => {
	return useContext(AppServiceContext).createAppConfigService();
};

export const useContactsApiService = (): IContactsApiService => {
	return useContext(AppServiceContext).createContactsApiService();
};

export const useReferenceValuesApiService = (): IReferenceValuesApiService => {
	return useContext(AppServiceContext).createReferenceValuesApiService();
};

export const useSegmentsApiService = (): ISegmentsApiService => {
	return useContext(AppServiceContext).createSegmentsApiService();
};

export const useViewsApiService = (): IViewsApiService => {
	return useContext(AppServiceContext).createViewsApiService();
};

export const usePowerBIApiService = (): IPowerBIApiService => {
	return useContext(AppServiceContext).createPowerBIApiService();
};
