import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { IAuthenticationProps } from '../SignInButton/SignInButton';
import ActionButton from '../../Base/ActionButton/ActionButton';
import Loader from '../../Base/Loader/Loader';

/**
 * Renders a sign-out button
 */
export const SignOutButton: FC<IAuthenticationProps> = ({ isLoading }) => {
	const { instance } = useMsal();

	// eslint-disable-next-line no-unused-vars
	const logoutPopup = () => {
		instance
			.logoutPopup({
				postLogoutRedirectUri: '/',
				mainWindowRedirectUri: '/',
			})
			.catch((e) => {
				console.log(e);
				// Log the error to an external logging service
			});
	};

	const logoutRedirect = () => {
		instance.logoutRedirect({ postLogoutRedirectUri: '/' });
	};

	const handleLogout = () => {
		logoutRedirect();
	};

	return (
		<ActionButton secondary onClick={() => handleLogout()}>
			{isLoading && <Loader simple size={'sm'} />}
			{!isLoading && `Sign Out`}
		</ActionButton>
	);
};
