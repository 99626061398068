import styles from './ActionButton.module.scss';
import React, { FC, ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import Loader from '../Loader/Loader';

interface IActionButtonProps {
	children: ReactNode;
	cancel?: boolean;
	className?: string;
	disabled?: boolean;
	highlight?: boolean;
	isDelete?: boolean;
	noBoxShadow?: boolean;
	rounded?: boolean;
	secondary?: boolean;
	smallFixedWidth?: boolean;
	tall?: boolean;
	isLoading?: boolean;
	onClick?: React.MouseEventHandler;
}

const ActionButton: FC<IActionButtonProps> = ({
	children,
	cancel,
	className,
	disabled,
	isDelete,
	highlight,
	noBoxShadow,
	rounded,
	secondary,
	smallFixedWidth,
	tall,
	isLoading,
	onClick,
}) => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const classNames = classNameBuilderFactory
		.Create()
		.AddClass(className)
		.AddClass(styles.actionButton)
		.AddConditionalClass(!disabled, styles.active)
		.AddConditionalClass(cancel, styles.cancel)
		.AddConditionalClass(isDelete, styles.delete)
		.AddConditionalClass(noBoxShadow, styles.no_box_shadow)
		.AddConditionalClass(rounded, styles.rounded)
		.AddConditionalClass(secondary, styles.secondary)
		.AddConditionalClass(smallFixedWidth, styles.small_fixed_width)
		.AddConditionalClass(tall, styles.tall)
		.AddConditionalClass(highlight, styles.highlight)
		.GetClassNames();

	const onClickHandler = (event: any) => {
		if (onClick) onClick(event);
	};

	return (
		<Button className={classNames} onClick={onClickHandler}>
			{!isLoading && children}
			{isLoading && <Loader simple size={'sm'} />}
		</Button>
	);
};

export default ActionButton;
