import { loginRequest } from '../../authConfig';
import { msalInstance } from 'index';
export default class MsalDataApiService {
	public static async GetAccessToken(): Promise<string> {
		const accounts = msalInstance?.getAllAccounts();
		if (accounts != null && accounts.length > 0) {
			return msalInstance
				.acquireTokenSilent({ scopes: loginRequest.scopes, account: accounts[0] })
				.then((authResult) => authResult.accessToken)
				.catch(async () => {
					// if access token not available in cache, interact with user to acquire new access token
					try {
						const authResult = await msalInstance.acquireTokenPopup({
							scopes: loginRequest.scopes,
							account: accounts[0],
						});
						return authResult.accessToken;
					} catch {
						return '';
					}
				});
		} else {
			return Promise.resolve('');
		}
	}
}
