import styles from './SegmentBuilder.module.scss';
import React, { useEffect, useState } from 'react';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { useLocation } from 'react-router-dom';
import Label from '../../atoms/Base/Label/Label';
import ContactsTableTemplate from '../../templates/ContactsTableTemplate/ContactsTableTemplate';
import SegmentOrGroupTemplate from '../../templates/SegmentOrGroupTemplate/SegmentOrGroupTemplate';
import { Contact } from '../../../models/contacts/contact';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	setCachedSegmentViewContactCount,
	setCachedOrGroups,
	setCachedSegmentViews,
} from '../../../features/segments-slice';
import { CreateSegmentRequest } from '../../../models/segments/requests/create-segment-request';
import { StringHelper } from '../../../common/helpers/string-helper';
import {
	useContactsApiService,
	useReferenceValuesApiService,
	useSegmentsApiService,
} from '../../../api/providers/AppServiceProvider';
import TestVariantsModal from '../../organisms/TestVariantsModal/TestVariantsModal';
import { SegmentView } from '../../../models/segments/segment-view';
import { ObjectHelper } from '../../../common/helpers/object-helper';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { ExcelHelper } from '../../../common/helpers/excel-helper';
import SelectColumnsModal from '../../organisms/SelectColumnsModal/SelectColumnsModal';
import { ContactsHelper } from '../../../common/helpers/contacts-helper';
import { setCachedContactColumnNames, setCachedTotalContactCount } from '../../../features/contacts-slice';
import SegmentFilterPercentageBar from '../../molecules/Custom/SegmentFilterPercentageBar/SegmentFilterPercentageBar';
import { Container } from '../../atoms/Base/Container/Container';
import { EventArgsCallback, StringValueCallback } from '../../../types/Callbacks';
import { ModifiedFilter } from '../../../common/models/filter';
import MedianValueColumnSelections from '../../organisms/MedianValueColumnSelections/MedianValueColumnSelections';
import SegmentName from '../../molecules/Custom/SegmentName/SegmentName';
import { useClassNameBuilderFactory } from '../../../common/providers/ReactKitProvider';
import DualButton from '../../molecules/Custom/DualButton/DualButton';
import { SegmentHelper } from '../../../common/helpers/segment-helper';
import { SegmentType } from '../../../common/enums/segment-enums';
import MedianValueResults from '../../molecules/Custom/MedianValueResults/MedianValueResults';
import Separator from '../../atoms/Base/Separator/Seperator';
import CXDataLoader from '../../atoms/Custom/CXDataLoader/CXDataLoader';
import { setCachedActivityColumnNames } from '../../../features/activity-slice';
import { RowCount } from '../../../models/row-count';
import { DateHelper } from 'common/helpers/date-helper';

const SegmentBuilder = () => {
	const dispatch = useAppDispatch();
	const cachedSegmentViews = useAppSelector((state) => state.segments.views);
	const cachedOrGroups: string[] = useAppSelector((state) => state.segments.orGroups);
	const cachedTotalContactCount: number = useAppSelector((state) => state.contacts.totalCount);
	const cachedSegmentViewContactCount = useAppSelector((state) => state.segments.contactCount);
	const cachedActivityColumnNames = useAppSelector((state) => state.activity.columnNames);
	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);
	const cachedSelectedContactColumnNames: string[] = useAppSelector((state) => state.contacts.selectedColumns);

	const contactsApiService = useContactsApiService();
	const segmentsApiService = useSegmentsApiService();
	const referenceValuesApiService = useReferenceValuesApiService();

	const { state } = useLocation();

	const [contacts, setContacts] = useState<Contact[]>([]);
	const [currentSegmentView, setCurrentSegmentView] = useState<SegmentView>({
		createdOn: StringHelper.Empty,
		segmentId: StringHelper.Empty,
		segmentName: StringHelper.Empty,
		text: StringHelper.Empty,
	});
	const [segmentViewContactCount, setSegmentViewContactCount] = useState<number>(0);

	const [currentOrGroups, setCurrentOrGroups] = useState<string[]>([]);
	const [currentModifiedFilter, setCurrentModifiedFilter] = useState<ModifiedFilter>();
	const [where, setWhere] = useState<string>(StringHelper.Empty);

	const [segmentName, setSegmentName] = useState<string>(StringHelper.Empty);
	const [segmentNameError, setSegmentNameError] = useState<string>(StringHelper.Empty);
	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [showTestVariantsModal, setShowTestVariantsModal] = useState<boolean>(false);
	const [showSelectColumnsModal, setShowSelectColumnsModal] = useState<boolean>(false);
	const [showContactsTable, setShowContactsTable] = useState<boolean>(false);
	const [viewIndex, setViewIndex] = useState<number>(-1);

	const [isExporting, setIsExporting] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [contactTableClassNames, setContactTableClassNames] = useState<string>(
		useClassNameBuilderFactory().Create().AddClass(styles.contact_table_label_container).GetClassNames()
	);

	const [segmentBuilderClassNames, setSegmentBuilderClassNames] = useState<string>(
		useClassNameBuilderFactory().Create().AddClass(styles.segment_builder_container).GetClassNames()
	);

	const setSegmentViewContactCountValues = (rowCount: RowCount, segmentId?: string, updatedSegmentName?: string) => {
		const count = rowCount.count;
		setSegmentViewContactCount(count);
		dispatch(setCachedSegmentViewContactCount(count));

		if (StringHelper.IsNullOrEmpty(segmentId) || StringHelper.IsNullOrEmpty(updatedSegmentName)) return;

		segmentsApiService.UpdateSegmentComment({
			segmentId: segmentId!,
			comment: `${updatedSegmentName}${count}`,
		});
	};

	const updateSegmentViewContactCount = (where: string, segmentId?: string, updatedSegmentName?: string) => {
		if (where.includes(`${SegmentHelper.ActivityTablePrefix}.`)) {
			segmentsApiService.GetJoinedRowCount({ where: StringHelper.ValueOrEmpty(where) }).then((response) => {
				setSegmentViewContactCountValues(response, segmentId, updatedSegmentName);
			});
		} else if (where.includes(`${SegmentHelper.ContactTablePrefix}.`)) {
			segmentsApiService.GetContactRowCount({ where: StringHelper.ValueOrEmpty(where) }).then((response) => {
				setSegmentViewContactCountValues(response, segmentId, updatedSegmentName);
			});
		}
	};

	const loadTotalContactCount = () => {
		if (cachedTotalContactCount > 0) return;

		contactsApiService.GetTotalCount().then((response) => {
			dispatch(setCachedTotalContactCount(response.count));
		});
	};

	const loadColumnNames = () => {
		if (!ArrayHelper.HasElements(cachedActivityColumnNames)) {
			referenceValuesApiService.GetActivityColumnNames().then((response) => {
				dispatch(setCachedActivityColumnNames(response));
			});
		}

		if (!ArrayHelper.HasElements(cachedContactColumnNames)) {
			referenceValuesApiService.GetContactColumnNames().then((response) => {
				dispatch(setCachedContactColumnNames(response));
				setSelectedColumns(
					response
						.slice(0, 7)
						.map((columnName) => StringHelper.SnakeCaseToFriendlyString(columnName.columnName))
				);
			});
		}
	};

	const onChangeSegmentNameHandler: StringValueCallback = (newValue: string) => {
		setSegmentNameError(StringHelper.Empty);
		updateCurrentSegmentViewField('segmentName', newValue);
	};

	const onUpdateTestVariantPercentagesHandler: EventArgsCallback<string[]> = (percentageList: string[]) => {
		setShowTestVariantsModal(false);
		if (!ArrayHelper.HasElements(percentageList)) return;

		updateCurrentSegmentViewField('testVariantPercentages', percentageList);
	};

	const onSaveSegmentHandler = (orGroups: string[] = currentOrGroups, modifiedFilter?: ModifiedFilter) => {
		if (modifiedFilter) setCurrentModifiedFilter(modifiedFilter);

		const isNewSegment = !state;
		const modifiedFilterToUse = modifiedFilter ? modifiedFilter : currentModifiedFilter;
		const orGroupsToUse = ArrayHelper.HasElements(orGroups) ? orGroups : cachedOrGroups;
		const orGroup = orGroupsToUse.map((orGroup) => orGroup.replace('&&', 'AND')).join(' OR ');

		setWhere(orGroup);

		dispatch(setCachedSegmentViewContactCount(0));
		if (modifiedFilterToUse && StringHelper.IsNotNullOrEmpty(modifiedFilterToUse.segmentName)) {
			setSegmentNameError(StringHelper.Empty);
			updateCurrentSegmentViewField('segmentName', modifiedFilterToUse.segmentName);
		}

		if (!isNewSegment && !currentSegmentView?.segmentName && !modifiedFilterToUse) {
			setSegmentNameError('Please enter a Segment Name');
			return;
		}

		if (ArrayHelper.HasElements(cachedSegmentViews) && viewIndex !== -1) {
			const whereIndex = cachedSegmentViews[viewIndex].text.indexOf('WHERE');
			const textExcludingWhere = cachedSegmentViews[viewIndex].text.substring(0, whereIndex);
			const updatedText = `${textExcludingWhere.trim()} WHERE ${orGroups.join(' OR ')}`;

			const updatedSegmentViews = ObjectHelper.DeepCopy(cachedSegmentViews);
			updatedSegmentViews![viewIndex].text = updatedText;

			dispatch(setCachedSegmentViews(updatedSegmentViews!));
		}
		const updatedSegmentName = SegmentHelper.ConstructSegmentName(currentSegmentView, modifiedFilterToUse);

		const createSegmentRequest: CreateSegmentRequest = {
			segmentId: StringHelper.ValueOrDefault(currentSegmentView?.segmentId, state?.segmentId),
			segmentName: `${updatedSegmentName}${cachedSegmentViewContactCount}`,
			tableName: 'CONTACT_PROPERTY',
			where: 'WHERE ' + orGroup,
		};

		setIsLoading(true);
		segmentsApiService.CreateReplaceContactSegmentView(createSegmentRequest).then((response) => {
			updateCurrentSegmentViewField('segmentId', response.segmentId);
			setSegmentName(updatedSegmentName.split('|')[0]);

			updateSegmentViewContactCount(orGroup, response.segmentId, updatedSegmentName);
			setIsLoading(false);
		});
	};

	const onConfirmSelectedColumnNamesHandler = (selectedColumns: string[]) => {
		setSelectedColumns(selectedColumns);
	};

	const onToggleContactsTableHandler = () => {
		if (showContactsTable) {
			setContactTableClassNames(contactTableClassNames.split(StringHelper.WhiteSpace)[0]);
			setSegmentBuilderClassNames(segmentBuilderClassNames.split(StringHelper.WhiteSpace)[0]);
		} else {
			setContactTableClassNames(`${contactTableClassNames} ${styles.show_table}`);
			setSegmentBuilderClassNames(`${segmentBuilderClassNames} ${styles.show_table}`);
		}
		setShowContactsTable(!showContactsTable);
	};

	const onSegmentTypeSelectedHandler = (option: string) => {
		updateCurrentSegmentViewField('segmentType', option);
	};

	const updateCurrentSegmentViewField = (fieldName: string, newValue: any) => {
		let updatedSegmentView = ObjectHelper.DeepCopy(currentSegmentView);
		if (!updatedSegmentView) return;

		updatedSegmentView[fieldName] = newValue;

		setCurrentSegmentView(updatedSegmentView!);
	};

	const onExportHandler = () => {
		setIsExporting(true);

		const selectedColumns = ArrayHelper.HasElements(cachedSelectedContactColumnNames)
			? cachedSelectedContactColumnNames.map((contactColumnName) =>
					StringHelper.ToSnakeCase(contactColumnName, true)
			  )
			: cachedContactColumnNames.slice(0, 7).map((contactColumnName: any) => contactColumnName.columnName);

		contactsApiService
			.ExportContacts({ selectedColumns: selectedColumns, where: where, limit: -1, offset: -1 })
			.then(
				(response) => {
					const url = window.URL.createObjectURL(new Blob([response]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${currentSegmentView.segmentId}-${DateHelper.GetISODateTime()}.csv`);
					document.body.appendChild(link);
					link.click();

					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
					setIsExporting(false);
				},
				(error) => {
					setIsExporting(false);
				}
			);
	};

	useEffect(() => {
		loadColumnNames();
		loadTotalContactCount();

		if (!state) {
			dispatch(setCachedOrGroups([]));
			setIsLoading(false);
			return;
		}

		let text = state.text;
		if (!ArrayHelper.HasElements(cachedSegmentViews)) {
			segmentsApiService.GetSegmentViewById(state.segmentId).then((response) => {
				const segmentNameParts = response.segmentName.split('|');
				const testVariantPercentages = segmentNameParts.slice(1, 5);
				setSegmentName(segmentNameParts[0]);

				response.testVariantPercentages = testVariantPercentages;
				setCurrentSegmentView(response);
			});
		} else {
			const viewIndex = cachedSegmentViews.findIndex((x) => x.segmentId === state.segmentId);
			text = cachedSegmentViews[viewIndex].text;

			setViewIndex(viewIndex);

			setCurrentSegmentView(cachedSegmentViews.find((segmentView) => segmentView.segmentId === state.segmentId)!);
		}

		const regEx = /^[a-zA-Z\s_]*\.(?:[A-Z]*)\.([a-zA-Z_\d]*).*WHERE\s(.*\))/g;
		const match = regEx.exec(text) || [];
		if (match.length > 0) {
			const where = match[2];
			setWhere(where);
			updateSegmentViewContactCount(where);
			const orGroups = where.split(') OR (').map((value: string) => {
				const openingBracketCount = (value.match(new RegExp('\\(', 'g')) || []).length;
				const closingBracketCount = (value.match(new RegExp('\\)', 'g')) || []).length;
				return openingBracketCount > closingBracketCount
					? `${value.trim()})`
					: closingBracketCount > openingBracketCount
					? `(${value.trim()}`
					: value.trim();
			});
			setCurrentOrGroups(orGroups);
			dispatch(setCachedOrGroups(orGroups));
		}
	}, []);

	return (
		<div>
			{showTestVariantsModal && (
				<TestVariantsModal
					segmentView={currentSegmentView}
					testVariantPercentages={currentSegmentView?.testVariantPercentages}
					onCloseClick={onUpdateTestVariantPercentagesHandler}
				/>
			)}
			{showSelectColumnsModal && (
				<SelectColumnsModal
					onCloseClick={() => setShowSelectColumnsModal(false)}
					onConfirmSelectedColumnNames={onConfirmSelectedColumnNamesHandler}
				/>
			)}
			<div className={styles.segment_builder}>
				<Container className={styles.segments_header_container} horizontal fullWidth center>
					<Container className={styles.segments_header_left} childSpace horizontal fullWidth>
						<SegmentName
							segmentName={StringHelper.ValueOrDefault(segmentName, currentSegmentView?.segmentName)}
							segmentNameError={segmentNameError}
							onChangeSegmentName={onChangeSegmentNameHandler}
						/>
						<Container centerFull>
							<DualButton
								optionOne={SegmentType.Dynamic}
								optionTwo={SegmentType.Static}
								selectedOption={StringHelper.ValueOrDefault(
									currentSegmentView?.segmentType,
									SegmentType.Dynamic
								)}
								onOptionSelected={onSegmentTypeSelectedHandler}
							/>
						</Container>
						<Container centerFull>
							<ActionButton secondary onClick={() => setShowTestVariantsModal(true)}>
								Add AB Variants
							</ActionButton>
						</Container>
					</Container>
					<Container className={styles.segments_header_right} childSpace horizontal fullWidth right>
						<ActionButton secondary isLoading={isLoading} onClick={() => onSaveSegmentHandler([])}>
							Save Segment
						</ActionButton>
					</Container>
				</Container>
				<div className={segmentBuilderClassNames}>
					<Container
						className={styles.segment_filter_groups_header_title}
						horizontal
						fullWidth
						childSpaceLarge
					>
						<Container fullWidth bottomLeft>
							<Container>
								<Label bold>SEGMENT OVERVIEW</Label>
							</Container>
						</Container>
						<Container fullWidth bottomLeft className={styles.second_column}>
							<Container fullWidth childSpace>
								<Container>
									<Label bold>SEGMENT SIZE</Label>
								</Container>
							</Container>
						</Container>
						<Container className={`${styles.third_column} ${styles.median_value_selections}`}>
							<MedianValueColumnSelections />
						</Container>
					</Container>
					<Container
						className={styles.segment_filter_groups_header_content}
						horizontal
						fullWidth
						childSpaceLarge
					>
						<Container fullWidth bottomLeft>
							<Container></Container>
						</Container>
						<Container fullWidth bottomLeft className={styles.second_column}>
							<Container fullWidth childSpace>
								<Container>
									<SegmentFilterPercentageBar
										where={where}
										isPrimary
										isNewSegment={!state}
										numerator={segmentViewContactCount}
										denominator={cachedTotalContactCount}
									/>
								</Container>
							</Container>
						</Container>
						<Container className={styles.third_column}>
							<MedianValueResults where={where} isNewSegment={!state} />
						</Container>
					</Container>
					<Container className={styles.segment_filter_groups}>
						<Separator noTopSpace />
						<SegmentOrGroupTemplate
							orGroups={currentOrGroups}
							segmentName={StringHelper.ValueOrDefault(
								currentSegmentView?.segmentName,
								currentModifiedFilter?.segmentName
							)}
							onSave={onSaveSegmentHandler}
						/>
					</Container>
				</div>

				{!showContactsTable && (
					<Container fullWidth>
						<ActionButton secondary onClick={onToggleContactsTableHandler}>
							Show Contact List
						</ActionButton>
					</Container>
				)}

				<div className={contactTableClassNames}>
					<Container horizontal fullWidth centerFull>
						<Container className={styles.left_container} centerFull horizontal fullWidth childSpace>
							<Container leftSpace horizontal fullWidth>
								<ActionButton secondary onClick={onToggleContactsTableHandler}>
									Hide Contacts
								</ActionButton>
							</Container>
						</Container>
						<Container className={styles.right_container} horizontal fullWidth right childSpace rightSpace>
							<ActionButton secondary onClick={() => setShowSelectColumnsModal(true)}>
								Select Columns
							</ActionButton>
							<ActionButton secondary onClick={onExportHandler} isLoading={isExporting}>
								Export
							</ActionButton>
						</Container>
					</Container>
					<div className={styles.contact_table_container}>
						{isLoading && <CXDataLoader />}
						{!isLoading && StringHelper.IsNotNullOrEmpty(where) && (
							<ContactsTableTemplate selectedColumns={selectedColumns} where={where} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SegmentBuilder;
