import styles from './NavigationMenu.module.scss';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page } from '../../../../common/enums/page-enums';
import { PrimaryRoutes } from '../../../../common/enums/internal-route-enums';
import { BiSolidContact, BiSolidMegaphone } from 'react-icons/bi';
import { RiBarChartBoxFill, RiDashboardFill } from 'react-icons/ri';
import { INavigationMenuItem } from '../../../../common/models/navigation';
import { MdPeopleAlt } from 'react-icons/md';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { IoMdSettings } from 'react-icons/io';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { Container } from '../Container/Container';
import Separator from '../Separator/Seperator';
import HelpModal from '../../../organisms/HelpModal/HelpModal';

const iconSize = 15;

const navigationMenuItems: INavigationMenuItem[] = [
	{
		icon: <RiDashboardFill key={'home'} size={iconSize} />,
		description: 'Dashboard',
		routes: [PrimaryRoutes.Home],
		page: Page.Home,
	},
	{
		icon: <RiBarChartBoxFill key={'data-insights'} size={iconSize} />,
		description: 'Data & Insights',
		routes: [PrimaryRoutes.DataInsights],
		page: Page.DataInsights,
	},
	{
		icon: <BiSolidMegaphone key={'campaigns'} size={iconSize} />,
		description: 'Campaign Ideas',
		routes: [PrimaryRoutes.Campaigns],
		page: Page.Campaigns,
		hidden: true,
	},
	{
		icon: <MdPeopleAlt key={'segments'} size={iconSize} />,
		description: 'Segments',
		routes: [PrimaryRoutes.Segments],
		page: Page.Segments,
	},
	{
		icon: <BiSolidContact key={'contacts'} size={iconSize} />,
		description: 'Contacts 360',
		routes: [PrimaryRoutes.Contacts],
		page: Page.Contacts,
	},
	{
		isSeparator: true,
	},
	{
		icon: <IoMdSettings key={'settings'} size={iconSize} />,
		description: 'Settings',
		routes: [PrimaryRoutes.Settings],
		page: Page.Settings,
		hidden: true,
	},
	{
		icon: <HiQuestionMarkCircle key={'help'} size={iconSize} />,
		description: 'Help',
		routes: [PrimaryRoutes.Help],
		page: Page.Help,
		showModal: true,
	},
];

const NavigationMenu = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

	const onNavigationHandler = (page: Page) => {
		switch (page) {
			case Page.Home:
				navigate(PrimaryRoutes.Home);
				break;
			case Page.Contacts:
				navigate(PrimaryRoutes.Contacts);
				break;
			case Page.Segments:
				navigate(PrimaryRoutes.Segments);
				break;
			case Page.Campaigns:
				navigate(PrimaryRoutes.Campaigns);
				break;
			case Page.DataInsights:
				navigate(PrimaryRoutes.DataInsights);
				break;
			case Page.Settings:
				navigate(PrimaryRoutes.Settings);
				break;
		}
	};

	const getClassName = (routes: PrimaryRoutes[], isSeparator: boolean = false) => {
		let isSelected = false;
		if (routes[0] === PrimaryRoutes.Home && location.pathname === PrimaryRoutes.Home) isSelected = true;
		else {
			routes.forEach((route) => {
				if (route !== PrimaryRoutes.Home && location.pathname.startsWith(route.toString())) {
					isSelected = true;
					return;
				}
			});
		}

		return isSelected ? styles.selected : StringHelper.Empty;
	};

	const onCloseClickHandler = () => {
		setShowHelpModal(false);
	};

	return (
		<Container>
			{showHelpModal && <HelpModal onCloseClick={onCloseClickHandler} />}
			<ul className={styles.navigation_container}>
				{navigationMenuItems
					.filter((navigationMenuItem) => !navigationMenuItem.hidden)
					.map((navigationMenuItem, index) => {
						return navigationMenuItem.isSeparator ? (
							<li key={`navigation-item-${index}`} className={styles.separator}>
								<Container topSpace bottomSpace>
									<Separator />
								</Container>
							</li>
						) : (
							<li
								key={`navigation-item-${index}`}
								className={getClassName(navigationMenuItem.routes!)}
								onClick={() =>
									navigationMenuItem.showModal
										? setShowHelpModal(true)
										: onNavigationHandler(navigationMenuItem.page!)
								}
							>
								<Container fullWidth horizontal childSpace>
									<Container centerFull>{navigationMenuItem.icon}</Container>
									<Container>{navigationMenuItem.description}</Container>
								</Container>
							</li>
						);
					})}
			</ul>
		</Container>
	);
};

export default NavigationMenu;
