import styles from './MedianValueColumnSelections.module.scss';
import React, { useEffect, useState } from 'react';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import { StringHelper } from '../../../common/helpers/string-helper';
import { useAppSelector } from '../../../redux/hooks';
import { Callback } from '../../../types/Callbacks';
import { Container } from '../../atoms/Base/Container/Container';
import SelectMedianValueColumnsModal from '../SelectMedianValueColumnsModal/SelectMedianValueColumnsModal';
import Label from '../../atoms/Base/Label/Label';
import { FieldType } from '../../../common/enums/field-type-enums';
import { useReferenceValuesApiService } from '../../../api/providers/AppServiceProvider';
import { ReferenceValuesHelper } from '../../../common/helpers/reference-values-helper';

const MedianValueSelections = () => {
	const referenceValuesApiService = useReferenceValuesApiService();

	const cachedContactColumnNames = useAppSelector((state) => state.contacts.columnNames);
	const cachedSelectedMedianColumns = useAppSelector((state) => state.medianValues.selectedMedianColumns);

	const [defaultMedianColumns, setDefaultMedianColumns] = useState<string[]>(cachedSelectedMedianColumns);
	const [selectedMedianColumn, setSelectedMedianColumn] = useState<string>(StringHelper.Empty);
	const [selectedMedianColumnIndex, setSelectedMedianColumnIndex] = useState<number>(0);
	const [showMedianValuesModal, setShowMedianValuesModal] = useState<boolean>(false);

	const medianColumns = cachedContactColumnNames
		.filter((contactColumnName) => {
			return StringHelper.Capitalize(contactColumnName.dataType) === FieldType.Number;
		})
		.map((contactColumnName) => contactColumnName.columnName);

	useEffect(() => {
		if (!ArrayHelper.HasElements(cachedSelectedMedianColumns)) {
			referenceValuesApiService.GetContactColumnNames().then((response) => {
				const medianColumns = ReferenceValuesHelper.GetColumnsByType(response, FieldType.Number, 3);
				setDefaultMedianColumns(medianColumns);
			});
		} else {
			setDefaultMedianColumns(
				cachedSelectedMedianColumns.map((medianColumn) => StringHelper.SnakeCaseToFriendlyString(medianColumn))
			);
		}
	}, [cachedSelectedMedianColumns]);

	const onCloseClickHandler: Callback = () => {
		setShowMedianValuesModal(false);
	};

	const onShowMedianValuesModalHandler = (medianColumn: string, index: number) => {
		setSelectedMedianColumn(medianColumn);
		setSelectedMedianColumnIndex(index);
		setShowMedianValuesModal(true);
	};

	return (
		<Container fullWidth centerFull>
			{showMedianValuesModal && (
				<SelectMedianValueColumnsModal
					medianColumns={medianColumns}
					selectedMedianColumn={selectedMedianColumn}
					selectedMedianColumnIndex={selectedMedianColumnIndex}
					onCloseClick={onCloseClickHandler}
				/>
			)}
			<Container childSpace>
				<Container className={styles.median_value_selections_container} horizontal childSpaceLarge>
					{ArrayHelper.HasElements(defaultMedianColumns) &&
						defaultMedianColumns.map((medianColumn, index) => {
							return (
								<Label
									tooltip={medianColumn.toUpperCase()}
									key={index}
									clickable
									smallFixedWidth
									underline
									onClick={() => onShowMedianValuesModalHandler(medianColumn, index)}
								>
									{StringHelper.Truncate(medianColumn, 7, true)}
								</Label>
							);
						})}

					<Label tooltip={'INSIGHTS'} clickable smallFixedWidth underline>
						{StringHelper.Truncate('INSIGHTS', 7, true)}
					</Label>
				</Container>
			</Container>
		</Container>
	);
};

export default MedianValueSelections;
