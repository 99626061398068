import { FieldType } from '../enums/field-type-enums';
import { FilterType } from '../enums/segment-enums';
import { StringHelper } from '../helpers/string-helper';

/* eslint-disable no-unused-vars */
export interface IQueryBuilder {
	addAnd(): IQueryBuilder;
	addNot(): IQueryBuilder;
	addQueryPart(queryPart?: string, columnType?: FieldType): IQueryBuilder;
	getQuery(): string;
}

export class QueryBuilder implements IQueryBuilder {
	private queryParts: string[] = [];

	public addAnd(): IQueryBuilder {
		this.queryParts.push(FilterType.And);

		return this;
	}

	public addNot(): IQueryBuilder {
		this.queryParts.push('NOT');

		return this;
	}

	public addQueryPart(queryPart?: string, columnType?: FieldType): IQueryBuilder {
		const dateSuffix = StringHelper.Empty;

		if (queryPart) {
			const updatedQueryPart =
				columnType === FieldType.Date && !queryPart.startsWith(StringHelper.SingleQuote)
					? `'${queryPart?.trim()}'`
					: queryPart?.trim();
			this.queryParts.push(
				`${columnType === FieldType.Date ? `${updatedQueryPart}` : `${updatedQueryPart}${dateSuffix}`}`
			);
		}

		return this;
	}

	public getQuery(): string {
		return `(${this.queryParts.join(StringHelper.WhiteSpace)})`;
	}
}
