export class StringHelper {
	public static IsNotNullOrEmpty(source: string | null | undefined): boolean {
		return source != null && source !== undefined && source?.toString().trim().length > 0;
	}

	public static IsNullOrEmpty(source: string | null | undefined): boolean {
		return !this.IsNotNullOrEmpty(source);
	}

	public static get Empty(): string {
		return '';
	}

	public static get WhiteSpace(): string {
		return ' ';
	}

	public static get SingleQuote(): string {
		return "'";
	}

	public static get DoubleSingleQuote(): string {
		return "''";
	}

	public static ValueOrDefault(source: string | null | undefined, defaultValue: string = this.Empty): string {
		if (source != null && source !== undefined && source?.toString().length > 0) return source;

		return defaultValue;
	}

	public static ValueOrEmpty(source: string | null | undefined): string {
		return this.ValueOrDefault(source, this.Empty);
	}

	public static Capitalize(source?: string, preserve: boolean = false): string {
		if (!source) return this.Empty;

		const remainingSource = preserve ? source.slice(1) : source.slice(1).toLowerCase();
		return source.charAt(0).toUpperCase() + remainingSource;
	}

	public static ToCamelCase(source: string): string {
		return (
			source.charAt(0).toLowerCase() +
			source.slice(1, source.length - 1).replaceAll(StringHelper.WhiteSpace, StringHelper.Empty) +
			source.slice(source.length - 1, source.length).toLowerCase()
		);
	}

	public static ToSnakeCase(source: string, uppercase: boolean = true): string {
		const snakeCaseString = source.replaceAll(this.WhiteSpace, '_');
		return uppercase ? snakeCaseString.toUpperCase() : snakeCaseString;
	}

	public static CamelCaseToFriendlyString(source: string, maxLength?: number): string {
		if (this.IsNullOrEmpty(source)) return source;

		let readableString = StringHelper.Capitalize(source.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2'), true);
		if (!maxLength) return readableString;

		return this.Truncate(source, maxLength);
	}

	public static SanitiseString(source: string, valuesToReplace: string[], replaceValue: string): string {
		valuesToReplace.forEach((valueToReplace) => {
			source = source.replaceAll(valueToReplace, replaceValue);
		});
		return source;
	}

	public static SnakeCaseToFriendlyString(source: string, maxLength: number = 0): string {
		if (source.includes('-')) return source;

		const textItems = source
			.replace(/([a-zA-Z0-9])(?:[_])/g, '$1 ')
			.split(StringHelper.WhiteSpace)
			.map((textItem: string) => this.Capitalize(textItem));
		const updatedString = textItems.join(StringHelper.WhiteSpace);
		return maxLength > 0 ? this.Truncate(updatedString, maxLength) : updatedString;
	}

	public static Truncate(source: string, maxLength: number, uppercase: boolean = false): string {
		if (!source) return source;

		const showEllipsis = maxLength < source.length;
		const truncatedString = source.slice(0, maxLength < source.length ? maxLength : source.length);
		const capitalizedString = this.Capitalize(`${truncatedString}${showEllipsis ? '...' : StringHelper.Empty}`);
		return uppercase ? capitalizedString.toUpperCase() : capitalizedString;
	}
}
