/* eslint-disable no-unused-vars */
import styles from './SegmentOrGroup.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { FilterType, SegmentRecordType } from '../../../../common/enums/segment-enums';
import AndOr from '../../../atoms/Custom/AndOr/AndOr';
import { ColumnFilterItem } from '../../../../common/models/column-filter';
import { COLUMN_FILTER_OPTIONS_MAP } from '../../../../common/constants/column-filter-enums';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setCachedOrGroups } from '../../../../features/segments-slice';
import { SegmentHelper } from '../../../../common/helpers/segment-helper';
import { ModifiedFilter, SelectedFilter } from '../../../../common/models/filter';
import { StringHelper } from '../../../../common/helpers/string-helper';
import { useQueryBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import SegmentFilterSelections from '../SegmentFilterSelections/SegmentFilterSelections';
import SegmentFilterRecordType from '../SegmentFilterRecordType/SegmentFilterRecordType';
import { ArrayHelper } from '../../../../common/helpers/array-helper';
import { Container } from '../../../atoms/Base/Container/Container';

interface ISegmentOrGroupProps {
	displayFirstAndOption?: boolean;
	orGroup: string;
	orGroupIndex: number;
	modifiedFilter?: ModifiedFilter;
	onShowFilterOptions?: (selectedFilterOption: SelectedFilter) => void;
	onSave?: (orGroups: string[], modifiedFilter?: ModifiedFilter) => void;
}

const SegmentOrGroup: FC<ISegmentOrGroupProps> = ({
	displayFirstAndOption = false,
	orGroup,
	orGroupIndex,
	modifiedFilter,
	onShowFilterOptions,
	onSave,
}) => {
	const dispatch = useAppDispatch();
	const cachedOrGroups: string[] = useAppSelector((state) => state.segments.orGroups);

	const queryBuilderFactory = useQueryBuilderFactory();

	const [columnFilterItems, setColumnFilterItems] = useState<ColumnFilterItem[]>([]);
	const [groupIndex] = useState<number>(orGroupIndex);

	useEffect(() => {
		let currentOrGroup = orGroup;

		// Get the current filter group from redux as it will be the up to date version
		if (modifiedFilter?.orGroupIndex === groupIndex && groupIndex < cachedOrGroups.length) {
			currentOrGroup = cachedOrGroups[groupIndex];
		}

		// Adding the first filter for a not yet saved segment view
		if (modifiedFilter && currentOrGroup === SegmentHelper.EmptyOrGroup) {
			const columnFilterItems = SegmentHelper.ConvertToFilterItems(`(${modifiedFilter.filterString})`);
			updateColumnFilterItemsState(columnFilterItems);
			return;
		}

		// Updating an existing filter
		if (!modifiedFilter?.isNewFilter && modifiedFilter?.orGroupIndex === groupIndex) {
			const columnFilterItems = SegmentHelper.ConvertToFilterItems(currentOrGroup);

			columnFilterItems[modifiedFilter.filterIndex] = SegmentHelper.ConvertToFilterItem(
				modifiedFilter.filterString,
				modifiedFilter.filterIndex
			);

			updateColumnFilterItemsState(columnFilterItems);
			return;
		}

		if (modifiedFilter?.isNewFilter && modifiedFilter?.orGroupIndex === groupIndex) {
			currentOrGroup = `${currentOrGroup} AND (${modifiedFilter.filterString})`;
		}

		const columnFilterItems = SegmentHelper.ConvertToFilterItems(currentOrGroup);
		setColumnFilterItems(columnFilterItems);

		// If the filter group is empty or the current group index is not equal to the modified filter's group index, don't save to redux state
		if (modifiedFilter?.orGroupIndex !== groupIndex) {
			return;
		}

		const updatedOrGroups = updateCachedOrGroups(columnFilterItems);
		if (onSave) onSave(updatedOrGroups, modifiedFilter);
	}, [modifiedFilter]);

	const updateColumnFilterItemsState = (columnFilterItems: ColumnFilterItem[]) => {
		setColumnFilterItems(columnFilterItems);
		const updatedOrGroups = updateCachedOrGroups(columnFilterItems);

		if (onSave) onSave(updatedOrGroups, modifiedFilter);
	};

	const onClickRecordTypeHandler = (id: string, recordType: SegmentRecordType) => {
		const noneMatchingColumnFilterItems = [...columnFilterItems.filter((filter) => filter.id !== id)];
		let matchingColumnFilterItem = [...columnFilterItems].find((filter) => filter.id === id);

		matchingColumnFilterItem!.recordType = recordType;
		matchingColumnFilterItem!.tablePrefix = recordType.substring(0, 3).toLowerCase();
		setColumnFilterItems([...noneMatchingColumnFilterItems, matchingColumnFilterItem!]);
	};

	const onShowFilterOptionsHandler = (
		columnFilterItem: ColumnFilterItem,
		filterIndex: number,
		isNewFilter: boolean = false
	) => {
		const fullColumnName = `${columnFilterItem.tablePrefix}.${columnFilterItem.columnName}`;
		const columnFilterOptions = COLUMN_FILTER_OPTIONS_MAP.get(fullColumnName)!;
		const selectedFilter: SelectedFilter = {
			filterIndex: filterIndex,
			orGroupIndex: groupIndex,
			columnFilterItem: columnFilterItem,
			columnFilterOptions: columnFilterOptions,
			isNewFilter: isNewFilter,
		};
		if (onShowFilterOptions) onShowFilterOptions(selectedFilter);
	};

	const updateCachedOrGroups = (columnFilterItems: ColumnFilterItem[]) => {
		const orGroup = SegmentHelper.ConstructQueryFromFilterItems(columnFilterItems, queryBuilderFactory);

		const updatedOrGroups = [...cachedOrGroups];
		if (!ArrayHelper.HasElements(updatedOrGroups) || groupIndex > updatedOrGroups.length - 1) {
			updatedOrGroups.push(orGroup);
		} else {
			updatedOrGroups[groupIndex] = orGroup;
		}
		dispatch(setCachedOrGroups(updatedOrGroups));
		return updatedOrGroups;
	};

	const onAddColumnFilterItemHandler = () => {
		const hasUnassignedFilter =
			columnFilterItems.find((columnFilterItem) => StringHelper.IsNullOrEmpty(columnFilterItem.columnName)) !==
			undefined;

		// If an unassigned filter exists, break out of the function as we don't want multiple of these
		if (hasUnassignedFilter) return;

		setColumnFilterItems([
			...columnFilterItems,
			{
				id: `${columnFilterItems.length}`,
				tablePrefix: SegmentHelper.ContactTablePrefix,
				recordType: SegmentRecordType.Contact,
			},
		]);
	};

	const onRemoveColumnFilterItemHandler = (filterIndex: number) => {
		const updatedColumnFilterItems = columnFilterItems.filter((_, index) => index !== filterIndex);

		if (updatedColumnFilterItems.length === 0) {
			updatedColumnFilterItems.push({
				id: '0',
				columnName: StringHelper.Empty,
				tablePrefix: SegmentHelper.ContactTablePrefix,
				recordType: SegmentRecordType.Contact,
			});
		}

		setColumnFilterItems(updatedColumnFilterItems);
		const updatedOrGroups = updateCachedOrGroups(updatedColumnFilterItems);

		if (onSave) onSave(updatedOrGroups);
	};

	const filterItemsCount = columnFilterItems.length - 1;

	return (
		<div>
			{columnFilterItems.map((columnFilterItem, index) => {
				return (
					<Container key={`filter-${index}`} fullWidth horizontal>
						<Container>
							{index === 0 && displayFirstAndOption && (
								<AndOr
									filterType={FilterType.And}
									itemIndex={0}
									isFirstItem
									onClick={onRemoveColumnFilterItemHandler}
								/>
							)}
						</Container>
						<Container className={index === 0 ? styles.first_filter_group : StringHelper.Empty}>
							{index < filterItemsCount && (
								<AndOr
									filterType={FilterType.And}
									itemIndex={index + 1}
									isFirstItem={index === 0}
									onClick={onRemoveColumnFilterItemHandler}
								/>
							)}
						</Container>
						<Container className={index === 0 ? styles.first_filter_group : StringHelper.Empty}>
							{index === filterItemsCount && (
								<AndOr
									filterType={FilterType.And}
									isFirstItem={index === 0}
									isPlus
									itemIndex={index}
									onClick={onAddColumnFilterItemHandler}
								/>
							)}
						</Container>
						<Container fullWidth>
							<Container fullWidth horizontal childSpace>
								<Container fullWidth className={styles.segment_filter_record_type_container}>
									<SegmentFilterRecordType
										columnFilterItem={columnFilterItem}
										onClickRecordType={onClickRecordTypeHandler}
									/>
								</Container>
								{!columnFilterItem.columnName && (
									<Container
										fullWidth
										className={styles.segment_applied_filter_container}
										onClick={() => onShowFilterOptionsHandler(columnFilterItem, index, true)}
									>
										<span>Select Column</span>
									</Container>
								)}
								{columnFilterItem.columnName && (
									<Container
										fullWidth
										className={styles.segment_applied_filter_container}
										onClick={() => onShowFilterOptionsHandler(columnFilterItem, index)}
									>
										<span>
											{StringHelper.SnakeCaseToFriendlyString(columnFilterItem.columnName, 16)}
										</span>
									</Container>
								)}
								{columnFilterItem.columnOperator ? (
									<Container
										fullWidth
										className={styles.segment_applied_filter_container}
										onClick={() => onShowFilterOptionsHandler(columnFilterItem, index)}
									>
										{StringHelper.Truncate(
											SegmentHelper.MapOptionToDescription(
												columnFilterItem.columnType!,
												columnFilterItem.columnOperator
											),
											16
										)}
									</Container>
								) : (
									<Container fullWidth></Container>
								)}
								{ArrayHelper.HasElements(columnFilterItem.columnValues) &&
								SegmentHelper.IsNotKnownOrUnknownOperator(columnFilterItem.columnOperator!) ? (
									<Container fullWidth>
										<SegmentFilterSelections
											canRemove={false}
											displayFirstItemOnly
											selections={columnFilterItem.columnValues!}
											onClick={() => onShowFilterOptionsHandler(columnFilterItem, index)}
										/>
									</Container>
								) : (
									<Container fullWidth></Container>
								)}
							</Container>
						</Container>
					</Container>
				);
			})}
		</div>
	);
};

export default SegmentOrGroup;
