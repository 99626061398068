import './Loader.scss';
import styles from './Loader.module.scss';
import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { Container } from '../Container/Container';

export interface ILoaderProps {
	animation?: 'border' | 'grow';
	centered?: boolean;
	childSpace?: boolean;
	count?: number;
	message?: string;
	simple?: boolean;
	size?: 'sm' | any;
}

const Loader: FC<ILoaderProps> = ({
	animation = 'border',
	centered = false,
	childSpace,
	count = 1,
	message,
	simple,
	size,
}) => {
	const classNames = useClassNameBuilderFactory()
		.Create()
		.AddConditionalClass(centered && !simple, styles.loader_container_centered)
		.AddConditionalClass(!centered && !simple, styles.loader_container)
		.AddConditionalClass(simple, styles.simple)
		.GetClassNames();

	const getSpinners = () => {
		const spinnerArr = [];
		for (let i = 0; i < count; i++) {
			spinnerArr.push(<Spinner key={`spinner-${i}`} animation={animation} role={'status'} size={size}></Spinner>);
		}
		return spinnerArr;
	};

	return (
		<div className={classNames}>
			<Container childSpace={childSpace} horizontal>
				{getSpinners()}
			</Container>
			<label>{message}</label>
		</div>
	);
};

export default Loader;
