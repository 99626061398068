import styles from './Home.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from 'redux/hooks';
import { usePowerBIApiService } from 'api/providers/AppServiceProvider';
import { setPowerBIResponse } from 'features/powerbi-slice';
import PowerBIEmbedded from 'components/molecules/Custom/PowerBI/PowerBIEmbedded';
import CXDataLoader from '../../atoms/Custom/CXDataLoader/CXDataLoader';
import { Container } from '../../atoms/Base/Container/Container';
import { PowerBIResponse } from '../../../models/powerbi';

interface IHomeProps {
	isLoading?: boolean;
}

const Home: FC<IHomeProps> = ({ isLoading }) => {
	const { accounts } = useMsal();
	const dispatch = useAppDispatch();
	const powerBIApiService = usePowerBIApiService();

	const [isLoadingPowerBI, setIsLoadingPowerBI] = useState<boolean>(true);
	const [isPowerBILoading, setIsPowerBILoading] = useState<boolean>(false);
	const [isRefreshTokenLoading, setIsRefreshTokenLoading] = useState<boolean>(false);
	const [powerBIEmbedded, setPowerBiEmbedded] = useState<any>();
	const [accessToken, setAccessToken] = useState<string>();
	const [embedTokenExpiration, setEmbedTokenExpiration] = useState<string>();

	useEffect(() => {
		if (accounts != null && accounts.length > 0) {
			powerBIApiService.getPowerBIDashboardService().then(
				(response: PowerBIResponse) => {
					if (response != null) {
						dispatch(setPowerBIResponse(response));
						setPowerBiEmbedded(response);
						setAccessToken(response.embedToken);
						setEmbedTokenExpiration(response.embedTokenExpiration);
						setIsLoadingPowerBI(false);
						setIsPowerBILoading(true);
						monitorTokenExpiration(response.embedTokenExpiration);
					}
				},
				(error) => {
					console.log(error);
					setIsLoadingPowerBI(false);
				}
			);
		}
	}, []);

	useEffect(() => {
		const intervaldata = setInterval(() => {
			if (embedTokenExpiration != null && embedTokenExpiration != '')
				monitorTokenExpiration(embedTokenExpiration);
		}, 60000);
		return () => clearInterval(intervaldata);
	}, [accessToken, embedTokenExpiration]);

	const onHandleRenderedPowerBI = (isclose: boolean) => {
		setIsPowerBILoading(isclose);
	};

	const monitorTokenExpiration = (tokenExpiration: string): void => {
		var secondsToExpire = Math.floor((new Date(tokenExpiration).getTime() - new Date().getTime()) / 1000);
		var secondsBeforeExpirationForAutoRefresh = 6 * 60;
		if (secondsToExpire < secondsBeforeExpirationForAutoRefresh) {
			setEmbedTokenExpiration('');
			if (!isRefreshTokenLoading) {
				refreshEmbedToken();
			}
		}
	};

	const refreshEmbedToken = async () => {
		setIsRefreshTokenLoading(true);
		powerBIApiService.getEmbeddedToken('Dashboard').then(
			(response: PowerBIResponse) => {
				if (response != null) {
					setAccessToken(response.embedToken);
					setEmbedTokenExpiration(response.embedTokenExpiration);
					monitorTokenExpiration(response.embedTokenExpiration);
					setIsRefreshTokenLoading(false);
				}
			},
			(error) => {
				setIsRefreshTokenLoading(false);
				console.log(error);
			}
		);
	};

	return (
		<div>
			{isLoading && (
				<Container padding>
					<CXDataLoader message={'Signing in, please wait'} />
				</Container>
			)}

			{!isLoading && accounts.length === 0 && (
				<Container className={styles.welcome_container}>
					<div>
						<h3>Please Sign In</h3>
					</div>
				</Container>
			)}

			{!isLoading && isLoadingPowerBI && accounts.length > 0 && (
				<Container>
					<CXDataLoader />
				</Container>
			)}

			{!isLoading && !isLoadingPowerBI && (
				<Container>
					{powerBIEmbedded != null && accessToken != null && (
						<PowerBIEmbedded
							reportClass={styles.report_class}
							embedUrl={powerBIEmbedded.embeddedUrl}
							accessToken={accessToken}
							loading={isPowerBILoading}
							onRenderedPowerBI={onHandleRenderedPowerBI}
						></PowerBIEmbedded>
					)}
				</Container>
			)}
		</div>
	);
};

export default Home;
