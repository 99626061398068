import styles from './Logo.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '../Container/Container';
import { PrimaryRoutes } from '../../../../common/enums/internal-route-enums';

const Logo = () => {
	const navigate = useNavigate();

	return (
		<Container className={styles.logo_container} center>
			<img
				src={`/logos/${process.env.REACT_APP_LOGO}`}
				alt="image"
				draggable={false}
				onClick={() => navigate(PrimaryRoutes.Home)}
			/>
		</Container>
	);
};

export default Logo;
