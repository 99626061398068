import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';

declare global {
	interface Window {
		Beacon: any;
	}
}

const HelpScoutBeacon = () => {
	useEffect(() => {
		(function (e: any, t: any, n: any) {
			function a() {
				var e = t.getElementsByTagName('script')[0],
					n = t.createElement('script');
				n.type = 'text/javascript';
				n.async = true;
				n.src = 'https://beacon-v2.helpscout.net';
				e.parentNode.insertBefore(n, e);
			}
			if (
				((e.Beacon = n =
					function (t: any, n: any, a: any) {
						e.Beacon.readyQueue.push({ method: t, options: n, data: a });
					}),
				(n.readyQueue = []),
				'complete' === t.readyState)
			)
				return a();
			e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, false);
		})(window, document, window.Beacon || function () {});

		window.Beacon('init', '544eee61-29fd-4ebf-aa53-c5d46b972278');

		const secret = 'use-from-env-variable';
		const data = 'aj@cxdata.ai';

		// Create HMAC with SHA-256
		const signature = CryptoJS.HmacSHA256(data, secret).toString(CryptoJS.enc.Hex);

		// window.Beacon('identify', {
		// 	email: 'aj@cxdata.ai',
		// 	signature: signature,
		// });
	}, []);

	return <></>;
};

export default HelpScoutBeacon;
