import styles from './Layout.module.scss';

import React, { FC, ReactElement, ReactNode } from 'react';
import { IBaseComponentProps } from '../../../../types/IBaseComponentProps';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';

interface ILayoutProps extends IBaseComponentProps {
	twoColumnRightProminent?: boolean;
	twoColumnLeftProminent?: boolean;
	autoGrow?: boolean;
	space?: boolean;
	spacePlus?: boolean;
	children?: ReactNode;
}

export const Layout: FC<ILayoutProps> = ({
	twoColumnRightProminent,
	twoColumnLeftProminent,
	autoGrow,
	space,
	spacePlus,
	children,
}): ReactElement => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const layoutClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.layout)
		.AddConditionalClass(twoColumnRightProminent, styles.two_column_right_prominent)
		.AddConditionalClass(twoColumnLeftProminent, styles.two_column_left_prominent)
		.AddConditionalClass(autoGrow, styles.auto_grow)
		.AddConditionalClass(space, styles.space)
		.AddConditionalClass(spacePlus, styles.space_plus)
		.GetClassNames();

	return <div className={layoutClassNames}>{children}</div>;
};
