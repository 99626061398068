import React, { FC, useEffect, useState } from 'react';
import TextBox from '../../atoms/Base/TextBox/TextBox';
import ActionButton from '../../atoms/Base/ActionButton/ActionButton';
import { Modal } from '../../molecules/Base/Modal/Modal';
import { Container } from '../../atoms/Base/Container/Container';
import { EventArgsCallback, StringValueIndexCallback } from '../../../types/Callbacks';
import { SegmentView } from '../../../models/segments/segment-view';
import { ArrayHelper } from '../../../common/helpers/array-helper';
import Label from '../../atoms/Base/Label/Label';
import { StringHelper } from '../../../common/helpers/string-helper';

interface ITestVariantsModalProps {
	segmentView?: SegmentView;
	testVariantPercentages?: string[];
	onCloseClick?: EventArgsCallback<string[]>;
}

const TestVariantsModal: FC<ITestVariantsModalProps> = ({ segmentView, testVariantPercentages, onCloseClick }) => {
	const emptyArray = ArrayHelper.EmptyArray(4);
	const [percentageList, setPercentageList] = useState<string[]>(
		ArrayHelper.ValueOrDefault(segmentView?.testVariantPercentages, testVariantPercentages)
	);

	useEffect(() => {
		let percentagesToUse = emptyArray;
		if (!segmentView || !ArrayHelper.HasElements(testVariantPercentages)) return;

		if (segmentView && ArrayHelper.HasElements(segmentView.testVariantPercentages)) {
			percentagesToUse = ArrayHelper.ValueOrEmpty(segmentView.testVariantPercentages);
		}
		setPercentageList(percentagesToUse);
	}, [segmentView]);

	const getTotalPercentage = (percentageList: string[]) => {
		const mappedPercentageList = percentageList.map((percentage) => {
			return Number(StringHelper.ValueOrDefault(percentage, '0'));
		});

		let totalPercentage = 0;
		if (ArrayHelper.HasElements(mappedPercentageList)) {
			totalPercentage = mappedPercentageList.reduce(
				(accumulator: number, currentValue: number) => accumulator + currentValue
			);
		}

		return totalPercentage;
	};

	const onChangeHandler: StringValueIndexCallback = (newValue: string, index: number) => {
		const percentage = Number(newValue) > 100 ? '100' : newValue;

		let updatedPercentageList = [...percentageList];
		updatedPercentageList[index] = percentage;

		const totalPercentage = getTotalPercentage(updatedPercentageList);

		if (totalPercentage > 100) {
			for (let i = 0; i < percentageList.length; i++) {
				if (i !== index) updatedPercentageList[i] = StringHelper.Empty;
			}
		}

		setPercentageList(updatedPercentageList);
	};

	const onSaveTestVariantsHandler = () => {
		if (onCloseClick) onCloseClick(percentageList);
	};

	const getTextBox = (index: number) => {
		return (
			<TextBox
				type={'number'}
				placeHolder={'Enter percentage'}
				onChange={(newValue: string) => onChangeHandler(newValue, index)}
				defaultText={`${percentageList[index]}`}
			/>
		);
	};

	return (
		<Modal
			title={'ADD VARIANTS'}
			visible
			small
			onClose={() => {
				if (onCloseClick) onCloseClick([]);
			}}
		>
			<Container childSpace topSpace>
				{['A', 'B', 'C', 'D'].map((label: string, index: number) => {
					return (
						<Container horizontal childSpace key={index}>
							<Container centerFull>
								<Label>{label}:</Label>
							</Container>
							<Container fullWidth>{getTextBox(index)}</Container>
						</Container>
					);
				})}
			</Container>
			<Container center topSpaceLarge>
				<ActionButton secondary onClick={onSaveTestVariantsHandler}>
					Confirm
				</ActionButton>
			</Container>
		</Modal>
	);
};

export default TestVariantsModal;
