import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact } from '../models/contacts/contact';
import { ReferenceValueColumnName } from '../models/reference-value';

// Define a type for the slice state
interface ContactsState {
	value: Contact[];
	columnNames: ReferenceValueColumnName[];
	totalCount: number;
	selectedColumns: string[];
}

// Define the initial state using that type
const initialState: ContactsState = {
	value: [],
	columnNames: [],
	totalCount: 0,
	selectedColumns: [],
};

export const contactsSlice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		setCachedContactColumnNames: (state, action: PayloadAction<ReferenceValueColumnName[]>) => {
			state.columnNames = action.payload;
		},
		setCachedContacts: (state, action: PayloadAction<Contact[]>) => {
			state.value = action.payload;
		},
		setCachedTotalContactCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		setCachedSelectedContactColumnNames: (state, action: PayloadAction<string[]>) => {
			state.selectedColumns = action.payload;
		},
	},
});

export const {
	setCachedContactColumnNames,
	setCachedContacts,
	setCachedTotalContactCount,
	setCachedSelectedContactColumnNames,
} = contactsSlice.actions;

export default contactsSlice.reducer;
