import styles from './Modal.module.scss';

import React, { FC, Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { IBaseComponentProps } from '../../../../types/IBaseComponentProps';
import { Container } from '../../../atoms/Base/Container/Container';
import { VscChromeClose } from 'react-icons/vsc';
import { Button } from 'react-bootstrap';
import { Layout } from '../../../atoms/Base/Layout/Layout';
import { Callback } from '../../../../types/Callbacks';
import { useClassNameBuilderFactory } from '../../../../common/providers/ReactKitProvider';
import { StringHelper } from '../../../../common/helpers/string-helper';

interface IModalProps extends IBaseComponentProps {
	title?: string;
	icon?: ReactElement;
	visible?: boolean;
	allowClose?: boolean;
	prominent?: boolean;
	small?: boolean;
	offCenterTop?: boolean;
	onClose?: Callback;
	children?: ReactNode;
	scroll?: boolean;
}

export const Modal: FC<IModalProps> = ({
	title,
	icon,
	visible,
	allowClose = true,
	prominent,
	small,
	offCenterTop,
	scroll,
	children,
	onClose,
}): ReactElement | null => {
	const classNameBuilderFactory = useClassNameBuilderFactory();

	const modalClassNames = classNameBuilderFactory
		.Create()
		.AddClass(styles.modal)
		.AddConditionalClass(prominent, styles.prominent)
		.AddConditionalClass(!prominent, styles.standard)
		.AddConditionalClass(offCenterTop, styles.off_center_top)
		.AddConditionalClass(small, styles.small)
		.GetClassNames();

	const [modalVisibilityClass, setModalVisibilityClass] = useState<string>(styles.modal_container);
	const [overlayVisibilityClass, setOverlayVisibilityClass] = useState<string>(styles.modal_page_overlay);

	const handleClose = () => {
		if (allowClose) onClose && onClose();
	};

	const renderCloseButton = (size: number) => (
		<div className={styles.close_button_container}>
			<Button className={styles.close_button} onClick={handleClose}>
				<VscChromeClose size={size} />
			</Button>
		</div>
	);

	const renderHeaderContent = () => (
		<Fragment>
			{prominent && icon && icon}
			<div className={styles.title}>{title && title}</div>
			{allowClose && renderCloseButton(20)}
		</Fragment>
	);

	const renderHeader = (): ReactElement => (
		<div className={`${styles.modal_header} ${prominent ? styles.prominent : styles.standard}`}>
			{prominent && (
				<Container childSpace center>
					{renderHeaderContent()}
				</Container>
			)}
			{!prominent && (
				<Layout twoColumnRightProminent autoGrow>
					{renderHeaderContent()}
				</Layout>
			)}
		</div>
	);

	useEffect(() => {
		setOverlayVisibilityClass(`${overlayVisibilityClass} ${styles.visible}`);
		setModalVisibilityClass(`${modalVisibilityClass} ${styles.visible}`);
	}, []);

	return visible ? (
		<div>
			<div className={overlayVisibilityClass} onClick={handleClose}></div>

			<Container>
				<div className={modalVisibilityClass}>
					<div className={modalClassNames}>
						{renderHeader()}
						<Container
							padding={!prominent}
							prominentPadding={prominent}
							fullWidth
							className={scroll ? styles.modal_container_scroll : StringHelper.Empty}
						>
							{children}
						</Container>
					</div>
				</div>
			</Container>
		</div>
	) : null;
};
