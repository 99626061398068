import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MedianValueChartList } from '../models/median-values/median-value-chart-list';

// Define a type for the slice state
interface MedianValuesState {
	selectedMedianColumns: string[];
	medianValueCharts: MedianValueChartList[];
}

// Define the initial state using that type
const initialState: MedianValuesState = {
	selectedMedianColumns: [],
	medianValueCharts: [],
};

export const medianValuesSlice = createSlice({
	name: 'medianValues',
	initialState,
	reducers: {
		setCachedSelectedMedianColumns: (state, action: PayloadAction<string[]>) => {
			state.selectedMedianColumns = action.payload;
		},
		setCachedMedianValueCharts: (state, action: PayloadAction<MedianValueChartList[]>) => {
			state.medianValueCharts = action.payload;
		},
	},
});

export const { setCachedSelectedMedianColumns, setCachedMedianValueCharts } = medianValuesSlice.actions;

export default medianValuesSlice.reducer;
