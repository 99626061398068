import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/pages/Container/App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import HelpScoutBeacon from './components/molecules/Custom/HelpScoutBeacon/HelpScoutBeacon';
import { Container } from './components/atoms/Base/Container/Container';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') || new DocumentFragment());
root.render(
	<React.Fragment>
		<MsalProvider instance={msalInstance}>
			<App />
			<Container className={'help_scout_beacon'}>
				<HelpScoutBeacon />
			</Container>
		</MsalProvider>
	</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
