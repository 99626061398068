import styles from './SegmentOrGroupTemplate.module.scss';
import React, { FC, useEffect, useState } from 'react';
import SegmentOrGroup from '../../molecules/Custom/SegmentOrGroup/SegmentOrGroup';
import AndOr from '../../atoms/Custom/AndOr/AndOr';
import SegmentFilterOptionsModal from '../../organisms/SegmentFilterOptionsModal/SegmentFilterOptionsModal';
import { ModifiedFilter, SelectedFilter } from '../../../common/models/filter';
import { SegmentHelper } from '../../../common/helpers/segment-helper';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setCachedOrGroups } from '../../../features/segments-slice';
import SegmentFilterPercentageBar from '../../molecules/Custom/SegmentFilterPercentageBar/SegmentFilterPercentageBar';
import { FilterType } from '../../../common/enums/segment-enums';
import { Container } from '../../atoms/Base/Container/Container';
import MedianValueResults from '../../molecules/Custom/MedianValueResults/MedianValueResults';

interface ISegmentOrGroupTemplateProps {
	displayFirstOrOption?: boolean;
	orGroups: string[];
	segmentName?: string;
	onSave?: (orGroups: string[], modifiedFilter?: ModifiedFilter) => void;
}

const SegmentOrGroupTemplate: FC<ISegmentOrGroupTemplateProps> = ({
	displayFirstOrOption = false,
	orGroups,
	segmentName,
	onSave,
}) => {
	const dispatch = useAppDispatch();
	const cachedOrGroups: string[] = useAppSelector((state) => state.segments.orGroups);

	const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);
	const [segmentOrGroups, setSegmentOrGroups] = useState<string[]>([]);
	const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>();
	const [modifiedFilter, setModifiedFilter] = useState<ModifiedFilter>();

	useEffect(() => {
		if (cachedOrGroups) {
			setSegmentOrGroups(cachedOrGroups);
			return;
		}

		setSegmentOrGroups(orGroups);
	}, [orGroups, cachedOrGroups]);

	const groupCount = segmentOrGroups?.length - 1;

	const onShowFilterOptionsHandler = (selectedFilter: SelectedFilter) => {
		setSelectedFilter(selectedFilter);
		setShowFilterOptions(true);
	};

	const onConfirmFilterOptionHandler = (modifiedFilter: ModifiedFilter) => {
		setModifiedFilter(modifiedFilter);
		setShowFilterOptions(false);
	};

	const onAddOrGroupHandler = () => {
		setModifiedFilter(undefined);
		setSegmentOrGroups([...segmentOrGroups, SegmentHelper.EmptyOrGroup]);
	};

	const onRemoveOrGroupHandler = (orGroupIndex: number) => {
		const updatedOrGroups = segmentOrGroups.filter((_, index) => index !== orGroupIndex);
		setSegmentOrGroups(updatedOrGroups);
		dispatch(setCachedOrGroups(updatedOrGroups));
		if (onSave) onSave(updatedOrGroups);
	};

	return (
		<div>
			{showFilterOptions && selectedFilter && (
				<SegmentFilterOptionsModal
					currentSegmentName={segmentName}
					selectedFilter={selectedFilter}
					onCloseClick={() => {
						setShowFilterOptions(false);
					}}
					onConfirmFilter={onConfirmFilterOptionHandler}
				/>
			)}
			{segmentOrGroups.map((orGroup, index) => {
				return (
					<div key={index}>
						<Container horizontal childSpaceLarge>
							<Container fullWidth className={styles.primary_column}>
								{displayFirstOrOption && index === 0 && (
									<AndOr filterType={FilterType.Or} itemIndex={0} onClick={onRemoveOrGroupHandler} />
								)}
								<label>
									<SegmentOrGroup
										orGroup={orGroup}
										orGroupIndex={index}
										modifiedFilter={modifiedFilter}
										onShowFilterOptions={onShowFilterOptionsHandler}
										onSave={onSave}
									/>
								</label>
								<Container className={styles.or_container} fullWidth>
									{index < groupCount && (
										<AndOr
											filterType={FilterType.Or}
											itemIndex={index + 1}
											onClick={onRemoveOrGroupHandler}
										/>
									)}

									{index === groupCount && (
										<AndOr filterType={FilterType.Or} isPlus lastOr onClick={onAddOrGroupHandler} />
									)}
								</Container>
							</Container>
							<Container className={styles.second_column}>
								{orGroup !== SegmentHelper.EmptyOrGroup && (
									<Container fullWidth>
										<SegmentFilterPercentageBar where={orGroup} />
									</Container>
								)}
							</Container>
							<Container className={styles.third_column}>
								{orGroup !== SegmentHelper.EmptyOrGroup && <MedianValueResults where={orGroup} />}
							</Container>
						</Container>
					</div>
				);
			})}
			{segmentOrGroups.length === 0 && (
				<Container horizontal fullWidth>
					<AndOr filterType={FilterType.Default} hideConnectorLines isPlus onClick={onAddOrGroupHandler} />
				</Container>
			)}
		</div>
	);
};

export default SegmentOrGroupTemplate;
