export class DateHelper {
	public static ConvertToValidDateInputDate(date: any): string {
		try {
			return new Date(date).toISOString().split('T')[0];
		} catch {
			return date;
		}
	}

	public static GetISODate(date: Date = new Date()): string {
		return date.toISOString().split('T')[0];
	}

	public static GetISODateTime(date: Date = new Date()): string {
		return date.toISOString();
	}
}
